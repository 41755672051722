/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Container, Row, Col } from 'reactstrap';
import MyButton from '../../MyButton/MyButton'
import { Formik } from "formik";
import * as Yup from "yup";
import { AddEditUser, UserTableDTO } from "../../../interfaces/user"
import '../../../../node_modules/text-security/dist/text-security.css'
import { Default, Mobile } from '../../../ResponsiveSizes';
import { TFunction } from 'i18next';

interface AddUserModalProps {
  modal: any,
  toggle: any,
  type: string,
  loginError: string | null,
  setLoginError: (error: string | null) => void,
  selectedUser: UserTableDTO | undefined,
  AddUser: (userDTO: AddEditUser) => Promise<any>
  EditUser: (userDTO: AddEditUser) => Promise<any>
  t: TFunction
}

const selected = require("../../../assets/Selected.svg") as string;
const notSelected = require("../../../assets/NotSelected.svg") as string;
const backArrow = require("../../../assets/Keyboard_backspace.svg") as string;

const AddUserModal = (props: AddUserModalProps) => {


  const [userType, setUserType] = useState(1)
  const [userActive, setUserActive] = useState<boolean>(false)
  const [secureText, setSecureText] = useState("secure-text");

  useEffect(() => {
    if (selectedUser) {
      setUserType(selectedUser.userType as number)
      setUserActive(selectedUser.isActive as boolean)
    }
  }, [props.selectedUser]
  )

  const toggleSecureText = () => {
    secureText === "" ? setSecureText("secure-text") : setSecureText("")
  }


  const handleChangeUserType = (event: any) => {
    setUserType(Number(event.target.value))
  }

  const handleActiveChange = (value: boolean) => {
    setUserActive(value)
  }

  const { modal, toggle, type, selectedUser, AddUser, EditUser, setLoginError, loginError, t } = props



  const initialValues: AddEditUser = {
    email: '',
    password: '',
    name: '',
    username: '',
    userType: -1,
    isActive: true
  };

  const content = (<Formik
    initialValues={type === "edit" && selectedUser ? ({
      email: selectedUser.email,
      name: selectedUser.name,
      username: selectedUser.username,
      userType: selectedUser.userType,
      password: "",
      isActive: selectedUser.isActive as boolean
    } as AddEditUser || initialValues) : initialValues}
    onSubmit={async (values, { setSubmitting }) => {


      const addUserDTO = values as AddEditUser
      addUserDTO.userType = userType
      addUserDTO.isActive = userActive as boolean
      if (type === "add") {
        const result = await AddUser(addUserDTO)



        if (result === null)
          setLoginError(t("User already exists with this username"))
        else if (result === false)
          setLoginError(t("User already exists with this email"))
      }
      else if (type === "edit") {

        const result = await EditUser(addUserDTO)

        if (result === null)
          setLoginError(t("User already exists with this username"))
        else if (result === false)
          setLoginError(t("User already exists with this email"))
      }

      setSubmitting(false);


    }}

    validationSchema={Yup.object().shape({
      name: Yup.string().required(t("Required")),
      username: Yup.string().required(t("Required")),
      email: Yup.string()
        .email(t("Email must be a valid"))
        .required(t("Required")),
      password: type === "edit" ? Yup.string()
        .notRequired()
        .min(8, t("Password is too short - should be 8 chars minimum"))
        .matches(/(?=.*[0-9])/, t("Password must contain a number")) :
        Yup.string()
          .required(t("Required"))
          .min(8, t("Password is too short - should be 8 chars minimum"))
          .matches(/(?=.*[0-9])/, t("Password must contain a number"))

    })}
    isInitialValid={type === "add" ? false : true}
  >

    {props => {
      const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid
      } = props;


      return (
        <React.Fragment>
          <div className="title-container"><div className="flex-container"><Mobile><img onClick={() => toggle(type)} src={backArrow} alt='' /></Mobile>
            <div className="title">{type === "add" ? t("Add User") : type === "edit" ? t("Edit user") : ""}</div></div>
            <Default> <div className="cross" onClick={toggle} /></Default></div>
          <Row className="form-step">
            <Col md="6" xs="6" className="checkbox-wrapper round">

              <input
                id="checkbox1"
                name="blower"
                type="checkbox"
                checked={userType === 1}
                onChange={handleChangeUserType}
                value={1}
              />
              <label htmlFor="checkbox1"></label>
              <div>{t("Blower")}</div>

            </Col>
            <Col md="6" xs="6" className="checkbox-wrapper round">
              <input
                id="checkbox2"
                name="administrator"
                type="checkbox"
                checked={userType === 0}
                value={0}
                onChange={handleChangeUserType}
              />
              <label htmlFor="checkbox2"></label>
              <div>{t("Admin")}</div>
            </Col>
          </Row>
          <form onSubmit={handleSubmit} className="my-form">
            <Row>
              <Col md="6" xs="12">
                <label htmlFor="name" className="input-title">{t("Name")}</label>
                <input
                  name="name"
                  type="text"
                  placeholder="Type"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`user-input ${errors.name && (touched.name ? "error" : "")}`}
                />
                {errors.name && touched.name && (
                  <div className="input-feedback">{errors.name}</div>
                )}
              </Col>
              <Col md="6" xs="12">
                <label htmlFor="username" className="input-title">{t("Username")}</label>
                <input
                  name="username"
                  type="text"
                  placeholder="Type"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`user-input ${errors.username && (touched.username ? "error" : "")}`}
                />
                {errors.username && touched.username && (
                  <div className="user-input input-feedback">{errors.username}</div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="12">

                <label htmlFor="email" className="input-title">{t("Email")}</label>
                <input
                  name="email"
                  type="text"
                  placeholder="Type"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`user-input ${errors.email && (touched.email ? "error" : "")}`}
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </Col>
              <Col md="6" xs="12">
                <label htmlFor="password" className="input-title">{t("Password")}</label>
                <div className="password-container">
                  <input
                    name="password"
                    type="text"
                    placeholder="Type"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={values.password!.length > 0 ? `${secureText} user-input ${errors.password && (touched.password ? "error" : "")}` :
                      `user-input ${errors.password && (touched.password ? "error" : "")}`}
                  />
                  {values.password.length > 0 && <div className="password-eye" onClick={toggleSecureText} />}
                </div>
                {errors.password && touched.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="12" xs="12">
                {type === "edit" ?
                  <div className="user-active">
                    <div className="user-active-icon" onClick={() => handleActiveChange(!userActive)}><img src={userActive ? selected : notSelected} alt='' /></div>
                    <div className="user-active-text">{t("User active")}</div>
                  </div> : null}
              </Col>
            </Row>
            <div className="add-user-error-wrap">
              <div className="login-error add-user-error">
                {loginError && <div className="input-feedback">{loginError}</div>}
              </div>
            </div>
            <div className="add-user-button-container">
              <MyButton type="reset" typeButton="secondary" onClick={toggle}>
                {t("Cancel")}
              </MyButton>

              <div className="step" />
              <MyButton type="submit" disabled={!isValid || isSubmitting}>
                {type === "add" ? t("Add User") : type === "edit" ? t("Edit user") : ""}
              </MyButton>
            </div>
          </form>
        </React.Fragment>
      );
    }}
  </Formik>)


  return (
    <div>
      <Container>
        <Default>
          <Modal isOpen={modal} toggle={toggle} centered className="my-modal-dialog" >
            <ModalBody className="modal-body">
              {content}
            </ModalBody>
          </Modal>
        </Default>

        <Mobile>
          {modal && content}
        </Mobile>
      </Container>
    </div>

  );
}

export default AddUserModal;


