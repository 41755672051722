import axios from "axios"
import { ProductDTO } from "../interfaces/product"
import { SortDTO } from "../interfaces/general"

export const getAllProducts = () =>
    axios.get<ProductDTO[]>(`/products/getallproducts`)

export const filterProducts = (sortDTO: SortDTO | undefined, searchTerm: string | undefined, isActive: boolean) =>
    axios.get<ProductDTO[]>(`/products/filterproducts?sortdto=${JSON.stringify(sortDTO)}&searchTerm=${searchTerm}&isActive=${isActive}`)


export const editProductById = (productId: number, product: ProductDTO) =>
    axios.put<boolean>(`/products/editproductbyid/${productId}`, product)

export const createProduct = (product: ProductDTO) =>
    axios.post<ProductDTO>(`/products/createproduct`, product)

export const deleteProduct = (productId: number) =>
    axios.delete<boolean>(`/products/deleteproduct/${productId}`)