/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Input, Form, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody } from 'reactstrap';
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { TransactionDTO } from "../../../interfaces/transaction"
import '../../../../node_modules/text-security/dist/text-security.css'
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Default, Mobile, Desktop, Sensor } from '../../../ResponsiveSizes';
import { TransactionTypes, GetTransactionType } from '../../../enums/transactionState';
import { getTransactionById, editTransaction, addTransaction } from '../../../api/transaction';
import CertificateRowContact from '../../Certificates/CertificateRow/CertificateRowContact';
import { ContactDTO } from '../../../interfaces/contact';
import { filterProducts } from '../../../api/products';
import { ProductDTO } from '../../../interfaces/product';
import AddEditTransactionLine from '../AddEditTransactionLine/AddEditTransactionLine';
import { TransactionLineDTO } from '../../../interfaces/transactionLine';
import TransactionLinesBlock from '../TransactionLinesBlock/TransactionLinesBlock';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import da from 'date-fns/locale/da';
import { GetCertificateName } from '../../../enums/certificateType';
import { downloadOrderPdfInfo, downloadWorkNotesPdfInfo } from '../../../api/pdf';
import TransactionLinesBlockMobile from '../TransactionLinesBlockMobile/TransactionLinesBlockMobile';
import MyButton from '../../MyButton/MyButton';
import { TFunction } from 'i18next';

registerLocale('da', da)






const moment = require('moment');

const backArrow = require("../../../assets/Desktop-back.svg") as string;
const save = require("../../../assets/Save.svg") as string;
const pdf = require("../../../assets/Pdf.svg") as string;
const backArrowMobile = require("../../../assets/Keyboard_backspace.svg") as string;


const selected = require("../../../assets/Selected.svg") as string;
const notSelected = require("../../../assets/NotSelected.svg") as string;


const bold = require("../../../assets/Format_bold.svg") as string;

const italic = require("../../../assets/Format_italic.svg") as string;

const underline = require("../../../assets/Format_underlined.svg") as string;


interface AddEditTransactionProps {
    type: string,
    selectedTransactionId?: number | undefined,
    history: any,
    setType: (type: string) => void
    refreshData: () => void,
    userId: number
    setSelectedPage: (position: number) => void,
    handleNavbarShow: (show: boolean) => void,
    t: TFunction
}

interface TransactionValidate {
    name: string,
    dateCreated: string | null,
    letterHead: string | null,
    endText: string | null,
    noteForWorksheet: string | null,
    noteForWorkRockwool: string | null,
    transactionLines: TransactionLineDTO[],
    state: TransactionTypes
    //contactBilledTo
    contactBilledName: string | null,
    contactBilledAddress1: string | null,
    contactBilledAddress2: string | null,
    contactBilledCity: string | null,
    contactBilledZipCode: string | null,
    contactBilledEmail: string | null,
    contactBilledPhone: string | null,
    contactBilledAtt: string | null,
    contactBilledContactPerson: string | null
    //contactDeliverdTo
    contactDeliverdName: string | null,
    contactDeliverdAddress1: string | null,
    contactDeliverdAddress2: string | null,
    contactDeliverdCity: string | null,
    contactDeliverdZipCode: string | null,
    contactDeliverdEmail: string | null,
    contactDeliverdPhone: string | null,
    contactDeliverdAtt: string | null,
    contactDeliveredContactPerson: string | null
}




const AddEditTransaction = (props: AddEditTransactionProps) => {

    const count: React.MutableRefObject<number> = useRef(0);
    const [transaction, setTransaction] = useState<TransactionDTO>()
    const [products, setProducts] = useState<ProductDTO[]>([])
    const [selectedProduct, setSelectedProduct] = useState<ProductDTO>()
    const [modalState, setModal] = useState({ modalOpen: false, type: "" });
    const [transactionLines, setTransactionLines] = useState<TransactionLineDTO[]>([])
    const [startDate, setStartDate] = useState<any>(new Date())
    const [transactionStatus, setTransactionStatus] = useState<number>(0)
    const [transactionLine, setTransactionLine] = useState<TransactionLineDTO>()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addProductValueId, setAddProductId] = useState(0);

    const [transactionState, setTransactionState] = useState<boolean>(false);
    const toggleAddProduct = () => setDropdownOpen(prevState => !prevState);

    const [noteForRockwoolEditorState, setNoteForRockwoolEditorState] = useState(EditorState.createEmpty())
    const [noteForWorksheetEditorState, setNoteForWorksheetEditorState] = useState(EditorState.createEmpty())
    const [endTextEditorState, setEndTextEditorState] = useState(EditorState.createEmpty())

    const [submitModal, setSubmitModal] = useState<boolean>(false)

    const notifyEdit = () => toast(t("Transaction was edited successfully"));
    const notifyAdd = () => toast(t("Transaction was added successfully"));

    const initialValues: TransactionValidate = {
        name: "",
        dateCreated: "",
        letterHead: "",
        endText: "",
        noteForWorksheet: "",
        noteForWorkRockwool: "",
        transactionLines: [],
        state: 0,
        //contactBilledTo
        contactBilledName: "",
        contactBilledAddress1: "",
        contactBilledAddress2: "",
        contactBilledCity: "",
        contactBilledZipCode: "",
        contactBilledEmail: "",
        contactBilledPhone: "",
        contactBilledAtt: "",
        contactBilledContactPerson: "",
        //contactDeliverdTo
        contactDeliverdName: "",
        contactDeliverdAddress1: "",
        contactDeliverdAddress2: "",
        contactDeliverdCity: "",
        contactDeliverdZipCode: "",
        contactDeliverdEmail: "",
        contactDeliverdPhone: "",
        contactDeliverdAtt: "",
        contactDeliveredContactPerson: ""
    };

    useEffect(() => {

        if (type === "edit")
            getTransactionById(selectedTransactionId!).then((response) => {
                if (response.data) {
                    setTransaction(response.data)
                    setTransactionLines(response.data.transactionLines)
                    setTransactionStatus(response.data.state)

                    setStartDate(Date.parse(response.data.dateCreated.toString()))
                    //   setTransactionState(response.data.state)


                    let html = response.data.endText!;
                    let contentBlock = html && htmlToDraft(html);

                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        setEndTextEditorState(EditorState.createWithContent(contentState))
                    }

                    html = response.data.noteForWorksheet!;
                    contentBlock = html && htmlToDraft(html);

                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        setNoteForWorksheetEditorState(EditorState.createWithContent(contentState))
                    }

                    html = response.data.noteForWorkRockwool!;
                    contentBlock = html && htmlToDraft(html);

                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        setNoteForRockwoolEditorState(EditorState.createWithContent(contentState))
                    }


                }
            })

        filterProducts(undefined, "", true).then((response) => {
            if (response.data && response.data.length) {
                const products = [...response.data]
                const otherProduct: ProductDTO = {
                    id: -1,
                    certificateType: -1,
                    isActive: true,
                    kgPerBag: 0,
                    kgPerCube: 0
                }
                products.push(otherProduct)
                setProducts(products)
                setSelectedProduct(response.data[0])
                setAddProductId(response.data[0].id!)
            }
        })

    }, [props.type])

    const { type, selectedTransactionId, refreshData, userId, setSelectedPage, history, handleNavbarShow } = props

    const toggleModal = (type: string) => {
        if (["add", "edit", "delete"].indexOf(type) !== -1)
            setModal({ type, modalOpen: !modalState.modalOpen });
        else
            setModal({ ...modalState, modalOpen: !modalState.modalOpen });
    }






    const DecrementCount = () => {
        count.current--;
    }

    const AddTransactionLine = (transactionLine: TransactionLineDTO) => {

        setTransactionLines([...transactionLines, transactionLine])
    }


    const EditTransactionLine = (transactionLine: TransactionLineDTO) => {



        const result = [...transactionLines]

        result.forEach((value, index) => {
            if (value.id! === transactionLine.id) {

                result[index] = transactionLine
            }
        })


        setTransactionLines([...result])
    }

    const DeleteTransactionLine = (transactionLine: TransactionLineDTO) => {

        const result = [...transactionLines].filter(t => t!.id! !== transactionLine!.id!)

        setTransactionLines([...result])
    }


    const openModalWithProduct = (type: string, transactionLineDTO?: TransactionLineDTO) => {

        setModal({ type, modalOpen: true });
        if (transactionLineDTO)
            setTransactionLine(transactionLineDTO)
    }

    const HandleDownloadOrderPdfInfo = (certificateId: number) => {
        downloadOrderPdfInfo(certificateId).then((response) => {
            if (response.data) {
                const myURL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window.URL works in Firefox
                //const csv = csv;  
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const csvUrl = myURL.createObjectURL(blob);
                //setFiledownloadlink(csvUrl);

                const tempLink = document.createElement('a');
                tempLink.href = csvUrl;

                const name = transactionStatus === 0 ? "Tilbud" : transactionStatus === 1 ? "Ordrebekraeftigelse" : "Ordrebekraeftigelse"
                tempLink.setAttribute('download', `${name}${transaction!.contactBilledTo!.address1! ? `-${transaction!.contactBilledTo!.address1!}` : ""}.pdf`);
                tempLink.click();
            }
        })
    }

    const HandleDownloadWorkNotesPdfInfo = (certificateId: number) => {
        downloadWorkNotesPdfInfo(certificateId).then((response) => {
            if (response.data) {
                const myURL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window.URL works in Firefox
                //const csv = csv;  
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const csvUrl = myURL.createObjectURL(blob);
                //setFiledownloadlink(csvUrl);

                const tempLink = document.createElement('a');
                tempLink.href = csvUrl;
                const name = transactionStatus === 0 ? "Tilbud" : transactionStatus === 1 ? "Ordrebekraeftigelse" : "Ordrebekraeftigelse"
                tempLink.setAttribute('download', `${name}${transaction!.contactBilledTo!.address1! ? `-${transaction!.contactBilledTo!.address1!}` : ""}.pdf`);
                tempLink.click();
            }
        })
    }

    const handleAddProductIdChange = (id: number) => {
        setAddProductId(id)
        setSelectedProduct(products.find(p => p.id === id))

    }


    const TransactionDTOtoTransactionValidate = (transaction: TransactionDTO): TransactionValidate | null => {


        if (transaction) {
            return {
                name: transaction.name!,
                dateCreated: moment(transaction.dateCreated!).format('DD/MM/YYYY'),
                letterHead: transaction.letterHead!,
                endText: transaction.endText!,
                noteForWorksheet: transaction.noteForWorksheet!,
                noteForWorkRockwool: transaction.noteForWorkRockwool!,
                transactionLines: transaction.transactionLines!,
                state: transaction.state!,
                //contactBilledTo
                contactBilledName: transaction.contactBilledTo && transaction.contactBilledTo!.name!,
                contactBilledAddress1: transaction.contactBilledTo && transaction.contactBilledTo!.address1!,
                contactBilledAddress2: transaction.contactBilledTo && transaction.contactBilledTo!.address2!,
                contactBilledCity: transaction.contactBilledTo && transaction.contactBilledTo!.city!,
                contactBilledZipCode: transaction.contactBilledTo && transaction.contactBilledTo!.zipCode!,
                contactBilledEmail: transaction.contactBilledTo && transaction.contactBilledTo!.email!,
                contactBilledPhone: transaction.contactBilledTo && transaction.contactBilledTo!.phone!,
                contactBilledAtt: transaction.contactBilledTo && transaction.contactBilledTo!.att!,
                contactBilledContactPerson: transaction.contactBilledTo && transaction.contactBilledTo!.contactPerson!,
                //contactDeliverdTo
                contactDeliverdName: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.name!,
                contactDeliverdAddress1: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.address1!,
                contactDeliverdAddress2: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.address2!,
                contactDeliverdCity: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.city!,
                contactDeliverdZipCode: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.zipCode!,
                contactDeliverdEmail: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.email!,
                contactDeliverdPhone: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.phone!,
                contactDeliverdAtt: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.att!,
                contactDeliveredContactPerson: transaction.contactDeliverdTo && transaction.contactDeliverdTo!.contactPerson!,
            }
        }
        return null
    }

    const TransactionValidateToTransactionDTO = (transactionV: TransactionValidate): TransactionDTO | null => {


        if (type === "edit" && transaction) {
            return {
                id: transaction.id,
                dateFulfilled: transaction.dateFulfilled,
                isActive: transaction.isActive,
                total: transaction.total,
                blowerId: transaction.blowerId,
                name: transactionV.name,
                dateCreated: moment(transactionV.dateCreated, "DD/MM/YYYY"),
                letterHead: transactionV.letterHead,
                endText: transactionV.endText,
                noteForWorksheet: transactionV.noteForWorksheet,
                noteForWorkRockwool: transactionV.noteForWorkRockwool,
                transactionLines: transactionV.transactionLines,
                state: Number(transactionV.state),
                //contactBilledTo
                contactBilledTo: transaction.contactBilledTo ? {
                    name: transactionV.contactBilledName,
                    address1: transactionV.contactBilledAddress1,
                    address2: transactionV.contactBilledAddress2,
                    att: transactionV.contactBilledAtt,
                    city: transactionV.contactBilledCity,
                    email: transactionV.contactBilledEmail,
                    id: transaction.contactBilledTo.id,
                    phone: transactionV.contactBilledPhone,
                    zipCode: transactionV.contactBilledZipCode,
                    contactPerson: transactionV.contactBilledContactPerson
                } as ContactDTO : null,
                contactDeliverdTo: transaction.contactDeliverdTo ? {
                    name: transactionV.contactDeliverdName,
                    address1: transactionV.contactDeliverdAddress1,
                    address2: transactionV.contactDeliverdAddress2,
                    att: transactionV.contactDeliverdAtt,
                    city: transactionV.contactDeliverdCity,
                    email: transactionV.contactDeliverdEmail,
                    id: transaction.contactDeliverdTo.id,
                    phone: transactionV.contactDeliverdPhone,
                    zipCode: transactionV.contactDeliverdZipCode,
                    contactPerson: transactionV.contactDeliveredContactPerson
                } as ContactDTO : null,
            }
        }
        else if (type === "add") {
            return {
                id: -1,
                dateFulfilled: moment(),
                isActive: true,
                total: 0,
                blowerId: userId,
                name: transactionV.name,
                dateCreated: moment(transactionV.dateCreated, "DD/MM/YYYY"),
                letterHead: transactionV.letterHead,
                endText: transactionV.endText,
                noteForWorksheet: transactionV.noteForWorksheet,
                noteForWorkRockwool: transactionV.noteForWorkRockwool,
                transactionLines: transactionV.transactionLines,
                state: transactionV.state,
                //contactBilledTo
                contactBilledTo: {
                    name: transactionV.contactBilledName,
                    address1: transactionV.contactBilledAddress1,
                    address2: transactionV.contactBilledAddress2,
                    att: transactionV.contactBilledAtt,
                    city: transactionV.contactBilledCity,
                    email: transactionV.contactBilledEmail,
                    id: -1,
                    phone: transactionV.contactBilledPhone,
                    zipCode: transactionV.contactBilledZipCode
                } as ContactDTO,
                contactDeliverdTo: {
                    name: transactionV.contactDeliverdName,
                    address1: transactionV.contactDeliverdAddress1,
                    address2: transactionV.contactDeliverdAddress2,
                    att: transactionV.contactDeliverdAtt,
                    city: transactionV.contactDeliverdCity,
                    email: transactionV.contactDeliverdEmail,
                    id: -1,
                    phone: transactionV.contactDeliverdPhone,
                    zipCode: transactionV.contactDeliverdZipCode
                } as ContactDTO,
            }
        }
        return null
    }


    Yup.addMethod(Yup.number, 'delocalize', function () {
        return this.transform(function (currentValue, originalValue) {
            return parseFloat(originalValue.replace(',', '.'))
        })
    })


    const handleChangeDate = (date: any) => {
        if (!date)
            date = new Date()

        setStartDate(date)
    };

    const [transactionValidate, setTransactionValidate] = useState<TransactionValidate | null>(null)

    //let transactionValidate = TransactionDTOtoTransactionValidate(transaction!)

    useEffect(() => {
        setTransactionValidate(TransactionDTOtoTransactionValidate(transaction!))
    }, [transaction])

    const CopyCompanyAddress = (values: TransactionValidate) => {

        const result = TransactionValidateToTransactionDTO(values)

        result!.contactDeliverdTo! = { ...result!.contactBilledTo! }
        setTransactionValidate(TransactionDTOtoTransactionValidate(result!))


    }


    const handleBack = () => {
        setSelectedPage(1)
        handleNavbarShow(true)
        history.push("/transactions")
    }

    const handleCustomSubmit = (event: any) => {
        if (transactionStatus === 1 && transactionState) {
            event.preventDefault()
            setSubmitModal(true)
        }

    }

    const { t } = props

    console.log("addProductValueId", addProductValueId)
    const content = (<Formik
        initialValues={(type === "edit" || type === "add") ? (transactionValidate || initialValues) : initialValues}
        onSubmit={async (values, { setSubmitting }) => {

            values.transactionLines = transactionLines;
            values.state = transactionStatus
            values.dateCreated = moment(startDate).format('DD/MM/YYYY')
            values.endText = draftToHtml(convertToRaw(endTextEditorState.getCurrentContent()))
            values.noteForWorksheet = draftToHtml(convertToRaw(noteForWorksheetEditorState.getCurrentContent()))
            values.noteForWorkRockwool = draftToHtml(convertToRaw(noteForRockwoolEditorState.getCurrentContent()))


            const result = TransactionValidateToTransactionDTO(values)

            result!.dateCreated = moment(startDate)
            if (result!.state !== 2)
                result!.state = transactionState ? result!.state + 1 : result!.state

            result!.transactionLines.forEach((value, index) => {
                if (value && value!.id! <= 0)
                    result!.transactionLines[index].id = 0
            })

            if (type === "add") {

                if (result) {
                    addTransaction(result).then((response) => {
                        if (response.data) {
                            notifyAdd()
                            // props.setType("edit")
                            history.push(`/transactions/edit/${response.data}`)
                            refreshData()
                        }
                    })
                }
            } else if (type === "edit") {

                if (result) {

                    editTransaction(result.id, result).then((response) => {
                        if (response.data) {
                            notifyEdit()
                        }

                    }).then(() => {

                        getTransactionById(selectedTransactionId!).then((response) => {
                            if (response.data) {
                                setTransaction(response.data)
                                // setTransactionLines(response.data.transactionLines)
                                setTransactionStatus(response.data.state)
                                setStartDate(Date.parse(response.data.dateCreated.toString()))
                            }
                            refreshData()
                        })

                    })
                    setTransactionState(false)
                }

                setSubmitting(false);
            }
            setSubmitModal(false)
        }
        }
        enableReinitialize
        validationSchema={
            Yup.object().shape({
                name: Yup.string().required(t("Required")),
                dateCreated: Yup.string().notRequired(),
                letterHead: Yup.string().notRequired().nullable(),
                endText: Yup.string().notRequired().nullable(),
                noteForWorksheet: Yup.string().notRequired().nullable(),
                noteForWorkRockwool: Yup.string().notRequired().nullable(),
                transactionLines: Yup.string().notRequired().nullable(),
                //contactBilledTo
                contactBilledName: Yup.string().notRequired(),
                contactBilledAddress1: Yup.string().notRequired().nullable(),
                contactBilledAddress2: Yup.string().notRequired().nullable(),
                contactBilledCity: Yup.string().notRequired().nullable(),
                contactBilledZipCode: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                contactBilledEmail: Yup.string()
                    .email(t("Email must be a valid"))
                    .notRequired().nullable(),
                contactBilledPhone: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                contactBilledAtt: Yup.string().notRequired().nullable(),
                //contactDeliverdTo
                contactDeliverdName: Yup.string().notRequired().nullable(),
                contactDeliverdddress1: Yup.string().notRequired().nullable(),
                contactDeliverdAddress2: Yup.string().notRequired().nullable(),
                contactDeliverdCity: Yup.string().notRequired().nullable(),
                contactDeliverdZipCode: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                contactDeliverdEmail: Yup.string()
                    .email(t("Email must be a valid"))
                    .notRequired().nullable(),
                contactDeliverdPhone: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                contactDeliverdAtt: Yup.string().notRequired().nullable(),
            })
        }

    >

        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid
            } = props;

            return (
                <React.Fragment>
                    <AddEditTransactionLine
                        toggle={toggleModal}
                        modal={modalState.modalOpen}
                        type={modalState.type}
                        selectedProduct={selectedProduct}
                        addTransactionLine={AddTransactionLine}
                        transactionLine={transactionLine}
                        editTransactionLine={EditTransactionLine}
                        deleteTransactionLine={DeleteTransactionLine}
                        count={count.current}
                        decrementCount={DecrementCount}
                        t={t}
                    />
                    <Modal isOpen={transactionState && submitModal} toggle={() => setSubmitModal(!submitModal)} centered className="my-modal-dialog" >
                        <ModalBody className="modal-body">
                            <div>
                                <Form onSubmit={handleSubmit} className="certificate-full-width">

                                    <div className="title-container"><div className="title-nav">
                                        <div />
                                    </div><Default><div className="cross" onClick={() => setSubmitModal(!submitModal)} /></Default></div>
                                    <div className="title">{t("You will not be able to change any values after you save the order as fulfilled. Please make sure all details are correct") + "."}</div>
                                    <div className="certificate-form-step">
                                    </div>
                                    <div className="add-user-button-container">
                                        <MyButton type="reset" typeButton="secondary" onClick={() => setSubmitModal(!submitModal)}>
                                            {t("Cancel")}
                                        </MyButton>
                                        <div className="step" />

                                        <MyButton type="submit" disabled={isSubmitting}>
                                            {t("Submit")}
                                        </MyButton>
                                    </div>

                                </Form>
                            </div>
                        </ModalBody>


                    </Modal>
                    <Form className="transaction-form" onSubmit={handleSubmit}>
                        <Default>
                            <div className="transaction-add-edit-back">
                                <img onClick={() => handleBack()} src={backArrow} alt='' className="transaction-back-arrow" />
                                <div className="transaction-add-edit-back-text">{t("Go back")}</div>
                            </div>
                        </Default>
                        <Mobile>
                            <div className="title-container transaction-padding-left"><div className="flex-container">
                                <div><img onClick={() => handleBack()} src={backArrowMobile} alt='' className="transaction-back-arrow" /></div>
                                <div className="title">{type === "edit" ? transaction && transaction!.name! : t("New transaction")}</div>
                            </div>
                            </div>
                        </Mobile>
                        <div className="transaction-form-container">

                            <Default> <div className="title">{type === "edit" ? transaction && transaction!.name! : t("New transaction")}</div></Default>
                            <div className="certificate-col-title transaction-title-container">
                                {type === "edit" && <>
                                    <div className="icon-margin">
                                        <button className="transaction-icon-container" type="button" onClick={() => HandleDownloadOrderPdfInfo(transaction!.id!)} >
                                            <img src={pdf} alt="" />
                                            <div className="transaction-icon-text">{t("Pdf")}</div>
                                        </button>
                                    </div>
                                    <div className="icon-margin">
                                        <button className="transaction-icon-container" type="button" onClick={() => HandleDownloadWorkNotesPdfInfo(transaction!.id!)} >
                                            <img src={pdf} alt="" />
                                            <div className="transaction-icon-text">{t("Worksheet")}</div>
                                        </button>
                                    </div>
                                </>}
                                <div className="icon-margin">
                                    <button className="transaction-icon-container" type="submit" onClick={handleCustomSubmit} disabled={!isValid || isSubmitting}>
                                        <img src={save} alt="" />
                                        <div className="transaction-icon-text">{t("Save")}</div>
                                    </button>
                                </div>
                            </div>
                            <div className="certificate-block-container">
                                <div className="certificate-block-title">{t("Transaction details")}</div>
                                <Row className="certificate-block-step-top">
                                    <Col xl="4" md="12" xs="12">

                                        <label htmlFor="name" className="input-title">{t("Transaction name")}</label>
                                        <Input
                                            name="name"
                                            type="text"
                                            placeholder="Type"
                                            value={values.name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={`user-input ${errors.name && (touched.name ? "error" : "")}`}
                                            disabled={transactionStatus === 2}
                                        />
                                        {errors.name && touched.name && (
                                            <div className="input-feedback">{errors.name}</div>
                                        )}
                                    </Col>
                                    <Col xl="4" md="12" xs="12">

                                        <label htmlFor="pricePerBag" className="input-title">{t("Date")}</label>
                                        <FormGroup>
                                            <DatePicker
                                                name="dateCreated"
                                                className="user-input form-control"
                                                selected={startDate}
                                                onChange={handleChangeDate}
                                                dateFormat="dd/MM/yyyy"
                                                disabled={transactionStatus === 2}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xl="4" md="12" xs="12" className="transaction-flex-center">
                                        {transaction && type === "edit" ?
                                            <div className="transaction-state">
                                                <div className="col-flex">
                                                    <div onClick={() => setTransactionState(!transactionState)} ><img src={transactionStatus === 2 ? selected : transactionState === true ? selected : notSelected} alt="" /></div>
                                                    <div className="certificate-icon-step">{transactionStatus === 2 ? t("Fullfiled") : <><span>{t("Change transaction state to")}</span><span>{` ${GetTransactionType(transactionStatus + 1)}`}</span></>}</div>
                                                </div>
                                            </div> :
                                            type === "add" ?
                                                <div className="transaction-state">
                                                    <div className="col-flex">
                                                        <div><img src={selected} alt="" /></div>
                                                        <div className="certificate-icon-step">{t("Quote")}</div>
                                                    </div>
                                                </div> : null
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <div className="certificate-block-container">
                                <Row>
                                    <Col lg="6" md="12">
                                        <Default>    {transactionStatus !== 2 && <div className="certificate-block-top" />}</Default>
                                        <div className="certificate-block">
                                            <Row>
                                                <Col md="12" xs="12" className="certificate-col-title">
                                                    <div>
                                                        {t("Company Address")}:
            </div>
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Name") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledName}
                                                        name={"contactBilledName"}
                                                        disabled={transactionStatus === 2}

                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Address") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledAddress1}
                                                        name={"contactBilledAddress1"}
                                                        disabled={transactionStatus === 2}

                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Address") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledAddress2}
                                                        name={"contactBilledAddress2"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Town") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledCity}
                                                        name={"contactBilledCity"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("ZIP Code") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledZipCode}
                                                        name={"contactBilledZipCode"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Email") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledEmail}
                                                        name={"contactBilledEmail"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Phone") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledPhone}
                                                        name={"contactBilledPhone"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Att") + ":"}
                                                        touched={touched}
                                                        value={values.contactBilledAtt}
                                                        name={"contactBilledAtt"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="12">
                                        {transactionStatus !== 2 && <div className="certificate-block-top certificate-block-bold"
                                        >
                                            <div className="copy-company-data" onClick={() => CopyCompanyAddress(values)}>
                                                {t("Copy the data from the company address")}
                                            </div>
                                        </div>}
                                        <div className="certificate-block">
                                            <Row>
                                                <Col md="12" xs="12" className="certificate-col-title">
                                                    <div>
                                                        {t("Delivery address") + ":"}
                                                    </div>
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Name") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdName}
                                                        name={"contactDeliverdName"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Address") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdAddress1}
                                                        name={"contactDeliverdAddress1"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Address") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdAddress2}
                                                        name={"contactDeliverdAddress2"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Town") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdCity}
                                                        name={"contactDeliverdCity"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("ZIP Code") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdZipCode}
                                                        name={"contactDeliverdZipCode"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Email") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdEmail}
                                                        name={"contactDeliverdEmail"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Phone") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdPhone}
                                                        name={"contactDeliverdPhone"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                                <Col md="12" xs="12" className="certificate-col">

                                                    <CertificateRowContact
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        title={t("Att") + ":"}
                                                        touched={touched}
                                                        value={values.contactDeliverdAtt}
                                                        name={"contactDeliverdAtt"}
                                                        disabled={transactionStatus === 2}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="certificate-block-container">
                                <div className="certificate-block-title">{t("Letterhead")}</div>
                                <div>{(t("shown in extension of top text from master data"))}</div>
                                <Row className="certificate-content-step">
                                    <Col md="12" xs="12">
                                        <Input
                                            name="letterHead"
                                            type="textarea"
                                            placeholder="Type"
                                            value={values.letterHead || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={`user-input ${errors.letterHead && (touched.letterHead ? "error" : "")}`}
                                            disabled={transactionStatus === 2}
                                        />
                                        {errors.letterHead && touched.letterHead && (
                                            <div className="input-feedback input-feedback-textarea">{errors.letterHead}</div>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            <div className="certificate-block-container">
                                <div className="certificate-block-title">{transactionStatus !== 2 ? "Products" : "Transaction Lines"}</div>
                                {transactionStatus !== 2 && <div>{(t("select product to add transaction line"))}</div>}
                                <Row className="certificate-content-step">
                                    <Col md="12" xs="12">
                                        {transactionStatus !== 2 && <div className="select-product-input-container">

                                            <Dropdown isOpen={dropdownOpen} toggle={toggleAddProduct} disabled={transactionStatus === 2}>
                                                <DropdownToggle caret
                                                    className="add-product-dropdown"
                                                    tag="span"
                                                    data-toggle="dropdown"
                                                >
                                                    {products && products.length > 0 &&
                                                        <div className="transaction-dropdown-padding">
                                                            <strong>{addProductValueId !== -1 ? GetCertificateName(products.find(p => p.id! === addProductValueId!)! && products.find(p => p.id! === addProductValueId!)!.certificateType!) : "Andet"}</strong>
                                                            {addProductValueId != -1 && <div className="sub-text">{`kg/bag ${products.find(p => p.id! === addProductValueId)! && products.find(p => p.id === addProductValueId)!.kgPerBag!}`}</div>}
                                                            {addProductValueId != -1 && <div className="sub-text">{"kg/m"}&#x2083; {products.find(p => p.id! === addProductValueId)! && products.find(p => p.id === addProductValueId)!.kgPerCube!}</div>}</div>}

                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {products!.map(p =>
                                                        <DropdownItem key={p.id} onClick={() => handleAddProductIdChange(p.id!)}>
                                                            <div className="transaction-dropdown-padding"><strong>{p.id != -1 ? GetCertificateName(p.certificateType) : "Andet"}</strong>
                                                                {p.id != -1 && <div><div className="sub-text">{`kg/bag ${p.kgPerBag}`}</div><div className="sub-text">{"kg/m"}&#x2083; {p.kgPerCube}</div></div>}</div></DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                            <div className="transaction-add-product" onClick={() => openModalWithProduct("add")}>
                                                <div className="add-product">
                                                    <div className="add-product-icon" />
                                                    <div>
                                                        <span>{t("Add")}</span> <span className="add-transaction-line-text">{t("Transaction Line")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div>
                                            <div>
                                                {transactionLines && products && transactionLines.length ?
                                                    <>
                                                        <Desktop>
                                                            <TransactionLinesBlock
                                                                transactionStatus={transactionStatus}
                                                                history={history}
                                                                transactionLines={transactionLines}
                                                                products={products!}
                                                                handleSelectTransactionLine={openModalWithProduct}
                                                                t={t}
                                                            />
                                                        </Desktop>
                                                        <Sensor>
                                                            <TransactionLinesBlockMobile
                                                                transactionStatus={transactionStatus}
                                                                history={history}
                                                                transactionLines={transactionLines}
                                                                products={products!}
                                                                handleSelectTransactionLine={openModalWithProduct}
                                                                t={t}
                                                            />
                                                        </Sensor>
                                                    </> : transactionStatus === 2 ? <div>{t("Transaction lines dont exist")}.</div> : null}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="certificate-block-container">
                                <div className="certificate-block-title">{t("End text")}</div>
                                <div>{(t("shown in extension of final text from master data"))}</div>
                                <Row className="certificate-content-step transaction-line-description">
                                    <Col md="12" xs="12" disabled={transactionStatus === 2}>
                                        < Editor
                                            editorState={endTextEditorState}
                                            placeholder="Type"
                                            toolbarClassName="toolbarClassName profile-toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName={transactionStatus === 2 ? "editorClassName transaction-editor-disabled" : "editorClassName white-background"}
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            onEditorStateChange={transactionStatus === 2 ? () => { } : setEndTextEditorState}

                                            toolbar={{
                                                options: ['inline'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline'],
                                                    className: "inline-editor",
                                                    bold: {
                                                        icon: bold,
                                                    },
                                                    italic: {
                                                        icon: italic,
                                                    },
                                                    underline: {
                                                        icon: underline
                                                    }
                                                }
                                            }}

                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="certificate-block-container">
                                <div className="certificate-block-title">{t("Note for worksheet")}</div>
                                <Row className="certificate-content-step transaction-line-description">
                                    <Col md="12" xs="12" disabled={transactionStatus === 2}>
                                        < Editor
                                            editorState={noteForWorksheetEditorState}
                                            placeholder="Type"
                                            toolbarClassName="toolbarClassName profile-toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName={transactionStatus === 2 ? "editorClassName transaction-editor-disabled" : "editorClassName white-background"}
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            onEditorStateChange={transactionStatus === 2 ? () => { } : setNoteForWorksheetEditorState}

                                            toolbar={{
                                                options: ['inline'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline'],
                                                    className: "inline-editor",
                                                    bold: {
                                                        icon: bold,
                                                    },
                                                    italic: {
                                                        icon: italic,
                                                    },
                                                    underline: {
                                                        icon: underline
                                                    }
                                                }
                                            }}

                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="certificate-block-container certificate-block-container-end">
                                <div className="certificate-block-title">{t("Note for Rockwool")}</div>
                                <Row className="certificate-content-step transaction-line-description">
                                    <Col md="12" xs="12" disabled={transactionStatus === 2}>
                                        < Editor
                                            editorState={noteForRockwoolEditorState}
                                            placeholder="Type"
                                            toolbarClassName="toolbarClassName profile-toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName={transactionStatus === 2 ? "editorClassName transaction-editor-disabled" : "editorClassName white-background"}
                                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                                            onEditorStateChange={transactionStatus === 2 ? () => { } : setNoteForRockwoolEditorState}

                                            toolbar={{
                                                options: ['inline'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline'],
                                                    className: "inline-editor",
                                                    bold: {
                                                        icon: bold,
                                                    },
                                                    italic: {
                                                        icon: italic,
                                                    },
                                                    underline: {
                                                        icon: underline
                                                    }
                                                }
                                            }}

                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Form>
                </React.Fragment>
            )
        }


        }
    </Formik >)

    return (
        <div>
            <div>
                {content}
            </div>
        </div >

    );
}

export default AddEditTransaction;

