import * as React from 'react';
import { Container } from 'reactstrap';
import { useLocation } from "react-router-dom"
import { User } from "../../interfaces/user"
import NavMenu from '../NavMenu';
import { Default, Mobile } from '../../ResponsiveSizes';
import { SettingsDTO } from '../../interfaces/settings';


const Layout = (props: { children?: React.ReactNode, currentUser: User, showNavbar: boolean, settings: SettingsDTO[] }) => {
    const location = useLocation()

    const policyLink = props!.settings && props.settings.find(d => d.key === "Policy link") && props.settings.find(d => d.key === "Policy link")!.value || ""
    const policyText = props!.settings && props.settings.find(d => d.key === "Policy text") && props.settings.find(d => d.key === "Policy text")!.value || ""
    return (
        <React.Fragment>
            <div className={["/login", "/recovery"].indexOf(location.pathname) !== -1 ? "content layout-background" : "content"}>
                <NavMenu currentUser={props.currentUser} location={location} showNavbar={props.showNavbar} />
                <Default>
                    <Container className={["/login", "/recovery"].indexOf(location.pathname) !== -1 ? "full-container" : ""}>
                        <div className={["/login", "/recovery"].indexOf(location.pathname) !== -1 ? "container-step layout-background" : "container-step"} />
                        {props.children}
                    </Container>
                </Default>
                <Mobile>
                    <Container className="mobile-container">
                        <div className={["/login", "/recovery"].indexOf(location.pathname) !== -1 ? "container-step layout-background" : "container-step"} />
                        {props.children}
                    </Container>
                </Mobile>
            </div>
            <footer className="footer"><div className="footer-content">
                <div className="copyright-container">
                    <div>Copyright © Rockwool A/S</div>
                    <div className="gdpr"><a href={policyLink} target="_blank" rel="noreferrer">{policyText}</a></div>
                </div>
                <div className="footer-contact">
                    <Default> <div className="footer-contact-text">Brug for hjælp? Kontakt Gert Spetzler Larsen:</div></Default>
                    <div className="footer-mobile">
                        <div className="footer-mobile-icon" />
                        <div>91 37 00 18</div>
                    </div>
                </div>
            </div></footer>        </React.Fragment>
    )
};

export default (Layout)
