import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { UserTable, UserTableDTO } from '../../../interfaces/user';
import { GetUserType, UserType } from '../../../enums/userType';
import { Mobile, Tablet } from '../../../ResponsiveSizes';

interface MyTableRowProps {
    user: UserTable,
    openModalWithUser: (type: string, userDTO?: UserTableDTO) => void
}

const chevronUp = require("../../../assets/Chevron-up.svg") as string;
const chevronDown = require("../../../assets/Chevron-down.svg") as string;

const UserCollapse = (props: MyTableRowProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {

        setIsOpen(!isOpen)
    };



    const editUser = (event: any) => {
        event.stopPropagation()
        
        props.openModalWithUser("edit", user.edit.props.userTableDTO)
    }

    const { user } = props
    
    return (
        <>
            <Mobile>
                <div color="primary" onClick={toggle}> <div className={isOpen ?
                    " collapse-active collapse-header" :
                    "collapse-header collapse-header-border"}>

                    <div>
                        <div className="collapse-header-title">{user.name}</div>
                        <div className="collapse-header-type">{user.userType}</div>
                    </div>
                    <div> <img src={isOpen ? chevronUp : chevronDown} alt="" /></div> </div>
                </div>
            </Mobile>
            <Tablet>
                <tr onClick={toggle}>
                    <td>{props.user.id}</td>
                    <td>{props.user.name}</td>
                    <td>{props.user.userType}</td>
                    <td>{props.user.email}</td>
                    <td > <img src={isOpen ? chevronUp : chevronDown} alt="" /></td>
                </tr>
            </Tablet>
            <Mobile>
                <Collapse isOpen={isOpen}>
                    <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                        <div className="collapse-row">
                            <div>Username</div>
                            <div>{user.username}</div>
                        </div>
                        <div className="collapse-row">
                            <div>Email</div>
                            <div>{user.email}</div>
                        </div>
                        <div className="collapse-row">
                            <div>Created</div>
                            <div>{user.created}</div>
                        </div>
                        <div className="collapse-row">
                            <div>Offer</div>
                            <div>{props.user.userType === GetUserType(UserType.Blower) ? props.user.offerAmount : '-'}</div>
                        </div>
                        <div className="collapse-row">
                            <div>Executed offer</div>
                            <div>{props.user.userType === GetUserType(UserType.Blower) ? props.user.exectutedOfferAmount : '-'}</div>
                        </div>
                        <div className="collapse-row">
                            <div>Executed %</div>
                            <div>{props.user.userType === GetUserType(UserType.Blower) ? props.user.exectutedOfferPercentage : '-'}</div>
                        </div>

                        <div className="collapse-row-end">
                            <div className="collapse-buttons">
                                <div />
                                <div onClick={editUser} className="collapse-text">EDIT</div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Mobile>
            <Tablet>
                {isOpen && <tr className="collaplse-tr">
                    <td colSpan={5}>
                        <Collapse isOpen={isOpen}>
                            <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                                <div className="collapse-row">
                                    <div>Username</div>
                                    <div>{user.username}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>Created</div>
                                    <div>{user.created}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>Offer</div>
                                    <div>{props.user.userType === GetUserType(UserType.Blower) ? props.user.offerAmount : '-'}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>Executed offer</div>
                                    <div>{props.user.userType === GetUserType(UserType.Blower) ? props.user.exectutedOfferAmount : '-'}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>Executed %</div>
                                    <div>{props.user.userType === GetUserType(UserType.Blower) ? props.user.exectutedOfferPercentage : '-'}</div>
                                </div>

                                <div className="collapse-row-end">
                                    <div className="collapse-buttons">
                                        <div />
                                        <div onClick={editUser} className="collapse-text">EDIT</div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </td>
                </tr>
                }
            </Tablet>
        </>
    )
}

export default UserCollapse;
