import React from 'react';
import { Button } from 'reactstrap';


const MyButton = ({ ...props }) => {
    return (
        // eslint-disable-next-line react/prop-types
        <Button className={props.typeButton === "secondary" ? "secondary-button" : "primary-button"}  {...props} />
    )
}


export default MyButton;