import React, { useState } from 'react';
import {  Product } from '../../../interfaces/product'
import { GetCertificateName } from '../../../enums/certificateType';

interface MyTableRowProps {
    product: Product,
}

const MyTableRow = (props: MyTableRowProps) => {
    const [hovered, setHovered] = useState(false);


    return (<tr onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <th scope="row">{props.product.pos}</th>
        <td>{GetCertificateName(props.product.certificateType)}</td>
        <td>{props.product.kgPerBag}</td>
        <td>{props.product.kgPerCube}</td>
        <td>
            <div className={hovered ? "edit-delete" : 'edit-delete-hide'}>
                <div className="icon-step">{props.product.edit}</div>
            </div>
        </td>
    </tr>
    )
}

export default MyTableRow;