import * as React from 'react';
import { Location, } from 'history';
import { Container, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link, } from 'react-router-dom';
import { User } from '../interfaces/user'
import { UserType } from '../enums/userType'
import { withTranslation, WithTranslation } from "react-i18next";
import { Default, Mobile } from '../ResponsiveSizes'
import MyNavLink from './WrappedNavLink'



interface NavbarProps extends WithTranslation {
    currentUser: User
    location: Location<any>
    showNavbar: boolean
}


class NavMenu extends React.Component<NavbarProps, { isOpen: boolean, dropdownOpen: boolean, activeTab: string }> {
    public state = {
        isOpen: false,
        dropdownOpen: false,
        activeTab: '/'
    };


    componentDidMount() {
        this.SetActivePage(this.props.location.pathname)

    }

    componentDidUpdate(prevProps: NavbarProps) {
        if (prevProps.location.pathname !== this.props.location.pathname)
            this.SetActivePage(this.props.location.pathname)
    }

    componentWillUnmount() {
        this.SetActivePage("/")
    }

    SetDropdownOpen = () => {

        this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }))
    }

    SetActivePage = (position: any) => {
        this.setState({ activeTab: position })
    }





    public render() {

        const { currentUser, t, showNavbar, location } = this.props


        const logoUrl = location.pathname.startsWith("/example") ? null : currentUser.userType === UserType.Administrator ?
            "/users" :
            currentUser.userType === UserType.Blower ?
                "/transactions" : currentUser.id === -1 ? "/login" : null


        const logoUrlMobile = location.pathname.startsWith("/example") ? null : !showNavbar ? null : currentUser.userType === UserType.Administrator ?
            "/users" :
            currentUser.userType === UserType.Blower ?
                "/transactions" : currentUser.id === -1 ? "/login" : null

        const tabs = (
            <React.Fragment>
                {[UserType.Administrator].indexOf(currentUser.userType) !== -1 && (
                    <React.Fragment>
                        <NavItem className="nav-item">
                            <MyNavLink
                                tag={Link}
                                position={["/users", "/"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/users">
                                {t("Users")}
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink
                                tag={Link}
                                position={["/certificates"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/certificates">
                                {t("Certificates")}
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink
                                tag={Link}
                                position={["/products"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/products">
                                {t("Products")}
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink
                                tag={Link}
                                position={["/settings"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/settings">
                                {t("Settings")}
                            </MyNavLink>
                        </NavItem>
                    </React.Fragment>
                )}
                {[UserType.Blower].indexOf(currentUser.userType) !== -1 && (
                    <React.Fragment>
                        <NavItem>
                            <MyNavLink
                                tag={Link}
                                position={["/transactions", "/"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/transactions">
                                {t("Transactions")}
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink
                                tag={Link}
                                position={["/certificates"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/certificates">
                                {t("Certificates")}
                            </MyNavLink>
                        </NavItem>
                        <NavItem>
                            <MyNavLink
                                tag={Link}
                                position={["/profile"]}
                                setActiveTab={this.SetActivePage}
                                activeTab={this.state.activeTab}
                                to="/profile">
                                {t("Profile")}
                            </MyNavLink>
                        </NavItem>
                    </React.Fragment>
                )}
            </React.Fragment>)

        return (
            <header>
                <Navbar className="navbar-expand-md navbar-toggleable-md border-bottom box-shadow mb-3  my-navbar" light>
                    <Container className="navbar">
                        <Default> {logoUrl ? <NavbarBrand onClick={() => this.SetActivePage(logoUrl)} className="logo" tag={Link} to={logoUrl}> </NavbarBrand> : <NavbarBrand className="logo" > </NavbarBrand>}</Default>
                        <Mobile>{logoUrlMobile ? <NavbarBrand onClick={() => this.SetActivePage(logoUrlMobile)} className="logo" tag={Link} to={logoUrlMobile} > </NavbarBrand> : <NavbarBrand className="logo"> </NavbarBrand>}</Mobile>
                        {!this.props.location.pathname.startsWith('/example') &&
                            <ul className="navbar-nav flex-grow">
                                <Default>  {currentUser.id !== -1 && tabs} </Default>
                                {currentUser.id !== -1 && <NavItem className="user-container">
                                    <Default>
                                        <div className="user-dropdown btn btn-secondary">
                                            <div className="user">
                                                <div className="user-icon" />
                                            </div>
                                            <div className="nav-text user-acc">
                                                {currentUser!.email} /
                                            </div>
                                            <div className="nav-text user-acc" onClick={() => this.SetDropdownOpen()}>
                                                <a href="/login" className="nav-text user-acc">Log out</a>
                                            </div>

                                        </div>


                                    </Default>
                                    <Mobile>
                                        {currentUser.id !== -1 &&
                                            <>
                                                <div className="user">
                                                    <div className="user-icon" />
                                                </div>
                                                <div onClick={() => this.SetDropdownOpen()}>
                                                    <NavLink tag={Link} to="/login" className="nav-text nav-bold nav-logout" >Log out</NavLink>
                                                </div>
                                            </>
                                        }
                                    </Mobile>
                                </NavItem>}
                            </ul>}

                    </Container>
                </Navbar>
                {!this.props.location.pathname.startsWith('/example') &&
                    <Mobile>
                        {currentUser.id !== -1 && showNavbar && <>
                            <Navbar className="mobile-navbar">
                                <ul className="navbar-nav flex-grow mobile-navbar-container">{tabs}</ul></Navbar>
                        </>}
                    </Mobile>
                }
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default withTranslation()(NavMenu);