/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { TransactionTable, TransactionTableDTO } from '../../../interfaces/transaction';
import { Mobile, Tablet } from '../../../ResponsiveSizes';
import { GetTransactionType } from '../../../enums/transactionState';
import { TFunction } from 'i18next';

interface MyTableRowProps {
    transaction: TransactionTable,
    openModalWithTransaction: (type: string, productDTO?: TransactionTableDTO) => void,
    handleArchive: (id: number, isActive: boolean) => void,
    tab: number,
    t: TFunction
}

const chevronUp = require("../../../assets/Chevron-up.svg") as string;
const chevronDown = require("../../../assets/Chevron-down.svg") as string;

const TransactionCollapse = (props: MyTableRowProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {

        setIsOpen(!isOpen)
    };

    const archiveTransaction = (event: any) => {
        event.stopPropagation()

        props.handleArchive(transaction.id, props.tab === 0 ? false : true)
    }

    const editTransaction = (event: any) => {
        event.stopPropagation()
        props.openModalWithTransaction("edit", transaction.edit.props.transactionDTO)
    }

    const { transaction, tab, t } = props

    return (
        <>
            <Mobile>
                <div color="primary" onClick={toggle} className={isOpen ?
                    " collapse-active collapse-header" :
                    "collapse-header collapse-header-border"}>

                    <div>
                        <div className="collapse-header-title">{transaction.name}</div>
                        <div className="collapse-header-type">{transaction.contactDeliveredName}</div>
                    </div>
                    <div> <img src={isOpen ? chevronUp : chevronDown} alt="" /></div>
                </div>
            </Mobile>
            <Tablet>
                <tr onClick={toggle}>
                    <td>{props.transaction.name}</td>
                    <td>{props.transaction.contactDeliveredName}</td>
                    <td>{GetTransactionType(transaction.state)}</td>
                    <td > <img src={isOpen ? chevronUp : chevronDown} alt="" /></td>
                </tr>
            </Tablet>
            <Mobile>
                <Collapse isOpen={isOpen}>
                    <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                        <div className="collapse-row">
                            <div>{t("Status")}</div>
                            <div>{GetTransactionType(transaction.state)}</div>
                        </div>

                        <div className="collapse-row">
                            <div>{t("Phone")}</div>
                            <div>{transaction.contactDeliveredPhone}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Address")}</div>
                            <div>{transaction.contactBilledAddress1}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Zip code")}</div>
                            <div>{transaction.contactBilledZipCode}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Town")}</div>
                            <div>{transaction.contactBilledCity}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Phone")}</div>
                            <div>{transaction.contactBilledPhone}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Date")}</div>
                            <div>{transaction.dateCreated}</div>
                        </div>
                        <div className="collapse-row-end">
                            <div className="collapse-buttons">
                                <div onClick={archiveTransaction} className="collapse-text">{tab === 0 ? "ARCHIVE" : "RECOVERY"}</div>
                                <div onClick={editTransaction} className="collapse-text">EDIT</div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Mobile>
            <Tablet>
                {isOpen && <tr className="collaplse-tr">
                    <td colSpan={4}>
                        <Collapse isOpen={isOpen}>
                            <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                                <div className="collapse-row">
                                    <div>{t("Phone")}</div>
                                    <div>{transaction.contactDeliveredPhone}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Address")}</div>
                                    <div>{transaction.contactBilledAddress1}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Zip code")}</div>
                                    <div>{transaction.contactBilledZipCode}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Town")}</div>
                                    <div>{transaction.contactBilledCity}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Phone")}</div>
                                    <div>{transaction.contactBilledPhone}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Date")}</div>
                                    <div>{transaction.dateCreated}</div>
                                </div>
                                <div className="collapse-row-end">
                                    <div className="collapse-buttons">
                                        <div onClick={archiveTransaction} className="collapse-text">{tab === 0 ? "ARCHIVE" : "RECOVERY"}</div>
                                        <div onClick={editTransaction} className="collapse-text">EDIT</div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </td>
                </tr>
                }
            </Tablet>

        </>
    )
}

export default TransactionCollapse;