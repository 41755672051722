import React from 'react';
import { TransactionTableDTO } from '../../../interfaces/transaction'

interface ArchiveProps {
    handleArchive: (id: number, isActive: boolean) => void
    transactionDTO: TransactionTableDTO,
    tab: number
}

const Archive = (props: ArchiveProps) => {

    return (
        <div className="archive-icon" onClick={() => props.handleArchive(props.transactionDTO.id, props.tab === 0 ? false : true)} />
    )
}


export default Archive;