import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router';
import * as LoginStore from '../../store/Login';
import { existRecoveryCode, changePassword, recovery } from '../../api/user'
import MyButton from "../../components/MyButton/MyButton";
import { toast } from "react-toastify";
import { withTranslation, WithTranslation } from "react-i18next";


// eslint-disable-next-line @typescript-eslint/no-var-requires
const backArrow = require("../../assets/Keyboard_backspace.svg") as string;



interface RecoveryFormState {
  existRecoveryCode?: boolean | null
  recoveryError?: string | null
}

type LoginProps = WithTranslation &
  LoginStore.UserState &
  typeof LoginStore.actionCreators &
  RouteComponentProps<{ code: string }>;


class RecoveryForm extends React.Component<LoginProps, RecoveryFormState>{

  constructor(props: any) {
    super(props);
    this.state = {
      existRecoveryCode: null,
      recoveryError: null
    };
  }

  componentDidMount() {

    existRecoveryCode(this.props.match.params.code).then((response) => {

      this.setState({ existRecoveryCode: response.data })
    })


  }

  public render() {
    const initialValuesForRecovery = { email: '' };
    const initialValuesForConfirm = { newPassword: '', confirmPassword: '' }

    const { code } = this.props.match.params
    const { t } = this.props

    const recoverSuccessfullySent = () => toast(t("Recovery successfully sent"));
    const notifySuccess = () => toast(t("Password has been successufully updated"));

    return (
      <React.Fragment>
        <div className="login-container">
          <div className="login-content">
            <div className="login-title">
              <div className="recovery-title">
                <div className="recovery-img">{!this.state.existRecoveryCode && <img onClick={() => { this.props.history.push('/login') }} src={backArrow} alt='' />} </div>
                <div className="recovery-txt">{t("Recovery")}</div>
              </div>
              <div className="login-error">
                {this.state.recoveryError && <div className="input-feedback">{this.state.recoveryError}</div>}
              </div>
            </div>
            {this.state.existRecoveryCode === false && (
              <Formik
                initialValues={initialValuesForRecovery}
                onSubmit={async (values, { setSubmitting }) => {
                  const result = await recovery(values.email)

                  if (result.data) {
                    recoverSuccessfullySent()
                    setTimeout(() => { this.props.history.push("/login") }, 3500)
                  }
                  setSubmitting(false);
                }}

                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(t("Email must be a valid"))
                    .required(t("Required")),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} className="login-form">

                      <label className="form-title" htmlFor="email">{t("Email")}</label>
                      <input
                        name="email"
                        type="text"
                        placeholder={t("Type")}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.email && (touched.email ? "error" : "")}
                      />
                      {errors.email && touched.email && (
                        <div className="input-feedback">{errors.email}</div>
                      )}
                      <div className="login-button-container">
                        <MyButton type="submit" disabled={isSubmitting}>
                          {t("Recovery")}
                        </MyButton>
                      </div>
                    </form>
                  );
                }}
              </Formik>)}
            {this.state.existRecoveryCode === true && (
              <Formik
                initialValues={initialValuesForConfirm}
                onSubmit={async (values, { setSubmitting }) => {

                  if (values.newPassword !== values.confirmPassword)
                    this.setState({ recoveryError: t("Passwords don't match") })

                  if (values.newPassword === values.confirmPassword) {
                    this.setState({ recoveryError: null })
                    const result = await changePassword(code, values.newPassword)

                    if (result.data) {
                      notifySuccess()
                      setTimeout(() => { this.props.history.push("/login") }, 3500)
                    }


                  }
                  setSubmitting(false);
                }}

                validationSchema={Yup.object().shape({
                  newPassword: Yup.string()
                    .required(t("Required"))
                    .min(8, t("Password is too short - should be 8 chars minimum"))
                    .matches(/(?=.*[0-9])/, t("Password must contain a number")),
                  confirmPassword: Yup.string()
                    .required(t("Required"))
                    .min(8, t("Password is too short - should be 8 chars minimum"))
                    .matches(/(?=.*[0-9])/, t("Password must contain a number"))

                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} className="login-form">
                      <label className="form-title" htmlFor="newPassword">{t("New Password")}</label>
                      <input
                        name="newPassword"
                        type="password"
                        placeholder={t("Type")}
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.newPassword && (touched.newPassword ? "error" : "")}
                      />
                      {errors.newPassword && touched.newPassword && (
                        <div className="input-feedback">{errors.newPassword}</div>
                      )}

                      <label className="form-title" htmlFor="confirmPassword">{t("Confirm New Password")}</label>
                      <input
                        name="confirmPassword"
                        type="password"
                        placeholder={t("Type")}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.confirmPassword && (touched.confirmPassword ? "error" : "")}
                      />
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="input-feedback">{errors.confirmPassword}</div>
                      )}
                      <div className="login-button-container">
                        <MyButton type="submit" disabled={isSubmitting}>
                          {t("Recovery")}
                        </MyButton>
                      </div>
                    </form>
                  );
                }}
              </Formik>)
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(connect(
  (state: ApplicationState) => state.login,   // Selects which state properties are merged into the component's props
  LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(RecoveryForm as any));
