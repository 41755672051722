/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getAllSettings, editSettings } from '../../api/settings';
import { SettingsDTO } from '../../interfaces/settings';
import { Input, Col, Row, Label } from 'reactstrap';
import MyButton from '../../components/MyButton/MyButton';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';



const Settings = () => {

    const { t } = useTranslation();
    const [settings, setSettings] = useState<SettingsDTO[]>([])
    const notifyEdit = () => toast(t("Settings were edited successfully"));

    useEffect(() => {
        getAllSettings().then((response) => {
            if (response.data) {
                setSettings(response.data)
            }
        })
    }, [])

    const handleChange = (key: string, value: string) => {

        const result = [...settings]
        if (result) {
            if (result.filter(r => r.key === key)[0])
                result.filter(r => r.key === key)[0].value = value
            setSettings(result)
        }
    }

    const handleSubmit = () => {
        editSettings(settings).then(response => {
            if (response.data) {
                notifyEdit()
            }
        })
    }

    return (
        <div>
            <div className="padding-container"><div className="title">{t("Settings")}</div></div>
            <div className="certificate-block-container">
                <div className="certificate-block">
                    {settings.map(s => {
                        return (

                            <div key={s.id}>
                                <Row>
                                    <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                        <Row className="row-full-width">
                                            <Label sm={3} md={3} xl={3} className="label-input certificate-input-title">{s.key + ":"}</Label>
                                            <Col sm={9} md={9} xl={9} className="col-padding-removed">
                                                <div className="input-flex-end">
                                                    <Input
                                                        className={`certificate-input`}
                                                        value={s.value}
                                                        onChange={(event) => handleChange(s.key, event.target.value)}
                                                    />

                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}
                </div>
                <div className="settings-submit-container">
                    <MyButton onClick={handleSubmit}>
                        {t("Submit")}
                    </MyButton>
                </div>
            </div>
        </div>
    )
}


export default Settings;
