/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux';
//import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from 'react-redux'
import { Input, Table, Modal, ModalBody } from 'reactstrap';
import MyTableHeader from '../../components/MyTableHeader/MyTableHeader';
import MyTableRow from '../../components/Transactions/MyTableRow/MyTableRow';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SortDTO, SearchDTO } from '../../interfaces/general';
import Edit from '../../components/Transactions/Edit/Edit'
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import MyTableSearch from '../../components/Users/UserTableSearch/UserTableSearch';
import { toast } from 'react-toastify';
import { actionCreators } from '../../store/Navbar';
import { Default, Mobile, Tablet, Desktop } from '../../ResponsiveSizes';
import { getFilteredTransactionsForTableByIndex, setActiveForTransaction } from '../../api/transaction';
import { TransactionTableDTO, TransactionTable } from '../../interfaces/transaction';
import AddEditTransaction from '../../components/Transactions/AddEditTransaction/AddEditTransaction';
import { RouteComponentProps } from 'react-router';
import Archive from '../../components/Transactions/Archive/Archive';
import MyButton from '../../components/MyButton/MyButton';
import { ApplicationState } from '../../store';
import TransactionCollapse from '../../components/Transactions/TransactionCollapse/TransactionCollapse';
import UserTableRowTablet from '../../components/Transactions/MyTableRowTablet/MyTableRowTablet';
import { useTranslation } from 'react-i18next';

const moment = require('moment');


type TransactionProps = RouteComponentProps<{ id: string, type: string }>;

const Transactions = (props: TransactionProps) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState("")
  const [SortDTO, setSortDTO] = useState<SortDTO>()
  const [searchDTOs, setSearchDTOs] = useState<SearchDTO[]>([{ property: "IsActive", searchTerm: "true" }])
  const [transactions, setTransactions] = useState<TransactionTable[]>([]);
  const [selectedTransactionId, setSelectedTransactionId] = useState<number | undefined>(undefined);
  const [transactionIndex, SetTransactionIndex] = useState(1)
  const [fullLength, setFullLength] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [tab, setActiveTab] = useState(0)
  const [type, setType] = useState<string>("")
  const [archiveModal, setArchiveModal] = useState<boolean>(false)
  const [selectedTransactionForArchive, setSelectedTransactionForArchive] = useState({ id: -1, isActive: false })
  const [startDate, setStartDate] = useState<Date | string>("")
  const [endDate, setEndDate] = useState<Date | string>("")



  const user = useSelector((store: ApplicationState) => store.login!.user)

  const timer: React.MutableRefObject<any> = useRef(null);
  const mounted: React.MutableRefObject<any> = useRef();
  const dispatch = useDispatch()

  const selectedTypeEvent = tab === 0 ? t("Transaction was archived successfully") : t("Transaction was recovered successfully")
  const notifyArchived = () => toast(selectedTypeEvent);




  useEffect(() => {
    setType(props.match.params.type || "")
    setSelectedTransactionId(Number(props.match.params.id))
  }, [props.match.params.id, props.match.params.type])





  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
      getFilteredTransactionsForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
        if (response.data) {
          if (response.data.item2 !== fullLength)
            setFullLength(response.data.item2)

          setTransactions(response.data.item1.map(p => {
            return UserDTOToUser(p)
          }))
        }
      })
    }
    else {
      handleFilter()
    }
  }, [filterValue, searchDTOs, itemsPerPage, startDate, endDate]
  )

  useEffect(() => {
    if (mounted.current) {
      const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
      getFilteredTransactionsForTableByIndex(transactionIndex - 1, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
        if (response.data) {
          if (response.data.item2 !== fullLength)
            setFullLength(response.data.item2)

          setTransactions(response.data.item1.map(p => {
            return UserDTOToUser(p)
          }))
        }
      })
    }
  }, [SortDTO, transactionIndex]
  )

  const handleFilter = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setSelectedPage(1)
      const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""

      getFilteredTransactionsForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
        if (response.data) {
          if (response.data.item2 !== fullLength)
            setFullLength(response.data.item2)

          setTransactions(response.data.item1.map(p => {
            return UserDTOToUser(p as TransactionTableDTO)
          }))
        }
      })
    }, 500)
  }

  const refreshData = () => {
    const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
    getFilteredTransactionsForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
      if (response.data) {
        if (response.data.item2 !== fullLength)
          setFullLength(response.data.item2)

        setTransactions(response.data.item1.map(p => {
          return UserDTOToUser(p)
        }))
      }
    })
  }

  const handleNavbarShow = (show: boolean) => {
    dispatch(actionCreators.setNavbarShow(show))
  }

  const onSearchChange = (event: any) => {
    setFilterValue(event.target.value)
  }

  const onItemPerPageChange = (event: any) => {

    if (!Number(event.target.value))
      setItemsPerPage(10)

    setItemsPerPage(event.target.value)
  }

  const onPropertySearchChange = (searchDTO: SearchDTO) => {

    const result = [...searchDTOs].filter(s => s.property !== searchDTO.property)


    if (searchDTO.property === "ENDDATE") {
      setEndDate(searchDTO.searchTerm ? moment(searchDTO.searchTerm, "DD-MM-YYYY").toDate() : "")
    }
    else if (searchDTO.property === "STARTDATE") {
      setStartDate(searchDTO.searchTerm ? moment(searchDTO.searchTerm, "DD-MM-YYYY").toDate() : "")
    }
    else {
      setSearchDTOs([...result, searchDTO])
    }
  }

  const onSortChange = (sortDTO: SortDTO) => {
    setSortDTO(sortDTO)
  }

  const handleArchive = (id: number, isActive: boolean) => {

    setSelectedTransactionForArchive({ id, isActive })
    setArchiveModal(!archiveModal)
  }


  const UserDTOToUser = (userTableDTO: TransactionTableDTO) => {
    return {
      ...userTableDTO,
      dateCreated: moment(userTableDTO.dateCreated).format('DD-MM-YYYY'),
      edit: (<Edit handleClick={SetSelectedAddEditTransaction} transactionDTO={userTableDTO} />),
      archive: (<Archive handleArchive={handleArchive} transactionDTO={userTableDTO} tab={tab} />)
    }
  }

  const SetSelectedAddEditTransaction = (type: string, transactionTableDTO?: TransactionTableDTO) => {

    handleNavbarShow(false)
    if (type === "add")
      props.history.push(`transactions/${type}`)
    else if (type === "edit")
      props.history.push(`transactions/${type}/${transactionTableDTO!.id!}`)
  }



  const HandleSetActiveTab = (index: number) => {
    setActiveTab(index)
    onPropertySearchChange({ property: "IsActive", searchTerm: index === 0 ? "true" : "false" })

  }

  const handleArchiveTransaction = () => {
    setActiveForTransaction(selectedTransactionForArchive.id, selectedTransactionForArchive.isActive).then((response) => {
      if (response.data) {
        setArchiveModal(!archiveModal)
        const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
        getFilteredTransactionsForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
          if (response.data) {
            if (response.data.item2 !== fullLength)
              setFullLength(response.data.item2)

            setTransactions(response.data.item1.map(p => {
              return UserDTOToUser(p)
            }))
          }
        }).then(() => { notifyArchived() })


      }
    })
  }

  return (
    <React.Fragment>
      {type === "" ?
        <>
          <Modal isOpen={archiveModal} toggle={() => setArchiveModal(!archiveModal)} centered className="my-modal-dialog" >
            <ModalBody className="modal-body">
              <div className="title">{t("Do you want to")}{" "}{tab === 0 ? t("archive") : t("recovery")} &quot;{transactions && transactions.filter(u => u.id === selectedTransactionForArchive.id).length > 0 &&
                transactions.filter(u => u.id === selectedTransactionForArchive.id)[0].name}&quot;?</div>
              <div className="add-user-button-container">
                <MyButton type="reset" typeButton="secondary" onClick={() => setArchiveModal(!archiveModal)}>
                  {t("Cancel")}
                </MyButton>
                <div className="step" />

                <MyButton onClick={() => handleArchiveTransaction()}>
                  {t("Submit")}
                </MyButton>

              </div>
            </ModalBody>
          </Modal>
          <Default>
            <div className="transactions-title-wrap"><div className="title">{t("Transactions")}</div>
              <div className="transactions-tabs-wrap">
                <div className={tab === 0 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(0)}>{t("Active")}</div>
                <div className={tab === 1 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(1)}>{t("Archived")}</div>
              </div>
            </div>
            <div className="products-filters-add-products">
              <div className="transactions-flex-center">
                <div className="input-container">
                  <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder="Search" type="text"></Input>
                </div>
                <Desktop><div className="date-search-container">
                  <div className="date-search-field"><MyTableSearch property="STARTDATE" placeholder={t("Start date")} onSearchChange={onPropertySearchChange} /></div>
                  <div className="date-search-field"><MyTableSearch property="ENDDATE" placeholder={t("End date")} onSearchChange={onPropertySearchChange} /></div>
                </div></Desktop>
              </div>

              <div className="add-product" onClick={() => SetSelectedAddEditTransaction("add")}>
                <div className="add-product-icon" />
                {t("Create transaction")}
              </div>
            </div>

            <Desktop>
              <Table hover responsive className="my-table" striped>
                <thead className="table-head">
                  <tr>
                    <MyTableHeader header={t("Name")} property="Name" handleSort={onSortChange} />
                    <MyTableHeader header={t("Phone")} property="ContactBilledPhone" handleSort={onSortChange} />
                    <MyTableHeader header={t("Address")} property="ContactBilledAddress1" handleSort={onSortChange} />
                    <MyTableHeader header={t("Zip")} property="ContactBilledZipCode" handleSort={onSortChange} />
                    <MyTableHeader header={t("Town")} property="ContactBilledCity" handleSort={onSortChange} />
                    <MyTableHeader header={t("Customer")} property="ContactDeliveredName" handleSort={onSortChange} />
                    <MyTableHeader header={t("Phone")} property="ContactDeliveredPhone" handleSort={onSortChange} />
                    <MyTableHeader header={t("State")} property="State" handleSort={onSortChange} />
                    <MyTableHeader header={t("Date")} property="DateCreated" handleSort={onSortChange} />
                    <th />
                  </tr>
                  <tr className="inputs-head">
                    <th><MyTableSearch property="Name" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="ContactBilledPhone" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="ContactBilledAddress1" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th className="small-th" ><MyTableSearch property="ContactBilledZipCode" onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="ContactBilledCity" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="ContactDeliveredName" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="ContactDeliveredPhone" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="State" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="DateCreated" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((p) => {


                    return (
                      <MyTableRow key={p.id} transaction={{ ...p }} />
                    )

                  })}
                </tbody>
              </Table>
            </Desktop>
            <Tablet>
              <Table hover responsive className="my-table" >
                <thead className="table-head">
                  <tr>
                    <MyTableHeader header={t("Name")} property="Name" handleSort={onSortChange} />
                    <MyTableHeader header={t("Customer")} property="ContactDeliveredName" handleSort={onSortChange} />
                    <MyTableHeader header={t("State")} property="State" handleSort={onSortChange} />
                    <th />
                  </tr>
                  <tr className="inputs-head">
                    <th><MyTableSearch property="Name" placeholder="Search" onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="ContactDeliveredName" placeholder="Search" onSearchChange={onPropertySearchChange} /></th>
                    <th><MyTableSearch property="State" placeholder="Search" onSearchChange={onPropertySearchChange} /></th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((p) => {


                    return (
                      <UserTableRowTablet key={p.id} user={{ ...p }}
                        setSelectedAddEditTransaction={SetSelectedAddEditTransaction}
                        handleArchive={handleArchive}
                        tab={tab}
                        t={t}
                      />
                    )

                  })}
                </tbody>
              </Table>
            </Tablet>

            <div className="items-per-page-container">
              <CustomPagination
                retrieveStartPosition={(position) => { SetTransactionIndex(position) }}
                itemsLength={fullLength}
                itemsAmountPerPage={Number(itemsPerPage)}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
              <div className="items-per-page-container">
                <div className="items-per-page-text">{t("Items per page")}</div>
                <div className="items-per-page-box">
                  <input className="items-per-page" type="tel" value={itemsPerPage} onChange={onItemPerPageChange} />
                </div>
              </div>
            </div>
          </Default>
          <Mobile>
            {<>
              <div className="padding-container">
                <div className="transactions-title-wrap"><div className="title">{t("Transactions")}</div>
                  <div className="transactions-tabs-wrap">
                    <div className={tab === 0 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(0)}>{t("Active")}</div>
                    <div className={tab === 1 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(1)}>{t("Archived")}</div>
                  </div>
                </div>
                <div className="products-filters-add-products">
                  <div className="input-container">
                    <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder="Search" type="text"></Input>
                  </div>
                  <div className="add-product-split-line" />
                  <div className="add-product-wrap">
                    <div className="add-product" onClick={() => SetSelectedAddEditTransaction("add")}>
                      <div className="add-product-icon" />
                      <Default>{t("Create transaction")}</Default>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-container">
                <div className="line" />
                {transactions.map((p) => {
                  return (
                    <TransactionCollapse key={p.id} transaction={p} openModalWithTransaction={SetSelectedAddEditTransaction} handleArchive={handleArchive} tab={tab} t={t} />
                  )
                })}
              </div>
            </>}
            <div className={"pagination-step"}>
              <CustomPagination
                retrieveStartPosition={(position) => { SetTransactionIndex(position) }}
                itemsLength={fullLength}
                itemsAmountPerPage={Number(itemsPerPage)}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              /></div>
          </Mobile>
          <div className="step" />
        </>
        :
        (<div>
          <div>
            <AddEditTransaction
              selectedTransactionId={selectedTransactionId!}
              type={type}
              setType={setType}
              history={props.history}
              refreshData={refreshData}
              userId={user!.id}
              setSelectedPage={setSelectedPage}
              handleNavbarShow={handleNavbarShow}
              t={t}
            />

          </div>
        </div>)}
    </React.Fragment>
  )
};

export default connect()(Transactions);
