import axios from "axios"
import { SortDTO, SearchDTO } from "../interfaces/general"
import { TransactionTuple, TransactionDTO } from "../interfaces/transaction"


export const getFilteredTransactionsForTableByIndex =
    (start: number, amount: number, sortDTO: SortDTO | undefined, searchTerm: string, searchDTOs: SearchDTO[], startDate: Date | string, endDate: Date | string) =>
        axios.get<TransactionTuple>(`/transactions/getfilteredtransactionsbyindex?
        &start=${start}&amount=${amount}&sortDTO=${JSON.stringify(sortDTO)}&searchterm=${searchTerm}&searchDTOs=${JSON.stringify(searchDTOs)}&
startDate=${startDate ? JSON.stringify(startDate) : ""}&endDate=${endDate ? JSON.stringify(endDate) : ""}`)


export const getTransactionById =
    (id: number) =>
        axios.get<TransactionDTO>(`/transactions/gettransactionbyid/${id}`)


export const editTransaction = (id: number, transactionDTO: TransactionDTO) =>
    axios.put<boolean>(`/transactions/edittransaction/${id}`, transactionDTO)


export const setActiveForTransaction = (id: number, isActive: boolean) =>
    axios.put<boolean>(`/transactions/setactivefortransaction/${id}/${isActive}`)




export const addTransaction = (transactionDTO: TransactionDTO) =>
    axios.post<number>(`/transactions/addtransaction`, transactionDTO)
