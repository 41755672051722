/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Container, Row, Col, Input } from 'reactstrap';
import MyButton from '../../MyButton/MyButton'
import { Formik } from "formik";
import * as Yup from "yup";
import { ProductDTO } from "../../../interfaces/product"
import '../../../../node_modules/text-security/dist/text-security.css'
import { Default, Mobile } from '../../../ResponsiveSizes';
import { AddTransactionLine, TransactionLineDTO } from '../../../interfaces/transactionLine';
import { GetCertificateName } from '../../../enums/certificateType';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TFunction } from 'i18next';



const backArrow = require("../../../assets/Keyboard_backspace.svg") as string;

const bold = require("../../../assets/Format_bold.svg") as string;

const italic = require("../../../assets/Format_italic.svg") as string;

const underline = require("../../../assets/Format_underlined.svg") as string;


interface AddEditTransactionLineProps {
    modal: any,
    toggle: any,
    type: string,
    selectedProduct: ProductDTO | undefined,
    transactionLine: TransactionLineDTO | undefined,
    editTransactionLine: (transactionLineDTO: TransactionLineDTO) => void
    addTransactionLine: (transactionLine: TransactionLineDTO) => void
    deleteTransactionLine: (transactionLine: TransactionLineDTO) => void,
    count: any,
    decrementCount: () => void,
    t: TFunction
}




const AddEditTransactionLine = (props: AddEditTransactionLineProps) => {

    const { modal, toggle, type, selectedProduct, addTransactionLine, transactionLine, editTransactionLine, deleteTransactionLine, count, decrementCount, t } = props

    const [editorState, setEditorState] = useState(EditorState.createEmpty())


    useEffect(() => {
        const html = transactionLine && transactionLine!.description!;
        const contentBlock = html && htmlToDraft(html);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState))
        }
    }, [transactionLine])


    useEffect(() => {

        const html = transactionLine && transactionLine!.description!;
        const contentBlock = html && htmlToDraft(html);


        if (type === "add")
            setEditorState(EditorState.createEmpty())
        else if (type === "edit" && contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState))
        }
    }, [type])




    const initialValues: AddTransactionLine = {
        id: undefined,
        insulationThickness: undefined,
        pricePerM2: undefined,
        kgPerM3: selectedProduct ? selectedProduct!.kgPerCube : undefined,
        insulationArea: undefined,
        description: '',
        otherTotal: undefined

    };

    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState)

    }

    Yup.addMethod(Yup.number, 'delocalize', function () {
        return this.transform(function (currentValue, originalValue) {
            return parseFloat(originalValue.replace(',', '.'))
        })
    })


    const content = (<Formik
        initialValues={(type === "edit" || type === "delete") ? (transactionLine || initialValues) : initialValues}
        onSubmit={async (values, { setSubmitting }) => {


            if (selectedProduct && selectedProduct.id !== -1) {
                if (type === "add") {

                    const result: TransactionLineDTO = {
                        ...values,
                        total: values.pricePerM2! * values.insulationArea!,
                        quantity: Math.ceil(Number((values.insulationThickness! / 1000 * values.kgPerM3! * values.insulationArea! / (selectedProduct!.kgPerBag || 1)))),
                        productId: selectedProduct!.id!,
                        insulationArea: Number(values.insulationArea!),
                        insulationThickness: Number(values.insulationThickness),
                        kgPerM3: Number(values.kgPerM3),
                        pricePerM2: Number(values.pricePerM2),
                        certificateId: null,
                        id: count,
                        description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    }
                    addTransactionLine(result)
                    decrementCount()
                }
                else if (type === "edit") {
                    const result: TransactionLineDTO = {
                        ...values,
                        total: values.pricePerM2! * values.insulationArea!,
                        quantity: Math.round(Number((values.insulationThickness! / 1000 * values.kgPerM3! * values.insulationArea! / (selectedProduct!.kgPerBag || 1)).toFixed(2))),
                        productId: selectedProduct!.id!,
                        insulationArea: Number(values.insulationArea!),
                        insulationThickness: Number(values.insulationThickness),
                        kgPerM3: Number(values.kgPerM3),
                        pricePerM2: Number(values.pricePerM2),
                        description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    }
                    editTransactionLine(result)
                }
                else if (type === "delete") {
                    const result: TransactionLineDTO = {
                        ...values,
                        total: values.pricePerM2! * values.insulationArea!,
                        quantity: Math.round(Number((values.insulationThickness! / 1000 * values.kgPerM3! * values.insulationArea! / (selectedProduct!.kgPerBag || 1)).toFixed(2))),
                        productId: selectedProduct!.id!,
                        insulationArea: Number(values.insulationArea!),
                        insulationThickness: Number(values.insulationThickness),
                        kgPerM3: Number(values.kgPerM3),
                        pricePerM2: Number(values.pricePerM2),
                        description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                    }
                    deleteTransactionLine(result)
                }
            }
            else if (selectedProduct && selectedProduct.id === -1) {
                if (type === "add") {

                    const result: TransactionLineDTO = {
                        ...values,
                        total: Number(values.otherTotal),                    
                        certificateId: null,
                        id: count,
                        description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                        productId: null
                        
                    }
                    addTransactionLine(result)
                    decrementCount()
                }
                else if (type === "edit") {
                    const result: TransactionLineDTO = {
                        ...values,
                        total: Number(values.otherTotal),                    
                        certificateId: null,                    
                        description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                        productId: null
                    }
                    editTransactionLine(result)
                }
                else if (type === "delete") {
                    const result: TransactionLineDTO = {
                        ...values,
                        total: Number(values.otherTotal),                    
                        certificateId: null,                    
                        description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                        productId: null
                    }
                    deleteTransactionLine(result)
                }


            }
            setEditorState(EditorState.createEmpty())
            toggle(type);
            setSubmitting(false);

        }}

        validationSchema={
            selectedProduct && (selectedProduct.id !== -1 ? Yup.object().shape({
                description: Yup.string().notRequired().nullable(),
                insulationThickness: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
                pricePerM2: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
                kgPerM3: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
                insulationArea: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
            }) : Yup.object().shape({
                description: Yup.string().notRequired().nullable(),
                otherTotal: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
            }))}
        isInitialValid={type === "add" ? false : true}

    >

        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid
            } = props;
            console.log("props", props)
            return (
                <React.Fragment>
                    <div className="title-container"><div className="title-nav"><Mobile><img onClick={() => toggle(type)} src={backArrow} alt='' /></Mobile>
                        <div className="title">{type === "add" ? t("Add Transaction Line") : type === "edit" ?
                            t("Edit Transaction Line") : type === "delete" ?
                                `${t("Do you want to delete the transaction line with product")} "${GetCertificateName(selectedProduct!.certificateType)}"?` : ""}</div>
                    </div><Default><div className="cross" onClick={() => toggle(type)} /></Default></div>
                    <div className="form-step">
                        <form onSubmit={handleSubmit} className="my-form">
                            {type !== "delete" && selectedProduct && selectedProduct.id !== -1 && (
                                <React.Fragment>
                                    <Row className="certificate-content-step transaction-line-description">
                                        <Col md="12" xs="12">
                                            < Editor
                                                editorState={editorState}
                                                placeholder={t("Description")}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                                toolbar={{
                                                    options: ['inline'],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline'],
                                                        className: "inline-editor",
                                                        bold: {
                                                            icon: bold,
                                                        },
                                                        italic: {
                                                            icon: italic,
                                                        },
                                                        underline: {
                                                            icon: underline
                                                        }
                                                    }
                                                }}

                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <label htmlFor="insulationThickness" className="input-title">{t("Thickness")}, mm</label>
                                            <Input
                                                name="insulationThickness"
                                                type="text"
                                                placeholder="Type"
                                                value={values.insulationThickness}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.insulationThickness && (touched.insulationThickness ? "error" : "")}`}
                                            />
                                            {errors.insulationThickness && touched.insulationThickness && (
                                                <div className="input-feedback">{errors.insulationThickness}</div>
                                            )}
                                        </Col>
                                        <Col md="6" xs="12">
                                            <label htmlFor="pricePerM2" className="input-title"><span>{t("Price per")} m</span><sub>2</sub></label>
                                            <Input
                                                name="pricePerM2"
                                                type="text"
                                                placeholder="Type"
                                                value={values.pricePerM2}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.pricePerM2 && (touched.pricePerM2 ? "error" : "")}`}
                                            />
                                            {errors.pricePerM2 && touched.pricePerM2 && (
                                                <div className="user-input input-feedback">{errors.pricePerM2}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" xs="12">

                                            <label htmlFor="kgPerM3" className="input-title"><span>{t("Kg per")} m</span><sub>3</sub></label>
                                            <Input
                                                name="kgPerM3"
                                                type="text"
                                                placeholder="Type"
                                                value={values.kgPerM3}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.kgPerM3 && (touched.kgPerM3 ? "error" : "")}`}
                                            />
                                            {errors.kgPerM3 && touched.kgPerM3 && (
                                                <div className="input-feedback">{errors.kgPerM3}</div>
                                            )}
                                        </Col>
                                        <Col md="6" xs="12">

                                            <label htmlFor="insulationArea" className="input-title"><span>{t("Area")}, m</span><sub>2</sub></label>
                                            <Input
                                                name="insulationArea"
                                                type="text"
                                                placeholder="Type"
                                                value={values.insulationArea}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.insulationArea && (touched.insulationArea ? "error" : "")}`}
                                            />
                                            {errors.insulationArea && touched.insulationArea && (
                                                <div className="input-feedback">{errors.insulationArea}</div>
                                            )}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                            {selectedProduct && selectedProduct.id === -1 && type !== "delete" && (
                                <React.Fragment>
                                    <Row className="certificate-content-step transaction-line-description">
                                        <Col md="12" xs="12">
                                            < Editor
                                                editorState={editorState}
                                                placeholder={t("Description")}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                                toolbar={{
                                                    options: ['inline'],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline'],
                                                        className: "inline-editor",
                                                        bold: {
                                                            icon: bold,
                                                        },
                                                        italic: {
                                                            icon: italic,
                                                        },
                                                        underline: {
                                                            icon: underline
                                                        }
                                                    }
                                                }}

                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" xs="12">
                                            <label htmlFor="otherTotal" className="input-title">{t("Total")}</label>
                                            <Input
                                                name="otherTotal"
                                                type="text"
                                                placeholder="Type"
                                                value={values.otherTotal}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.otherTotal && (touched.otherTotal ? "error" : "")}`}
                                            />
                                            {errors.otherTotal && touched.otherTotal && (
                                                <div className="input-feedback">{errors.otherTotal}</div>
                                            )}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}

                            <div className="add-user-button-container">
                                <MyButton type="reset" typeButton="secondary" onClick={toggle}>
                                    {t("Cancel")}
                                </MyButton>

                                <div className="step" />

                                <MyButton type="submit" disabled={!isValid || isSubmitting}>
                                    {type === "add" ? t("Add") : type === "edit" ? t("Edit") : type === "delete" ? t("Delete") : ""}
                                </MyButton>

                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }}
    </Formik>)

    return (
        <div>
            <Container>

                <Modal isOpen={modal} toggle={() => toggle(type)} centered className="my-modal-dialog" >
                    <ModalBody className="modal-body">
                        {content}
                    </ModalBody>
                </Modal>

            </Container>
        </div>

    );
}

export default AddEditTransactionLine;