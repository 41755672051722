import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import { SearchDTO } from '../../../interfaces/general';


interface MyTableHeaderProps {
    property: string,
    onSearchChange: (searchDTO: SearchDTO) => void,
    placeholder?: string
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment');

const MyTableSearch = (props: MyTableHeaderProps) => {

    const [date, handleChangeDate] = useState<any>()
    const { property, onSearchChange, placeholder } = props

    useEffect(() => {
        onSearchChange({ property, searchTerm: date ? moment(date).format('DD-MM-YYYY') : "" })
    }, [date])

    return (<>
        {["Created", "DateCreated", "CreatedDate", "STARTDATE", "ENDDATE"].indexOf(property) !== -1 ?

            <DatePicker
                name="dateCreated"
                autoComplete="off"
                selected={date}
                onChange={handleChangeDate}
                locale="da"
                dateFormat="dd-MM-yyyy"
                className="form-control input-search-date input-search user-input-search"
                placeholderText={placeholder}

            />
            :
            <Input className={"input-search user-input-search"} onChange={(event) => onSearchChange({ property, searchTerm: event.target.value })} placeholder={placeholder} type="text"></Input>
        }
    </>
    )
}

export default MyTableSearch;