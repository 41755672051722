/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
//import { useTranslation } from "react-i18next";
import { Input, Col, Row, Form } from 'reactstrap';
import { Formik } from "formik";
import * as Yup from "yup";
import '../../../node_modules/text-security/dist/text-security.css'
import { getBlowerById, editBlowerById } from '../../api/blower'
import { useSelector, } from 'react-redux'
import { ApplicationState } from '../../store';
import { BlowerProfileDTO } from "../../interfaces/blower"
import CertificateRowContact from '../../components/Certificates/CertificateRow/CertificateRowContact';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useTranslation } from 'react-i18next';




const save = require("../../assets/Save.svg") as string;
const upload = require("../../assets/Cloud_upload.svg") as string;
const checkbox = require("../../assets/Checkbox.svg") as string;
const checkboxSelected = require("../../assets/CheckboxSelected.svg") as string;
const close = require("../../assets/Plus.svg") as string;



const bold = require("../../assets/Format_bold.svg") as string;

const italic = require("../../assets/Format_italic.svg") as string;

const underline = require("../../assets/Format_underlined.svg") as string;








interface MyDropzoneProps {
  setBase64Image: (image: string | ArrayBuffer | null) => void
  base64Image: string | ArrayBuffer | null
}

const MyDropzone = (props: MyDropzoneProps) => {

  const { base64Image, setBase64Image } = props
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()


      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        const base64str = reader.result
        setBase64Image(base64str)
      }
      reader.readAsDataURL(file)
    })

  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/jpeg, image/png, image/gif' })



  return (
    <section>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className="dropdown-upload">
          <div className="dropdown-upload-image"><img src={upload} alt="" /></div>
          <div>
            <div>Drop file here to upload or <span><u><b>select file</b></u></span></div>
          </div>
        </div>
        <div>(880 x 180 pixels)</div>
      </div>
      <aside>
        <div className="image-close-container">
          <div className="image-container">
            <img className="image-size" src={base64Image as string} alt="" />
          </div>
          {base64Image && <div onClick={() => setBase64Image("")} className="profile-pointer"><img src={close} alt="" /></div>}
        </div>
      </aside>
    </section>
  )
}





const Profile = () => {
  const { t } = useTranslation();

  const notifyEdit = () => toast(t("Profile was edited successfully"));

  const initialValues: BlowerProfileDTO = {
    id: -1,
    username: "",
    password: "",
    identityNumber: undefined,
    name: "",
    email: "",
    city: "",
    phone: "",
    zipCode: undefined,
    address: "",
    companyName: "",
    companyEmail: "",
    phone2: "",
    svr: "",
    website: "",
    businessConditions: "",
    endTextOrder: "",
    floorSeparationWithClayDepositText: "",
    hulmurStemText: "",
    letterHeadImage: "",
    loftStemText: "",
    logoGraphicsForAttestation: "",
    logoGraphicsForLoftGranuleProCertificate: "",
    topTextEstimate: "",
    letterHeadImageContainInfo: false,
    logoGraphicsForAttestationContainInfo: false,
    logoGraphicsForLoftGranuleProCertificateContainInfo: false
  };



  const [profile, setProfile] = useState(initialValues)

  const [letterHeadImage, setLetterHeadImage] = useState<string | ArrayBuffer | null>("")
  const [logoGraphicsForAttestation, setLogoGraphicsForAttestation] = useState<string | ArrayBuffer | null>("")
  const [logoGraphicsForLoftGranuleProCertificate, setLogoGraphicsForLoftGranuleProCertificate] = useState<string | ArrayBuffer | null>("")

  const [letterHeadImageContainInfo, setLetterHeadImageContainInfo] = useState<boolean>(false)
  const [logoGraphicsForAttestationContainInfo, setLogoGraphicsForAttestationContainInfo] = useState<boolean>(false)
  const [logoGraphicsForLoftGranuleProCertificateContainInfo, setLogoGraphicsForLoftGranuleProCertificateContainInfo] = useState<boolean>(false)

  const [businessConditionsEditorState, setBusinessConditionsEditorState] = useState(EditorState.createEmpty())
  const [endTextOrderEditorState, setEndTextOrderEditorState] = useState(EditorState.createEmpty())
  const [hulmurStemTextEditorState, setHulmurStemTextEditorState] = useState(EditorState.createEmpty())
  const [loftStemTextEditorState, setLoftStemTextEditorState] = useState(EditorState.createEmpty())
  const [floorTextEditorState, setFloorTextEditorState] = useState(EditorState.createEmpty())




  const user = useSelector((store: ApplicationState) => store.login!.user)
  useEffect(() => {
    getBlowerById(user.id).then(response => {
      if (response.data) {
        setProfile(response.data)
        setLetterHeadImage(response.data.letterHeadImage)
        setLogoGraphicsForAttestation(response.data.logoGraphicsForAttestation)
        setLogoGraphicsForLoftGranuleProCertificate(response.data.logoGraphicsForLoftGranuleProCertificate)


        setLetterHeadImageContainInfo(response.data.letterHeadImageContainInfo)
        setLogoGraphicsForAttestationContainInfo(response.data.logoGraphicsForAttestationContainInfo)
        setLogoGraphicsForLoftGranuleProCertificateContainInfo(response.data.logoGraphicsForLoftGranuleProCertificateContainInfo)

        let html = response.data.businessConditions;
        let contentBlock = html && htmlToDraft(html);

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setBusinessConditionsEditorState(EditorState.createWithContent(contentState))
        }

        html = response.data.endTextOrder;
        contentBlock = html && htmlToDraft(html);

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setEndTextOrderEditorState(EditorState.createWithContent(contentState))
        }

        html = response.data.hulmurStemText;
        contentBlock = html && htmlToDraft(html);

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setHulmurStemTextEditorState(EditorState.createWithContent(contentState))
        }

        html = response.data.loftStemText;
        contentBlock = html && htmlToDraft(html);

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setLoftStemTextEditorState(EditorState.createWithContent(contentState))
        }

        html = response.data.floorSeparationWithClayDepositText;
        contentBlock = html && htmlToDraft(html);

        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setFloorTextEditorState(EditorState.createWithContent(contentState))
        }


      }
    })
  }, [])


  const onEditorStateChange = (editorState: any) => {
    setBusinessConditionsEditorState(editorState)

  }

  const onEndTextEditorStateChange = (editorState: any) => {
    setEndTextOrderEditorState(editorState)
    //
  }



  return (
    <div>
      <div>
        <Formik
          enableReinitialize
          initialValues={profile || initialValues}
          onSubmit={(values, { setSubmitting }) => {

            const result: BlowerProfileDTO =
            {
              ...values,
              zipCode: Number(values.zipCode),
              letterHeadImage: letterHeadImage as string,
              logoGraphicsForAttestation: logoGraphicsForAttestation as string,
              logoGraphicsForLoftGranuleProCertificate: logoGraphicsForLoftGranuleProCertificate as string,
              letterHeadImageContainInfo: letterHeadImageContainInfo,
              logoGraphicsForAttestationContainInfo: logoGraphicsForAttestationContainInfo,
              logoGraphicsForLoftGranuleProCertificateContainInfo: logoGraphicsForLoftGranuleProCertificateContainInfo,
              businessConditions: draftToHtml(convertToRaw(businessConditionsEditorState.getCurrentContent())),
              endTextOrder: draftToHtml(convertToRaw(endTextOrderEditorState.getCurrentContent())),
              hulmurStemText: draftToHtml(convertToRaw(hulmurStemTextEditorState.getCurrentContent())),
              loftStemText: draftToHtml(convertToRaw(loftStemTextEditorState.getCurrentContent())),
              floorSeparationWithClayDepositText: draftToHtml(convertToRaw(floorTextEditorState.getCurrentContent())),

            }


            editBlowerById(values.id, result).then(response => {
              if (response.data) {
                notifyEdit()
              }
            })


            setSubmitting(false);
          }}


          validationSchema={Yup.object().shape<BlowerProfileDTO>({
            username: Yup.string().required(t("Required")),
            email: Yup.string()
              .email(t("Email must be a valid"))
              .required(t("Required")),
            password: Yup.string().notRequired().nullable()
              .min(8, t("Password is too short - should be 8 chars minimum"))
              .matches(/(?=.*[0-9])/, t("Password must contain a number")),
            identityNumber: Yup.number().notRequired(),
            name: Yup.string().nullable(),
            address: Yup.string().nullable(),
            zipCode: Yup.number().notRequired().typeError(t('Must be a number')),
            city: Yup.string().nullable(),
            website: Yup.string().nullable(),
            companyEmail: Yup.string()
              .email(t("Email must be a valid"))
              .notRequired().nullable(),
            companyName: Yup.string().nullable(),
            id: Yup.number(),
            phone: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
            phone2:  Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
            svr: Yup.string().nullable(),
            businessConditions: Yup.string().nullable(),
            endTextOrder: Yup.string().nullable(),
            floorSeparationWithClayDepositText: Yup.string().nullable(),
            hulmurStemText: Yup.string().nullable(),
            letterHeadImage: Yup.string().nullable(),
            loftStemText: Yup.string().nullable(),
            logoGraphicsForAttestation: Yup.string().nullable(),
            logoGraphicsForLoftGranuleProCertificate: Yup.string().nullable(),
            topTextEstimate: Yup.string().nullable(),
            letterHeadImageContainInfo: Yup.boolean(),
            logoGraphicsForAttestationContainInfo: Yup.boolean(),
            logoGraphicsForLoftGranuleProCertificateContainInfo: Yup.boolean()
          })}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid
            } = props;


            return (
              <Form onSubmit={handleSubmit} id="editUser" className="transaction-form">
                <div className="padding-container"><div className="title">{t("Profile")}</div></div>
                <div className="certificate-col-title transaction-title-container">
                  <button className="transaction-icon-container" type="submit" disabled={!isValid || isSubmitting}>
                    <img src={save} alt="" />
                    <div className="transaction-icon-text">{t("Save")}</div>
                  </button>
                </div>
                <div className="certificate-block-container">
                  <Row>
                    <Col lg="6" md="12">
                      <div className="certificate-block">
                        <Row>
                          <Col md="12" xs="12" className="certificate-col-title">
                            <div>
                              {t("User detail") + ":"}
                            </div>
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Username") + ":"}
                              touched={touched}
                              value={values.username}
                              name={"username"}

                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Email") + ":"}
                              touched={touched}
                              value={values.email}
                              name={"email"}

                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Password") + ":"}
                              touched={touched}
                              value={values.password}
                              name={"password"}

                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg="6" md="12">
                      <div className="certificate-block">
                        <Row>
                          <Col md="12" xs="12" className="certificate-col-title">
                            <div>
                              {t("Company detail") + ":"}
                            </div>
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Identity number") + ":"}
                              touched={touched}
                              value={values.id || ""}
                              name={"id"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Blower name") + ":"}
                              touched={touched}
                              value={values.name || ""}
                              name={"name"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Company name") + ":"}
                              touched={touched}
                              value={values.companyName || ""}
                              name={"companyName"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Address") + ":"}
                              touched={touched}
                              value={values.address || ""}
                              name={"address"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Zip Code") + ":"}
                              touched={touched}
                              value={values.zipCode || ""}
                              name={"zipCode"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">
                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Town") + ":"}
                              touched={touched}
                              value={values.city || ""}
                              name={"city"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Phone") + ":"}
                              touched={touched}
                              value={values.phone || ""}
                              name={"phone"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Phone") + " 2:"}
                              touched={touched}
                              value={values.phone2 || ""}
                              name={"phone2"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Website") + ":"}
                              touched={touched}
                              value={values.website || ""}
                              name={"website"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col col-border-bottom">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("Company email") + ":"}
                              touched={touched}
                              value={values.companyEmail || ""}
                              name={"companyEmail"}
                            />
                          </Col>
                          <Col md="12" xs="12" className="certificate-col">

                            <CertificateRowContact
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              title={t("CVR") + ":"}
                              touched={touched}
                              value={values.svr || ""}
                              name={"svr"}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>

                </div>
                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Top text - estimate")}</div>
                  <div>{"(" + t("shown in extension of top text by") + `estimate"` + ")"}</div>
                  <Row className="certificate-content-step">
                    <Col md="12" xs="12">
                      <Input
                        name="topTextEstimate"
                        type="textarea"
                        placeholder={t("Type")}
                        value={values.topTextEstimate || ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`user-input ${errors.topTextEstimate && (touched.topTextEstimate ? "error" : "")}`}
                      />
                      {errors.topTextEstimate && touched.topTextEstimate && (
                        <div className="input-feedback input-feedback-textarea">{errors.topTextEstimate}</div>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Letterhead image")}</div>

                  <Row className="certificate-content-step">
                    <Col md="12" xs="12">
                      <MyDropzone base64Image={letterHeadImage} setBase64Image={setLetterHeadImage} />
                    </Col>
                  </Row>

                  <div className="image-contains-company-info">
                    <div onClick={() => setLetterHeadImageContainInfo(!letterHeadImageContainInfo)}><img src={letterHeadImageContainInfo ? checkboxSelected : checkbox} alt="" /></div>
                    <div className="profile-margin-left ">{t("Image contains company information")}</div>
                  </div>
                </div>
                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Hulmur stem text")}</div>
                  <Row className="certificate-content-step transaction-line-description">
                    <Col md="12" xs="12">
                      < Editor
                        editorState={hulmurStemTextEditorState}
                        placeholder={t("Type")}
                        toolbarClassName="toolbarClassName profile-toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName white-background"
                        onEditorStateChange={setHulmurStemTextEditorState}
                        toolbar={{
                          options: ['inline'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                            className: "inline-editor",
                            bold: {
                              icon: bold,
                            },
                            italic: {
                              icon: italic,
                            },
                            underline: {
                              icon: underline
                            }
                          }
                        }}

                      />
                    </Col>
                  </Row>
                </div>

                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Loft stem text")}</div>
                  <Row className="certificate-content-step transaction-line-description">
                    <Col md="12" xs="12">
                      < Editor
                        editorState={loftStemTextEditorState}
                        placeholder={t("Type")}
                        toolbarClassName="toolbarClassName profile-toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName white-background"
                        onEditorStateChange={setLoftStemTextEditorState}
                        toolbar={{
                          options: ['inline'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                            className: "inline-editor",
                            bold: {
                              icon: bold,
                            },
                            italic: {
                              icon: italic,
                            },
                            underline: {
                              icon: underline
                            }
                          }
                        }}

                      />
                    </Col>
                  </Row>
                </div>

                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Floor separation with clay deposit text")}</div>
                  <Row className="certificate-content-step transaction-line-description">
                    <Col md="12" xs="12">
                      < Editor
                        editorState={floorTextEditorState}
                        placeholder={t("Type")}
                        toolbarClassName="toolbarClassName profile-toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName white-background"
                        onEditorStateChange={setFloorTextEditorState}
                        toolbar={{
                          options: ['inline'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                            className: "inline-editor",
                            bold: {
                              icon: bold,
                            },
                            italic: {
                              icon: italic,
                            },
                            underline: {
                              icon: underline
                            }
                          }
                        }}

                      />
                    </Col>
                  </Row>
                </div>

                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("End text - order")}</div>
                  <div>{"(" + `${t("appears as bottom text at")}` + `${t("order confirmation")}` + ")"}</div>
                  <Row className="certificate-content-step transaction-line-description">
                    <Col md="12" xs="12">
                      < Editor
                        editorState={endTextOrderEditorState}
                        placeholder={t("Type")}
                        toolbarClassName="toolbarClassName profile-toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName white-background"
                        onEditorStateChange={onEndTextEditorStateChange}
                        toolbar={{
                          options: ['inline'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                            className: "inline-editor",
                            bold: {
                              icon: bold,
                            },
                            italic: {
                              icon: italic,
                            },
                            underline: {
                              icon: underline
                            }
                          }
                        }}

                      />
                    </Col>
                  </Row>
                </div>

                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Business conditions")}</div>
                  <div>{"(" + t("appears on Page") + "2)"}</div>
                  <Row className="certificate-content-step transaction-line-description">
                    <Col md="12" xs="12">
                      < Editor
                        editorState={businessConditionsEditorState}
                        placeholder="Type"
                        toolbarClassName="toolbarClassName profile-toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName white-background"
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: ['inline'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                            className: "inline-editor",
                            bold: {
                              icon: bold,
                            },
                            italic: {
                              icon: italic,
                            },
                            underline: {
                              icon: underline
                            }
                          }
                        }}

                      />
                    </Col>
                  </Row>
                </div>

                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Logo graphic for Granule Multi certificate")}</div>
                  <Row className="certificate-content-step">
                    <Col md="12" xs="12">
                      <MyDropzone base64Image={logoGraphicsForAttestation} setBase64Image={setLogoGraphicsForAttestation} />
                    </Col>
                  </Row>
                  <div className="image-contains-company-info">
                    <div onClick={() => setLogoGraphicsForAttestationContainInfo(!logoGraphicsForAttestationContainInfo)}><img src={logoGraphicsForAttestationContainInfo ? checkboxSelected : checkbox} alt="" /></div>
                    <div className="profile-margin-left ">{t("Image contains company information")}</div>
                  </div>
                </div>

                <div className="certificate-block-container">
                  <div className="certificate-block-title">{t("Logo graphic for Granule PRO certificate")}</div>
                  <Row className="certificate-content-step">
                    <Col md="12" xs="12">
                      <MyDropzone base64Image={logoGraphicsForLoftGranuleProCertificate} setBase64Image={setLogoGraphicsForLoftGranuleProCertificate} />
                    </Col>
                  </Row>
                  <div className="image-contains-company-info">
                    <div onClick={() => setLogoGraphicsForLoftGranuleProCertificateContainInfo(!logoGraphicsForLoftGranuleProCertificateContainInfo)}><img src={logoGraphicsForLoftGranuleProCertificateContainInfo ? checkboxSelected : checkbox} alt="" /></div>
                    <div className="profile-margin-left">{t("Image contains company information")}</div>
                  </div>
                </div>

              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  )
};

export default connect()(Profile);
