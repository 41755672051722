import React from 'react';
import { NavLink } from 'reactstrap';
import { TransactionLineDTO } from '../../../../interfaces/transactionLine'

interface EditProps {
    value?: boolean,
    handleClick: (type: string, transactionLineDTO: TransactionLineDTO) => void
    transactionLineDTO: TransactionLineDTO
}

const Edit = (props: EditProps) => {

    return (
        <div className="edit-icon" onClick={() => props.handleClick("edit", props.transactionLineDTO)} />
    )
}


export default Edit;