import React from 'react';
import { ProductDTO } from '../../../interfaces/product'

interface EditProps {
    value?: boolean,
    handleClick: (type: string, productDTO: ProductDTO) => void
    productDTO: ProductDTO
}

const Edit = (props: EditProps) => {

    return (
        <div className="edit-icon" onClick={() => props.handleClick("edit", props.productDTO)} />
    )
}


export default Edit;