import React from 'react';
import { SortDTO } from '../../interfaces/general'

interface MyTableHeaderProps {
    header: string,
    property: string
    handleSort: (sortDTO: SortDTO) => void
}


const MyTableHeader = (props: MyTableHeaderProps) => {

    return (<th><div className="header-container" ><div>{props.header}</div>
        <div className="arrow-container">
            <div className="arrow-up" onClick={() => props.handleSort({ property: props.property, desc: false })} />
            <div className="arrow-down" onClick={() => props.handleSort({ property: props.property, desc: true })} />
        </div>
    </div>
    </th >
    )
}

export default MyTableHeader;