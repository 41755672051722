import React, { useState } from 'react';
import { CertificateTable } from '../../../interfaces/certificate';
import { GetCertificateName } from '../../../enums/certificateType';

interface MyTableRowProps {
    certificate: CertificateTable,
}

const MyTableRow = (props: MyTableRowProps) => {
    const [hovered, setHovered] = useState(false);

    return (<tr onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}>
    <th scope="row">{props.certificate.id}</th>
    <td>{GetCertificateName(props.certificate.certificateType)}</td>
    <td>{props.certificate.createdDate}</td>
    <td>{props.certificate.blowerName}</td>
    <td>{props.certificate.contactDeliverdToAddress}</td>
    <td>{props.certificate.contactDeliverdToCity}</td>
    <td>{props.certificate.contactDeliverdToZipCode}</td>
    <td>
        <div className={hovered ? "edit-delete" : 'edit-delete-hide'}>
            <div className="icon-step">{props.certificate.edit}</div>
        </div>
    </td>
</tr>
)
}

export default MyTableRow;