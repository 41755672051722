import * as React from 'react';
import { Route, withRouter } from 'react-router';
import { Redirect, Switch, BrowserRouter } from "react-router-dom"
import { Location } from 'history';
import axios from "axios"
import Cookie from "js-cookie"
import { toast } from 'react-toastify';
import Layout from './components/Layout/Layout';
import * as LoginStore from './store/Login';
import * as NavbarStore from './store/Navbar';
import { ApplicationState } from './store';
import { User } from './interfaces/user'
import Login from './containers/Login/Login'
import Users from './containers/Users/Users'
import Products from './containers/Products/Products'
import Transactions from './containers/Transactions/Transactions'
import Certificates from './containers/Certificates/Certificates'
import Profile from './containers/Profile/Profile'
import RequestHandler from './hoc/RequestHandler'
import Recovery from './containers/Recovery/Recovery'
import { connect } from 'react-redux'
import PrivateRoute from './hoc/PrivateRoute'
import { UserType } from './enums/userType'
import 'react-toastify/dist/ReactToastify.css';
import Example from './containers/Example/Example';
import { getUserFromCookie } from './api/user';
import CacheBuster, { CacheBusterState } from './CacheBuster';
import Settings from './containers/Settings/Settings';


toast.configure({
    autoClose: 3000,
    draggable: false,
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    bodyClassName: "notification-body",
    toastClassName: "notification"

})



interface AppProps {
    location: Location<any>
}


type LoginProps =
    AppProps &
    LoginStore.UserState &
    NavbarStore.NavbarState &
    typeof LoginStore.actionCreators;


class App extends React.Component<LoginProps> {



    // eslint-disable-next-line react/no-deprecated
    async componentDidMount() {

        const { setUser, GetSettings } = this.props

        const result = localStorage.getItem("currentUser")

        let storageUser: User | null = null
        if (result) {
            storageUser = JSON.parse(result)
        }

        const cookie = document.cookie.split(';');
        const token = cookie[0].slice(cookie[0].indexOf("token=") + "token=".length)

        GetSettings()

        if ((Cookie.get("token") || token) && !storageUser)
            await getUserFromCookie().then((response) => {
                if (response.data) {
                    const user: User | null = response.data
                    if (user && user.id !== -1) {
                        localStorage.setItem("currentUser", JSON.stringify(user))
                        setUser(user)
                    }
                }

            })
        else if ((Cookie.get("token") || token) && storageUser) {
            setUser(storageUser)
        }
    }

    render() {
        const { location, user, show, settings } = this.props
        const showNavbar = show
        const isUserActive = user && user.id !== -1 && Cookie.get("token")

        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }: CacheBusterState) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        // You can decide how and when you want to force reload
                        refreshCacheAndReload();
                    } return (
                        <>
                            <BrowserRouter>
                                <RequestHandler axios={axios}>
                                    <>
                                        <Layout currentUser={user} showNavbar={showNavbar} settings={settings}>
                                            <Switch>
                                                <Route exact path='/login' component={Login} />
                                                <Route exact path='/recovery/:code?' component={Recovery} />
                                                <Route exact path='/example/:type?' component={Example} />
                                                {isUserActive && (<>
                                                    {(location.pathname === '/' || location.pathname === "/index.html") &&
                                                        (
                                                            user.userType === UserType.Administrator ?
                                                                (<Redirect to='/users' />)
                                                                : user.userType === UserType.Blower ?
                                                                    (<Redirect to='/transactions' />)
                                                                    : null)}


                                                    <PrivateRoute exact path='/users' component={Users} currentUser={user} roles={[UserType.Administrator]} />
                                                    <PrivateRoute exact path='/certificates/:id?' component={Certificates} currentUser={user} roles={[UserType.Administrator, UserType.Blower]} />
                                                    <PrivateRoute exact path='/products' component={Products} currentUser={user} roles={[UserType.Administrator]} />
                                                    <PrivateRoute exact path='/settings' component={Settings} currentUser={user} roles={[UserType.Administrator]} />
                                                    <PrivateRoute exact path='/transactions/:type?/:id?' component={Transactions} currentUser={user} roles={[UserType.Blower]} />
                                                    <PrivateRoute exact path='/profile' component={Profile} currentUser={user} roles={[UserType.Blower]} />


                                                </>)}
                                                {!Cookie.get("token") &&
                                                    (<Redirect from="/" to='/login' />)}
                                            </Switch>
                                        </Layout>
                                    </>
                                </RequestHandler>
                            </BrowserRouter>
                        </>);
                }}
            </CacheBuster>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        user: state.login!.user,
        show: state.navbar!.show,
        settings: state.login!.settings
    }
}

export default withRouter(connect(
    mapStateToProps,   // Selects which state properties are merged into the component's props
    LoginStore.actionCreators // Selects which action creators are merged into the component's props
)(App as any));