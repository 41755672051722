/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../store';
import { Credentials, User } from '../interfaces/user'
import { login } from '../api/user'
import { getAllSettings } from '../api/settings';
import { SettingsDTO } from '../interfaces/settings';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
    user: User
    settings: SettingsDTO[]
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface Login {
    type: 'LOGIN_REQUEST';
    user: User;
}

interface Settings {
    type: 'SETTINGS_REQUEST';
    settings: SettingsDTO[];
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = Login | Settings

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    setUser: (user: User): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'LOGIN_REQUEST', user });
    },

    Login: (loginFields: Credentials): AppThunkAction<KnownAction> => async (dispatch) => {

        const response = await login(loginFields)

        if (response.data) {
            const date = new Date();
            date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
            const expires = "; expires=" + date.toUTCString();

            document.cookie = "token" + "=" + (response.data.token || "") + expires + "; path=/";

            localStorage.setItem("currentUser", JSON.stringify(response.data))
            dispatch(actionCreators.setUser(response.data) as any)
            return response.data;
        }
        return null;

    },

    GetSettings: (): AppThunkAction<KnownAction> => async (dispatch) => {

        const response = await getAllSettings();

        if (response.data) {
            dispatch({ type: 'SETTINGS_REQUEST', settings: response.data });
            return response.data
        }

        return null;
    },
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UserState = { user: { id: -1, email: "", token: "", userType: -1 }, settings: [] as SettingsDTO[]  };




export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOGIN_REQUEST':
            return {
                ...state,
                user: action.user
            };
        case 'SETTINGS_REQUEST':
            return {
                ...state,
                settings: action.settings
            };
        default:
            break;
    }
    return state;
};
