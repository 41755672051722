import React from 'react';
import { CertificateTableDTO } from '../../../interfaces/certificate';

interface EditProps {
    value?: boolean,
    handleClick: (type: string, certificateTableDTO: CertificateTableDTO) => void
    certificateTableDTO: CertificateTableDTO
}

const Edit = (props: EditProps) => {

    return (
        <div className="edit-icon" onClick={() => props.handleClick("edit", props.certificateTableDTO)} />
    )
}


export default Edit;