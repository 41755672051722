import React from 'react';
import { UserTableDTO } from '../../../interfaces/user'

interface EditProps {
    value?: boolean,
    handleClick: (type: string, userTableDTO: UserTableDTO) => void
    userTableDTO: UserTableDTO
}

const Edit = (props: EditProps) => {

    return (
        <div className="edit-icon" onClick={() => props.handleClick("edit", props.userTableDTO)} />
    )
}


export default Edit;