import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Cookie from "js-cookie"
import { compose } from "recompose"
import { Credentials } from '../../interfaces/user'
import { User } from '../../interfaces/user'
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router';
import * as LoginStore from '../../store/Login';
import { UserType } from '../../enums/userType'
import { withTranslation, WithTranslation } from "react-i18next";
import MyButton from "../../components/MyButton/MyButton";
import { Form, Input } from "reactstrap";

// eslint-disable-next-line @typescript-eslint/no-var-requires

interface ValidatedLoginProps extends WithTranslation {
  Login: (values: Credentials) => Promise<User | null>
  setUser: (user: User) => Promise<void>
}


type LoginProps =
  ValidatedLoginProps &
  LoginStore.UserState &
  typeof LoginStore.actionCreators &
  // eslint-disable-next-line @typescript-eslint/ban-types
  RouteComponentProps<{}>;


class ValidatedLoginForm extends React.PureComponent<LoginProps>{

  state: {
    loginError?: string | null,
    loginText: string,
    loginLink: string,
  }

  constructor(props: any) {
    super(props);
    this.state = {
      loginError: null,
      loginText: "Old calculator",
      loginLink: "http://indblaeser.rockwool.dk/"
    };
  }


  async componentDidMount() {
    Cookie.remove("token")
    localStorage.removeItem("currentUser")
    document.cookie = ""

    if (this.props.settings)
      this.setState({
        loginText: this.props.settings.find(d => d.key === "Login text") && this.props.settings.find(d => d.key === "Login text")!.value || "",
        loginLink: this.props.settings.find(d => d.key === "Login link") && this.props.settings.find(d => d.key === "Login link")!.value || ""
      })

    if (this.props.user.id !== -1) {
      this.props.setUser({
        id: -1,
        email: "",
        token: "",
        userType: -1,
      })
    }
  }

  componentDidUpdate(prevState: LoginProps) {
 
    if (prevState.settings !== this.props.settings) {
      this.setState({
        loginText: this.props.settings.find(d => d.key === "Login text") && this.props.settings.find(d => d.key === "Login text")!.value || "",
        loginLink: this.props.settings.find(d => d.key === "Login link") && this.props.settings.find(d => d.key === "Login link")!.value || ""
      })
    }
  }


  recoveryPassword = () => {
    this.props.history.push("/recovery")
  }


  render() {
    const initialValues: Credentials = { email: '', password: '' };
    const { t } = this.props

    return (
      <React.Fragment>

        <div className="login-container">
          <div className="welcome-container">
            <div className="welcome">{t("Welcome to")}</div>
            <div className="bct-title">{t("Blower Certification Tool")}</div>
          </div>
          <div className="login-content">
            <div className="login-title">{t("Login")}</div>
            <div className="login-error">
              {this.state.loginError && <div className="input-feedback">{this.state.loginError}</div>}
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false);


                const credentials = { email: values.email.trim(), password: values.password.trim() }

                const loginUser = await this.props.Login(credentials)


                if (!loginUser) {
                  this.setState({ loginError: t("Incorrect email or password") })
                  return;
                }
                else if (loginUser!.id === -1) {
                  this.setState({ loginError: t("User is not active") })
                  return;
                }

                if (loginUser.userType === UserType.Administrator) {
                  this.props.history.push("/users")
                }
                else if (loginUser.userType === UserType.Blower) {
                  this.props.history.push("/transactions")
                }



              }}

              validationSchema={Yup.object().shape({
                email: Yup.string().trim()
                  .email("Email must be a valid")
                  .required(t("Required")),
                password: Yup.string().trim()
                  .required(t("Required"))
                  .min(8, t("Password is too short - should be 8 chars minimum"))
                  .matches(/(?=.*[0-9])/, t("Password must contain a number"))
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                } = props;
                return (
                  <Form onSubmit={handleSubmit} className="login-form">
                    <label className="form-title" htmlFor="email">{t("Email")}</label>
                    <Input
                      name="email"
                      type="text"
                      placeholder="Type"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.email && (touched.email ? "error" : "")}
                    />
                    {errors.email && touched.email && (
                      <div className="input-feedback">{errors.email}</div>
                    )}
                    <label className="form-title" htmlFor="password">{t("Password")}</label>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Type"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={errors.password && (touched.password ? "error" : "")}
                    />
                    {errors.password && touched.password && (
                      <div className="input-feedback">{errors.password}</div>
                    )}
                    <div className="buttons-container">
                      <div className="forgot-password-button-container">
                        <div className="forgot-password" onClick={this.recoveryPassword}>

                          {t("Forgot Password") + "?"}
                        </div>
                      </div>
                      <div className="login-button-container">
                        <div>
                          <MyButton type="submit" disabled={isSubmitting}>
                            {t("Login")}
                          </MyButton>
                        </div>
                        <div className="login-text-link">
                          <a target="_blank" rel="noreferrer" href={this.state.loginLink!}>{this.state.loginText!}</a>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => {

  return {
    user: state.login!.user,
    settings: state.login!.settings
  }
}

export default compose(connect(
  mapStateToProps,   // Selects which state properties are merged into the component's props
  LoginStore.actionCreators// Selects which action creators are merged into the component's props
), withTranslation())(ValidatedLoginForm as any)
