/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import AddUserModal from '../../components/Users/AddUserModal/AddUserModal'
import { useDispatch } from 'react-redux'
import { Input, Table } from 'reactstrap';
import MyTableHeader from '../../components/MyTableHeader/MyTableHeader';
import MyTableRow from '../../components/Users/UserTableRow/UserTableRow';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SortDTO, SearchDTO } from '../../interfaces/general';
import { getFilteredUsersForTableByIndex, createUser, editUser } from '../../api/user';
import { UserTableDTO, UserTable, AddEditUser } from '../../interfaces/user';
import Edit from '../../components/Users/Edit/Edit'
import { GetUserType } from '../../enums/userType'
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import MyTableSearch from '../../components/Users/UserTableSearch/UserTableSearch';
import { toast } from 'react-toastify';
import { actionCreators } from '../../store/Navbar';
import { Default, Mobile, Desktop, Tablet } from '../../ResponsiveSizes';
import UserCollapse from '../../components/Users/UserCollapse/UserCollapse';
import { useTranslation } from 'react-i18next';

const moment = require('moment');

const Users = () => {
    const { t } = useTranslation();

    const [filterValue, setFilterValue] = useState("")
    const [SortDTO, setSortDTO] = useState<SortDTO>()
    const [searchDTOs, setSearchDTOs] = useState<SearchDTO[]>([{ property: "IsActive", searchTerm: "true" }])
    const [modalState, setModal] = useState({ modalOpen: false, type: "" });
    const [users, setUsers] = useState<UserTable[]>([]);
    const [selectedUser, setSelectedUser] = useState<UserTableDTO | undefined>(undefined);
    const [userIndex, setUserIndex] = useState(1)
    const [fullLength, setFullLength] = useState(1)
    const [selectedPage, setSelectedPage] = useState(1)
    const [tab, setActiveTab] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [loginError, setLoginError] = useState<string | null>(null)
    const [startDate, setStartDate] = useState<Date | string>("")
    const [endDate, setEndDate] = useState<Date | string>("")

    const timer: React.MutableRefObject<any> = useRef(null);
    const mounted: React.MutableRefObject<any> = useRef();
    const dispatch = useDispatch()


    const notifyEdit = () => toast(t("User was edited successfully"));
    const notifyAdd = () => toast(t("User was added successfully"));


    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
            getFilteredUsersForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
                if (response.data) {
                    if (response.data.item2 !== fullLength)
                        setFullLength(response.data.item2)

                    setUsers(response.data.item1.map(p => {
                        return UserDTOToUser(p)
                    }))
                }
            })
        }
        else {
            handleFilter()
        }
    }, [filterValue, searchDTOs, itemsPerPage, startDate, endDate]
    )

    useEffect(() => {
        if (mounted.current) {
            const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
            getFilteredUsersForTableByIndex(userIndex - 1, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
                if (response.data) {
                    if (response.data.item2 !== fullLength)
                        setFullLength(response.data.item2)

                    setUsers(response.data.item1.map(p => {
                        return UserDTOToUser(p)
                    }))
                }
            })
        }
    }, [SortDTO, userIndex]
    )

    const handleFilter = () => {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            setSelectedPage(1)
            const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
            getFilteredUsersForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
                if (response.data) {
                    if (response.data.item2 !== fullLength)
                        setFullLength(response.data.item2)

                    setUsers(response.data.item1.map(p => {
                        return UserDTOToUser(p as UserTableDTO)
                    }))
                }
            })
        }, 500)
    }

    const openModalWithUser = (type: string, userDTO?: UserTableDTO) => {
        handleNavbarShow(false)
        setLoginError(null)

        setModal({ type, modalOpen: true });
        if (type === "edit")
            setSelectedUser(userDTO)
    }


    const handleNavbarShow = (show: boolean) => {
        dispatch(actionCreators.setNavbarShow(show))
    }

    const onSearchChange = (event: any) => {
        setFilterValue(event.target.value)
    }

    const onItemPerPageChange = (event: any) => {

        if (!Number(event.target.value))
            setItemsPerPage(10)

        setItemsPerPage(event.target.value)
    }

    const onPropertySearchChange = (searchDTO: SearchDTO) => {

        const result = [...searchDTOs].filter(s => s.property !== searchDTO.property)


        if (searchDTO.property === "ENDDATE") {
            setEndDate(searchDTO.searchTerm ? moment(searchDTO.searchTerm, "DD-MM-YYYY").toDate() : "")
        }
        else if (searchDTO.property === "STARTDATE") {
            setStartDate(searchDTO.searchTerm ? moment(searchDTO.searchTerm, "DD-MM-YYYY").toDate() : "")
        }
        else {
            setSearchDTOs([...result, searchDTO])
        }
    }

    const onSortChange = (sortDTO: SortDTO) => {
        setSortDTO(sortDTO)
    }

    const toggleModal = (type: string) => {
        setLoginError(null)
        handleNavbarShow(modalState.modalOpen)
        if (["add", "edit"].indexOf(type) !== -1)
            setModal({ type, modalOpen: !modalState.modalOpen });
        else
            setModal({ ...modalState, modalOpen: !modalState.modalOpen });
    }

    const UserDTOToUser = (userTableDTO: UserTableDTO) => {
        return {
            ...userTableDTO,
            created: moment(userTableDTO.created).format('DD-MM-YYYY'),
            userType: GetUserType(Number(userTableDTO.userType)),
            exectutedOfferPercentage: userTableDTO.exectutedOfferPercentage,
            edit: <Edit handleClick={openModalWithUser} userTableDTO={userTableDTO} />
        }
    }


    const AddUser = async (userDTO: AddEditUser) => {
        return await createUser(userDTO).then((response) => {

            if (response.data) {
                toggleModal("")
                notifyAdd()
            }
            return response.data;
        }).then((result) => {
            const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
            getFilteredUsersForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
                setSelectedPage(1)
                if (response.data) {
                    if (response.data.item2 !== fullLength)
                        setFullLength(response.data.item2)

                    setUsers(response.data.item1.map(p => {
                        return UserDTOToUser(p)
                    }))
                }
            })
            return result;
        })

    }

    const EditUser = async (userDTO: AddEditUser) => {
        return await editUser(selectedUser!.id!, userDTO).then((response) => {

            if (response.data) {
                toggleModal("")
                notifyEdit()
            }
            return response.data;
        }).then((result) => {
            const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
            getFilteredUsersForTableByIndex(userIndex - 1, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
                if (response.data) {
                    if (response.data.item2 !== fullLength)
                        setFullLength(response.data.item2)

                    setUsers(response.data.item1.map(p => {
                        return UserDTOToUser(p)
                    }))
                }
            })
            return result;
        })

    }


    const HandleSetActiveTab = (index: number) => {
        setActiveTab(index)
        onPropertySearchChange({ property: "IsActive", searchTerm: index === 0 ? "true" : "false" })

    }


    return (
        <React.Fragment>
            <AddUserModal
                t={t}
                toggle={toggleModal}
                AddUser={AddUser}
                EditUser={EditUser}
                modal={modalState.modalOpen}
                type={modalState.type}
                selectedUser={selectedUser}
                setLoginError={setLoginError}
                loginError={loginError}
            />
            <Default>
                <div className="transactions-title-wrap"><div className="title">{t("Users")}</div>
                    <div className="transactions-tabs-wrap">
                        <div className={tab === 0 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(0)}>{t("Active")}</div>
                        <div className={tab === 1 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(1)}>{t("Inactive")}</div>
                    </div>
                </div>
                <div className="products-filters-add-products">
                    <div className="transactions-flex-center">
                        <div className="input-container">
                            <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder={t("Search")} type="text"></Input>
                        </div>
                        <Desktop><div className="date-search-container">
                            <div className="date-search-field"><MyTableSearch property="STARTDATE" placeholder={t("Start date")} onSearchChange={onPropertySearchChange} /></div>
                            <div className="date-search-field"><MyTableSearch property="ENDDATE" placeholder={t("End date")} onSearchChange={onPropertySearchChange} /></div>
                        </div></Desktop>
                    </div>
                    <div className="add-product" onClick={() => toggleModal("add")}>
                        <div className="add-product-icon" />
                        {t("Add User")}
                    </div>
                </div>
                <Desktop>
                    <Table hover responsive className="my-table" striped>
                        <thead className="table-head">
                            <tr>
                                <MyTableHeader header={t("Id")} property="Id" handleSort={onSortChange} />
                                <MyTableHeader header={t("Name")} property="Name" handleSort={onSortChange} />
                                <MyTableHeader header={t("Role")} property="UserType" handleSort={onSortChange} />
                                <MyTableHeader header={t("Username")} property="Username" handleSort={onSortChange} />
                                <MyTableHeader header={t("Email")} property="Email" handleSort={onSortChange} />
                                <MyTableHeader header={t("Created")} property="Created" handleSort={onSortChange} />
                                <MyTableHeader header={t("Offer")} property="OfferAmount" handleSort={onSortChange} />
                                <MyTableHeader header={t("Executed")} property="ExectutedOfferAmount" handleSort={onSortChange} />
                                <MyTableHeader header={t("Executed")} property="ExectutedOfferPercentage" handleSort={onSortChange} />
                                <th />
                            </tr>
                            <tr className="inputs-head">
                                <th className="small-th"><MyTableSearch property="Id" onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="Name" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="UserType" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="Username" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="Email" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="Created" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th className="small-th"><MyTableSearch property="OfferAmount" onSearchChange={onPropertySearchChange} /></th>
                                <th className="small-th"><MyTableSearch property="ExectutedOfferAmount" onSearchChange={onPropertySearchChange} /></th>
                                <th className="small-th"><MyTableSearch property="ExectutedOfferPercentage" onSearchChange={onPropertySearchChange} /></th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((p) => {


                                return (
                                    <MyTableRow key={p.id} user={{ ...p }} />
                                )

                            })}
                        </tbody>
                    </Table>
                </Desktop>
                <Tablet>
                    <Table hover responsive className="my-table" >
                        <thead className="table-head">
                            <tr>
                                <MyTableHeader header={t("Id")} property="Id" handleSort={onSortChange} />
                                <MyTableHeader header={t("Name")} property="Name" handleSort={onSortChange} />
                                <MyTableHeader header={t("Role")} property="UserType" handleSort={onSortChange} />
                                <MyTableHeader header={t("Email")} property="Email" handleSort={onSortChange} />
                                <th />
                            </tr>
                            <tr className="inputs-head">
                                <th className="small-th"><MyTableSearch property="Id" onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="Name" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="UserType" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th><MyTableSearch property="Email" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((p) => {
                                return (
                                    <UserCollapse key={p.id} user={p} openModalWithUser={openModalWithUser} />
                                )
                            })}
                        </tbody>
                    </Table>
                </Tablet>
                <div className="items-per-page-container">
                    <CustomPagination
                        retrieveStartPosition={(position) => { setUserIndex(position) }}
                        itemsLength={fullLength}
                        itemsAmountPerPage={Number(itemsPerPage)}
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    />
                    <div className="items-per-page-container">
                        <div className="items-per-page-text">{t("Items per page")}</div>
                        <div className="items-per-page-box">
                            <input className="items-per-page" type="tel" value={itemsPerPage} onChange={onItemPerPageChange} />
                        </div>
                    </div>
                </div>
            </Default>
            <Mobile>
                {!modalState.modalOpen && (<>
                    <div className="padding-container">
                        <div className="transactions-title-wrap"><div className="title">{t("Users")}</div>
                            <div className="transactions-tabs-wrap">
                                <div className={tab === 0 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(0)}>{t("Active")}</div>
                                <div className={tab === 1 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(1)}>{t("Inactive")}</div>
                            </div>
                        </div>
                        <div className="products-filters-add-products">
                            <div className="input-container">
                                <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder="Search" type="text"></Input>
                            </div>
                            <div className="add-product-split-line" />
                            <div className="add-product-wrap">
                                <div className="add-product" onClick={() => toggleModal("add")}>
                                    <div className="add-product-icon" />
                                    <Default>{t("Add User")}</Default>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="collapse-container">
                        <div className="line" />
                        {users.map((p) => {
                            return (
                                <UserCollapse key={p.id} user={p} openModalWithUser={openModalWithUser} />
                            )
                        })}
                    </div>
                </>)}
                <div className={!modalState.modalOpen ? "pagination-step" : "pagination-hide"}>
                    <CustomPagination
                        retrieveStartPosition={(position) => { setUserIndex(position) }}
                        itemsLength={fullLength}
                        itemsAmountPerPage={Number(itemsPerPage)}
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                    /></div>
            </Mobile>
            <div className="step" />
        </React.Fragment>
    )
};

export default connect()(Users);
