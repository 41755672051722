export enum UserType {
    Administrator,
    Blower,

}

export const GetUserType = (pos: number) => {
    switch (pos) {
        case 0:
            return "Admin"
        case 1:
            return "Indblæser"     
        default:
            return ''
    }
}