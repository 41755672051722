import React from 'react';
import { ProductDTO } from '../../../interfaces/product'
import { TransactionLineDTO } from '../../../interfaces/transactionLine';
import NumberFormat from 'react-number-format';
import { GetCertificateName } from '../../../enums/certificateType';
import CertificateIcon from '../TransactionLinesBlock/TransactionLineCertificateIcon';
import Edit from '../TransactionLinesBlock/Edit/Edit';
import Delete from '../TransactionLinesBlock/Delete/Delete';
import { TransactionTypes } from '../../../enums/transactionState';
import { TFunction } from 'i18next';

interface TransactionLinesBlockProps {
    transactionLines: TransactionLineDTO[],
    products?: ProductDTO[] | undefined,
    history: any,
    handleSelectTransactionLine: (term: string, transactionLineDTO: TransactionLineDTO) => void
    transactionStatus: TransactionTypes,
    t: TFunction
}

const TransactionLinesBlockMobile = (props: TransactionLinesBlockProps) => {

    const { transactionLines, products, history, handleSelectTransactionLine, transactionStatus, t } = props




    const total = transactionLines!.map(t => t!.total).reduce((a, b) => a! + b!)
    const vat = total! * 0.25
    const totalWithVat = total! + vat!

    const existProducts = products || []
    return (
        <div>
            <div>
                {transactionLines!.map((tl, i) =>
                    <div key={tl.id}>
                        <div className="certificate-col-title transaction-line-mobile-small-margin">{`Product ${++i}`}</div>
                        <div className="transaction-line-mobile-step">
                            <div className="transaction-line-mobile-title">{t("Job type")}</div>
                            <div>  {GetCertificateName(existProducts.filter(m => m.id === tl.productId)[0] && existProducts.filter(m => m.id === tl.productId)[0].certificateType)}</div>
                        </div>
                        {tl.description && tl.description.length > 0 && <div className="transaction-line-mobile-step"><div className="transaction-line-mobile-title">{t("Description")}</div>
                            <div dangerouslySetInnerHTML={{ __html: tl.description }} />
                        </div>
                        }
                        <div className="transaction-line-mobile-title">{t("Prerequisites")}</div>
                        <div>
                            <div className="transaction-line-col-content"><div>{t("Area")}</div><div>{tl.insulationArea}m&#178;</div></div>
                            <div className="transaction-line-col-content"><div>{t("Thickness")}</div><div>{tl.insulationThickness}mm</div></div>
                            <div className="transaction-line-col-content"><div>{t("Packages Consumption")}</div><div>{tl.quantity}</div></div>
                        </div>
                        <div className="transaction-line-col-content transaction-line-mobile-title transaction-line-mobile-margin"><div>{t("Perfomance")}</div><div className="transaction-line-flex"><NumberFormat value={tl.total} displayType={'text'} thousandSeparator={true} /><span>&nbsp;kr.</span></div>
                        </div>
                        <div className="transaction-line-col-content transaction-line-mobile-height-margin">
                            <div><div>{tl!.certificateId && <CertificateIcon certificateId={tl!.certificateId!} history={history} />}</div> </div>
                            {transactionStatus !== 2 && <div className="transaction-line-flex">
                                <div><Edit handleClick={handleSelectTransactionLine} transactionLineDTO={tl} /></div>
                                <div><Delete handleClick={handleSelectTransactionLine} transactionLineDTO={tl} /></div>
                            </div>}
                        </div>
                    </div>
                )}
                <div className="certificate-col-title transaction-lines-statitistic transaction-line-col-content">
                    <div>{t("Sub total")}</div>
                    <div><NumberFormat value={total} displayType={'text'} thousandSeparator={true} /> kr.</div>
                </div>
                <div className="certificate-col-title transaction-lines-statitistic transaction-line-col-content">
                    <div>{t("VAT")}</div>
                    <div><NumberFormat value={vat} displayType={'text'} thousandSeparator={true} /> kr.</div>

                </div>
                <div className="certificate-col-title transaction-lines-statitistic-end transaction-line-col-content">
                    <div >{t("Total")}</div>
                    <div ><NumberFormat value={totalWithVat} displayType={'text'} thousandSeparator={true} /> kr.</div>

                </div>
            </div>
        </div>
    )
}


export default TransactionLinesBlockMobile;