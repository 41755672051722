import React, { useState, useEffect } from "react"
import PaginationItem from "./PaginationItem";


const arrows = require("../../assets/pagination-end.svg") as string;
const arrow = require("../../assets/pagination-next.svg") as string;
const arrowsDisabled = require("../../assets/pagination-end-disabled.svg") as string;
const arrowDisabled = require("../../assets/pagination-next-disabled.svg") as string;

interface NavbarProps {
  itemsLength: number,
  itemsAmountPerPage: number,
  retrieveStartPosition: (position: number) => void,
  selectedPage: number
  setSelectedPage: (page: number) => void,
}




const CustomPagination = (props: NavbarProps) => {

  const { itemsLength, retrieveStartPosition, itemsAmountPerPage, setSelectedPage, selectedPage } = props

  const [currentPageStart, setCurrentPageStart] = useState(1)
  const [startIndex, setStartIndex] = useState(1)
  const [disabledPrev, setDisabledPrev] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)

  const paginationButtonsAmount = 3
  const itemsLengthOnPage = itemsLength < itemsAmountPerPage ? itemsLength : itemsAmountPerPage
  const endIndex = (startIndex + itemsLengthOnPage) > itemsLength ?
    itemsLength : (startIndex + itemsLengthOnPage - 1)




  

  useEffect(() => {
    retrieveStartPosition(startIndex)
  }, [startIndex])

  useEffect(() => {
    if (selectedPage === 1)
      goStartPage()
  }, [selectedPage])

  useEffect(() => {
    if (currentPageStart - paginationButtonsAmount < 1) {
      setDisabledPrev(true)
    }
    else {
      setDisabledPrev(false)
    }

    let amountPages = Math.floor(itemsLength / itemsLengthOnPage)
    if (itemsLength % itemsAmountPerPage !== 0)
      amountPages++;


    if (currentPageStart + paginationButtonsAmount > amountPages) {
      setDisabledNext(true)
    }
    else {
      setDisabledNext(false)
    }


  }, [currentPageStart, itemsLength, itemsAmountPerPage])


  const goNextPages = () => {

    let amountPages = Math.floor(itemsLength / itemsLengthOnPage)

    if (itemsLength % itemsAmountPerPage !== 0)
      amountPages++;

    if (currentPageStart + paginationButtonsAmount <= amountPages) {
      setStartIndex((currentPageStart + paginationButtonsAmount - 1) * itemsAmountPerPage + 1)
      setCurrentPageStart(currentPageStart + paginationButtonsAmount)
      setSelectedPage(currentPageStart + paginationButtonsAmount)
    }
  }


  const goPrevPages = () => {

    if (currentPageStart - paginationButtonsAmount >= 1) {
      setStartIndex((currentPageStart - paginationButtonsAmount - 1) * itemsAmountPerPage + 1)
      setCurrentPageStart(currentPageStart - paginationButtonsAmount)
      setSelectedPage(currentPageStart - paginationButtonsAmount)
    }
  }

  const goStartPage = () => {
    setStartIndex(1)
    setCurrentPageStart(1)
    setSelectedPage(1)
  }

  const goEndPage = () => {

    let amountPages = Math.floor(itemsLength / itemsLengthOnPage)

    if (itemsLength % itemsAmountPerPage !== 0)
      amountPages++;


    let startPage = amountPages
    while (startPage % paginationButtonsAmount !== 1)
      startPage--


    setStartIndex(itemsLength - (itemsLength % itemsAmountPerPage !== 0 ? itemsLength % itemsAmountPerPage : itemsAmountPerPage) + 1)
    setCurrentPageStart(startPage)
    setSelectedPage(amountPages)
  }

  const handlePaginationClick = (position: number, step: number) => {

    const lastIndex = (currentPageStart + step - 1) * itemsAmountPerPage + 1
    if (lastIndex > itemsLength)
      return;

    setSelectedPage(position)
    setStartIndex((currentPageStart + step - 1) * itemsAmountPerPage + 1)
  }



  return (
    <React.Fragment>
      <div className="pagination-container">
        <div className="pagination">
          <div className="pagination-cell-start" onClick={goStartPage}><img src={disabledPrev ? arrowsDisabled : arrows} alt='' /></div>
          <div className="pagination-cell-prev" onClick={goPrevPages}><img src={disabledPrev ? arrowDisabled : arrow} alt='' /></div>
          <PaginationItem
            page={currentPageStart}
            itemsAmountPerPage={itemsAmountPerPage}
            selectedItem={selectedPage}
            itemsLength={itemsLength}
            step={1}
            handlePaginationClick={handlePaginationClick} />
          <PaginationItem
            page={currentPageStart + 1}
            itemsAmountPerPage={itemsAmountPerPage}
            selectedItem={selectedPage}
            itemsLength={itemsLength} step={2}
            handlePaginationClick={handlePaginationClick} />
          <PaginationItem
            page={currentPageStart + 2}
            itemsAmountPerPage={itemsAmountPerPage}
            selectedItem={selectedPage}
            itemsLength={itemsLength}
            step={3}
            handlePaginationClick={handlePaginationClick} />
          <div className="pagination-cell" onClick={goNextPages}><img src={!itemsLength || disabledNext ? arrowDisabled : arrow} alt='' /></div>
          <div className="pagination-cell-end" onClick={goEndPage}><img src={!itemsLength || disabledNext ? arrowsDisabled : arrows} alt='' /></div>
        </div>
        <div className="pagination-text"><div>{`Viser ${itemsLength > 0 ? startIndex : 0}-${endIndex} ud af ${itemsLength}`}</div></div>
      </div>
    </React.Fragment>
  )

}

export default CustomPagination
