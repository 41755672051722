import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { Table, Input } from 'reactstrap';
import Edit from '../../components/Products/Edit/Edit'
import AddEditDeleteProductModal from '../../components/Products/AddEditDeleteProductModal/AddEditDeleteProductModal'
import MyTableRow from '../../components/Products/ProductTableRow/ProductTableRow'
import MyTableHeader from '../../components/MyTableHeader/MyTableHeader'
import { editProductById, createProduct, filterProducts } from '../../api/products'
import { ProductDTO, Product } from '../../interfaces/product'
import { SortDTO } from '../../interfaces/general';
import { toast } from 'react-toastify';
import { Default, Mobile, Desktop, Tablet } from '../../ResponsiveSizes';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../store/Navbar';
import ProductCollapse from '../../components/Products/ProductCollapse/ProductCollapse';
import { RouteComponentProps } from 'react-router';
import { WithTranslation, useTranslation } from 'react-i18next';

type ProductProps = WithTranslation &
  // eslint-disable-next-line @typescript-eslint/ban-types
  RouteComponentProps<{}>;

const ProductsContainer = (props: ProductProps) => {

  const [filterValue, setFilterValue] = useState("")
  const [sortValue, setSortValue] = useState<SortDTO>()
  const [isActive, setIsActive] = useState<boolean>(true)
  const [modalState, setModal] = useState({ modalOpen: false, type: "" });
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductDTO | undefined>(undefined);
  const [productActive, setProductActive] = useState(false)
  const [tab, setActiveTab] = useState(0)
  const { t } = useTranslation();

  const notifyEdit = () => toast(t("Product was edited successfully"));
  const notifyAdd = () => toast(t("Product was added successfully"));


  const dispatch = useDispatch()

  useEffect(() => {
    getFilteredProducts()
  }, [filterValue]
  )

  useEffect(() => {
    handleFilter()
  }, [sortValue, isActive]
  )


  const handleAdd = (values: ProductDTO) => {
    createProduct({
      id: -1,
      kgPerBag: +values.kgPerBag!,
      kgPerCube: +values.kgPerCube!,
      certificateType: values.certificateType,
      isActive: true
    }).then((response) => {
      if (response.data)
        notifyAdd()

    }).then(() => { handleFilter() })
  }

  const handleEdit = (values: ProductDTO) => {
    editProductById(values.id!,
      {
        id: +values.id!,
        kgPerBag: +values.kgPerBag!,
        kgPerCube: +values.kgPerCube!,
        certificateType: values.certificateType,
        isActive: productActive
      }).then((response) => {
        if (response.data) {
          notifyEdit()
        }
      }).then(() => { handleFilter() })
  }

  const handleFilter = () => {
    filterProducts(sortValue, filterValue, isActive).then(response => {
      if (response.data)
        setProducts(response.data.map(p => {
          return ProductDTOToProduct(p)
        }))
    })
  }

  const toggleModal = (type: string) => {
    handleNavbarShow(modalState.modalOpen)
    if (["add", "edit", "delete"].indexOf(type) !== -1)
      setModal({ type, modalOpen: !modalState.modalOpen });
    else
      setModal({ ...modalState, modalOpen: !modalState.modalOpen });
  }

  const openModalWithProduct = (type: string, productDTO?: ProductDTO) => {
    handleNavbarShow(false)
    setModal({ type, modalOpen: true });
    if (type === "edit" || type === "delete") {
      setSelectedProduct(productDTO)
      if (productDTO)
        setProductActive(productDTO.isActive)
    }
  }

  const HandleSetActiveTab = (index: number) => {
    setActiveTab(index)
    setIsActive(index === 0 ? true : false)

  }

  const timer: MutableRefObject<any> = useRef(null);

  const getFilteredProducts = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      handleFilter()
    }, 500)
  }

  const onSortChange = (sortDTO: SortDTO) => {
    setSortValue(sortDTO)
  }

  const onSearchChange = (event: any) => {
    setFilterValue(event.target.value)
  }

  const ProductDTOToProduct = (productDTO: ProductDTO) => {
    return {
      ...productDTO,
      edit: (<Edit handleClick={openModalWithProduct} productDTO={productDTO} />)
    }
  }

  const handleNavbarShow = (show: boolean) => {
    dispatch(actionCreators.setNavbarShow(show))
  }




  return (
    <React.Fragment>
      <AddEditDeleteProductModal
        t={t}
        history={props.history}
        toggle={toggleModal}
        modal={modalState.modalOpen}
        type={modalState.type}
        selectedProduct={selectedProduct}
        handleEdit={handleEdit}
        handleFilter={handleFilter}
        handleAdd={handleAdd}
        productActive={productActive}
        setProductActive={setProductActive}
      />
      <Default>


        <div className="transactions-title-wrap"><div className="title">{t("Products")}</div>
          <div className="transactions-tabs-wrap">
            <div className={tab === 0 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(0)}>{t("Active")}</div>
            <div className={tab === 1 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(1)}>{t("Inactive")}</div>
          </div>
        </div>
        <div className="products-filters-add-products">
          <div className="input-container">
            <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder={t("Search")} type="text"></Input>
          </div>
          <div className="add-product" onClick={() => toggleModal("add")}>
            <div className="add-product-icon" />
            {t("Add Product")}
          </div>
        </div>
        <Desktop>
          <Table hover responsive className="my-table" >
            <thead className="table-head">
              <tr>
                <th>№</th>
                <MyTableHeader header={t("Product Name")} property="CertificateType" handleSort={onSortChange} />
                <MyTableHeader header="Kg/bag" property="KgPerBag" handleSort={onSortChange} />
                <MyTableHeader header={"kg/m₃"} property="KgPerCube" handleSort={onSortChange} />
                <th />
              </tr>
            </thead>
            <tbody>
              {products.map((p, i) => {

                return (
                  <MyTableRow key={p.id} product={{ ...p, pos: ++i }} />
                )

              })}
            </tbody>
          </Table>
        </Desktop>
        <Tablet>
          <Table hover responsive className="my-table" >
            <thead className="table-head">
              <tr>
                <MyTableHeader header={t("Product Name")} property="CertificateType" handleSort={onSortChange} />
                <MyTableHeader header="Kg/bag" property="KgPerBag" handleSort={onSortChange} />
                <MyTableHeader header="Kg/m₃" property="KgPerCube" handleSort={onSortChange} />
                <th />
              </tr>
            </thead>
            <tbody>
              {products.map((p) => {

                return (
                  <ProductCollapse t={t} key={p.id} product={p} openModalWithProduct={openModalWithProduct} />
                )

              })}
            </tbody>
          </Table>
        </Tablet>

      </Default>
      <Mobile>
        {!modalState.modalOpen && (<>
          <div className="padding-container">
            <div className="transactions-title-wrap"><div className="title">{t("Products")}</div>
              <div className="transactions-tabs-wrap">
                <div className={tab === 0 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(0)}>{t("Active")}</div>
                <div className={tab === 1 ? "transaction-tab transaction-tab-active" : "transaction-tab"} onClick={() => HandleSetActiveTab(1)}>{t("Inactive")}</div>
              </div>
            </div>
            <div className="products-filters-add-products">
              <div className="input-container">
                <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder={t("Search")} type="text"></Input>
              </div>
              <div className="add-product-split-line" />
              <div className="add-product-wrap">
                <div className="add-product" onClick={() => toggleModal("add")}>
                  <div className="add-product-icon" />
                  <Default>{t("Add Product")}</Default>
                </div>
              </div>
            </div>
          </div>
          <div className="collapse-container">
            <div className="line" />
            {products.map((p) => {
              return (
                <ProductCollapse t={t} key={p.id} product={p} openModalWithProduct={openModalWithProduct} />
              )
            })}
          </div>
        </>)}
      </Mobile>
    </React.Fragment>
  );
}

export default ProductsContainer;