import axios from "axios"

export const downloadOrderPdf = (certificateid: number) =>
    axios.get(`/pdfs/downloadorderpdf/${certificateid}`, { responseType: 'arraybuffer', })


export const downloadOrderRockwoolPdf = (certificateid: number) =>
    axios.get(`/pdfs/downloadorderrockwoolpdf/${certificateid}`, { responseType: 'arraybuffer', })

export const downloadOrderPdfInfo = (transactionid: number) =>
    axios.get(`/pdfs/downloadorderpdfinfo/${transactionid}`, { responseType: 'arraybuffer', })

export const downloadWorkNotesPdfInfo = (transactionid: number) =>
    axios.get(`/pdfs/downloadworknotespdfinfo/${transactionid}`, { responseType: 'arraybuffer', })

export const send = (certificateid: number, email: string) =>
    axios.get(`/pdfs/send/${certificateid}?email=${email}`)
