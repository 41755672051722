import * as React from 'react';
import { useState, useEffect, useRef } from 'react'
import { connect, useSelector } from 'react-redux';
import { Input, Table } from 'reactstrap';
import MyTableHeader from '../../components/MyTableHeader/MyTableHeader';
import MyTableRow from '../../components/Certificates/MyTableRow/MyTableRow';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { SortDTO, SearchDTO } from '../../interfaces/general';
import Edit from '../../components/Certificates/Edit/Edit'
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import MyTableSearch from '../../components/Users/UserTableSearch/UserTableSearch';
import { Default, Mobile, Tablet, Desktop } from '../../ResponsiveSizes';
import { RouteComponentProps } from 'react-router';
import { CertificateTable, CertificateTableDTO } from '../../interfaces/certificate';
import { getFilteredCertificatesForTableByIndex } from '../../api/certificate';
import EditCertificate from '../../components/Certificates/EditCertificate/EditCertificate';
import CertificateCollapse from '../../components/Certificates/CertificateCollapse/CertificateCollapse';
import { WithTranslation, useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const moment = require('moment');


type CertificatesProps = WithTranslation & RouteComponentProps<{ id: string }>;

const Certificates = (props: CertificatesProps) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState("")
  const [SortDTO, setSortDTO] = useState<SortDTO>()
  const [searchDTOs, setSearchDTOs] = useState<SearchDTO[]>([])
  const [certificates, setCertificates] = useState<CertificateTable[]>([]);
  const [certificateIndex, setCertificateIndex] = useState(1)
  const [fullLength, setFullLength] = useState(1)
  const [selectedPage, setSelectedPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [startDate, setStartDate] = useState<Date | string>("")
  const [endDate, setEndDate] = useState<Date | string>("")

  const timer: React.MutableRefObject<any> = useRef(null);
  const mounted: React.MutableRefObject<any> = useRef();

  const user = useSelector((store: ApplicationState) => store.login!.user)
  const [id, setId] = React.useState<number>(-1)



  React.useEffect(() => {
    if (Number.isInteger(Number(props.match.params.id)))
      setId(Number(props.match.params.id))
  }, [])


  useEffect(() => {
    if (Number.isInteger(Number(props.match.params.id)))
      setId(Number(props.match.params.id))
    else
      setId(-1)

  }, [props.match.params.id])





  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
      getFilteredCertificatesForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
        if (response.data) {
          if (response.data.item2 !== fullLength)
            setFullLength(response.data.item2)

          setCertificates(response.data.item1.map(p => {
            return CertificateDTOToCertificate(p)
          }))
        }
      })
    }
    else {
      handleFilter()
    }
  }, [filterValue, searchDTOs, itemsPerPage, startDate, endDate]
  )

  useEffect(() => {
    if (mounted.current) {
      const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
      getFilteredCertificatesForTableByIndex(certificateIndex - 1, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
        if (response.data) {
          if (response.data.item2 !== fullLength)
            setFullLength(response.data.item2)

          setCertificates(response.data.item1.map(p => {
            return CertificateDTOToCertificate(p)
          }))
        }
      })
    }
  }, [SortDTO, certificateIndex]
  )

  const handleFilter = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setSelectedPage(1)
      const endDateInclude = endDate && moment(endDate).add(1, "days") ? moment(endDate).add(1, "days") : ""
      getFilteredCertificatesForTableByIndex(0, itemsPerPage, SortDTO, filterValue, searchDTOs, startDate, endDateInclude).then(response => {
        if (response.data) {
          if (response.data.item2 !== fullLength)
            setFullLength(response.data.item2)

          setCertificates(response.data.item1.map(p => {
            return CertificateDTOToCertificate(p)
          }))
        }
      })
    }, 500)
  }

  const onSearchChange = (event: any) => {
    setFilterValue(event.target.value)
  }

  const onItemPerPageChange = (event: any) => {

    if (!Number(event.target.value))
      setItemsPerPage(10)

    setItemsPerPage(event.target.value)
  }

  const onPropertySearchChange = (searchDTO: SearchDTO) => {

    const result = [...searchDTOs].filter(s => s.property !== searchDTO.property)


    if (searchDTO.property === "ENDDATE") {
      setEndDate(searchDTO.searchTerm ? moment(searchDTO.searchTerm, "DD-MM-YYYY").toDate() : "")
    }
    else if (searchDTO.property === "STARTDATE") {
      setStartDate(searchDTO.searchTerm ? moment(searchDTO.searchTerm, "DD-MM-YYYY").toDate() : "")
    }
    else {
      setSearchDTOs([...result, searchDTO])
    }
  }

  const onSortChange = (sortDTO: SortDTO) => {
    setSortDTO(sortDTO)
  }


  const CertificateDTOToCertificate = (userTableDTO: CertificateTableDTO) => {
    return {
      ...userTableDTO,
      createdDate: moment(userTableDTO.createdDate).format('DD-MM-YYYY'),
      edit: (<Edit handleClick={SetSelectedAddEditTransaction} certificateTableDTO={userTableDTO} />),
    }
  }

  const SetSelectedAddEditTransaction = (type: string, transactionTableDTO?: CertificateTableDTO) => {

    props.history.push(`certificates/${transactionTableDTO!.id!}`)
  }




  return (
    <React.Fragment>
      {id !== -1 ? (
        <div>
          <EditCertificate
            id={id}
            history={props.history}
            t={t}
            user={user}
          />
        </div>
      ) : (
          <>
            <Default>
              <div className="transactions-title-wrap"><div className="title">{t("Certificates")}</div>
              </div>
              <div className="products-filters-add-products">
                <div className="certificates-flex-center">
                  <div className="input-container">
                    <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder={t("Search")} type="text"></Input>
                  </div>
                  <Desktop><div className="date-search-container">
                    <div className="date-search-field"><MyTableSearch property="STARTDATE" placeholder={t("Start date")} onSearchChange={onPropertySearchChange} /></div>
                    <div className="date-search-field"><MyTableSearch property="ENDDATE" placeholder={t("End date")} onSearchChange={onPropertySearchChange} /></div>
                  </div></Desktop>
                </div>
              </div>

              <Desktop>
                <Table hover responsive className="my-table" striped>
                  <thead className="table-head">
                    <tr>
                      <MyTableHeader header={t("Id")} property="Id" handleSort={onSortChange} />
                      <MyTableHeader header={t("Type")} property="CertificateType" handleSort={onSortChange} />
                      <MyTableHeader header={t("Date")} property="CreatedDate" handleSort={onSortChange} />
                      <MyTableHeader header={t("Blower")} property="BlowerName" handleSort={onSortChange} />
                      <MyTableHeader header={t("Address")} property="ContactDeliverdToAddress" handleSort={onSortChange} />
                      <MyTableHeader header={t("Town")} property="ContactDeliverdToCity" handleSort={onSortChange} />
                      <MyTableHeader header={t("Zip code")} property="ContactDeliverdToZipCode" handleSort={onSortChange} />
                      <th />
                    </tr>
                    <tr className="inputs-head">
                      <th><MyTableSearch property="Id" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="CertificateType" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="CreatedDate" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="BlowerName" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="ContactDeliverdToAddress" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="ContactDeliverdToCity" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="ContactDeliverdToZipCode" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {certificates.map((p) => {


                      return (
                        <MyTableRow key={p.id} certificate={{ ...p }} />
                      )

                    })}
                  </tbody>
                </Table>
              </Desktop>
              <Tablet>
                <Table hover responsive className="my-table" >
                  <thead className="table-head">
                    <tr>
                      <MyTableHeader header={t("Id")} property="Id" handleSort={onSortChange} />
                      <MyTableHeader header={t("Certificate type")} property="CertificateType" handleSort={onSortChange} />
                      <MyTableHeader header={t("Date")} property="CreatedDate" handleSort={onSortChange} />
                      <MyTableHeader header={t("Blower")} property="BlowerName" handleSort={onSortChange} />
                      <th />
                    </tr>
                    <tr className="inputs-head">
                      <th><MyTableSearch property="Id" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="CertificateType" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="CreatedDate" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th><MyTableSearch property="BlowerName" placeholder={t("Search")} onSearchChange={onPropertySearchChange} /></th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {certificates.map((p) => {


                      return (
                        <CertificateCollapse
                          key={p.id}
                          certificate={{ ...p }}
                          setSelectedAddEditTransaction={SetSelectedAddEditTransaction}
                          t={t}
                        />
                      )

                    })}
                  </tbody>
                </Table>
              </Tablet>

              <div className="items-per-page-container">
                <CustomPagination
                  retrieveStartPosition={(position) => { setCertificateIndex(position) }}
                  itemsLength={fullLength}
                  itemsAmountPerPage={Number(itemsPerPage)}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
                <div className="items-per-page-container">
                  <div className="items-per-page-text">{t("Items per page")}</div>
                  <div className="items-per-page-box">
                    <input className="items-per-page" type="tel" value={itemsPerPage} onChange={onItemPerPageChange} />
                  </div>
                </div>
              </div>
            </Default>
            <Mobile>
              {<>
                <div className="padding-container">
                  <div className="transactions-title-wrap"><div className="title">{t("Certificates")}</div>
                  </div>
                  <div className="products-filters-add-products">
                    <div className="input-container">
                      <Input className="input-search" onChange={(event) => onSearchChange(event)} placeholder="Search" type="text"></Input>
                    </div>
                  </div>
                </div>
                <div className="collapse-container">
                  <div className="line" />
                  {certificates.map((p) => {
                    return (
                      <CertificateCollapse
                        key={p.id}
                        certificate={{ ...p }}
                        setSelectedAddEditTransaction={SetSelectedAddEditTransaction}
                        t={t}
                      />
                    )
                  })}
                </div>
              </>}
              <div className={"pagination-step"}>
                <CustomPagination
                  retrieveStartPosition={(position) => { setCertificateIndex(position) }}
                  itemsLength={fullLength}
                  itemsAmountPerPage={Number(itemsPerPage)}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                /></div>
            </Mobile>
            <div className="step" />
          </>

        )}

    </React.Fragment>
  )
};

export default connect()(Certificates);
