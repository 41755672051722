/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { ProductDTO, Product } from '../../../interfaces/product'
import { Collapse } from 'reactstrap';
import { GetCertificateName } from '../../../enums/certificateType';
import { Mobile, Tablet } from '../../../ResponsiveSizes';
import { TFunction } from 'i18next';

interface MyTableRowProps {
    product: Product,
    openModalWithProduct: (type: string, productDTO?: ProductDTO) => void,
    t: TFunction
}

const chevronUp = require("../../../assets/Chevron-up.svg") as string;
const chevronDown = require("../../../assets/Chevron-down.svg") as string;

const ProductCollapse = (props: MyTableRowProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const { t } = props

    const toggle = () => {

        setIsOpen(!isOpen)
    };



    const editProduct = (event: any) => {
        event.stopPropagation()
        props.openModalWithProduct("edit", product.edit.props.productDTO)
    }

    const { product } = props

    return (
        <>
            <Mobile>
                <div color="primary" onClick={toggle}> <div className={isOpen ?
                    " collapse-active collapse-header" :
                    "collapse-header collapse-header-border"}>

                    <div>
                        <div className="collapse-header-title">{GetCertificateName(product.certificateType)}</div>
                    </div>
                    <div> <img src={isOpen ? chevronUp : chevronDown} alt="" /></div> </div>
                </div>
            </Mobile>
            <Tablet>
                <tr onClick={toggle}>
                    <td>{GetCertificateName(props.product.certificateType)}</td>
                    <td>{props.product.kgPerBag}</td>
                    <td>{props.product.kgPerCube}</td>
                    <td > <img src={isOpen ? chevronUp : chevronDown} alt="" /></td>
                </tr>
            </Tablet>
            <Mobile>
                <Collapse isOpen={isOpen}>
                    <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                        <div className="collapse-row">
                            <div>Kg/bag</div>
                            <div>{product.kgPerBag}</div>
                        </div>

                        <div className="collapse-row">
                            <div>{"Kg/m"}&#x2083;</div>
                            <div>{product.kgPerCube}</div>
                        </div>

                        <div className="collapse-row-end">
                            <div className="collapse-buttons">
                                <div />
                                <div onClick={editProduct} className="collapse-text">{t("EDIT")}</div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Mobile>
            <Tablet>
                {isOpen && <tr className="collaplse-tr">
                    <td colSpan={5}>
                        <Collapse isOpen={isOpen}>
                            <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                                <div className="collapse-row-end">
                                    <div className="collapse-buttons">
                                        <div/>
                                        <div onClick={editProduct} className="collapse-text">{t("EDIT")}</div>
                                    </div>
                                </div>
                            </div>

                        </Collapse>
                    </td>
                </tr>
                }
            </Tablet>
        </>
    )
}

export default ProductCollapse;