/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Label } from 'reactstrap';
import CertificateRowContact from '../../components/Certificates/CertificateRow/CertificateRowContact'
import CertificateRow from '../../components/Certificates/CertificateRow/CertificateRow'
import { RouteComponentProps } from 'react-router';


const selected = require("../../assets/Selected.svg") as string;
const notSelected = require("../../assets/NotSelected.svg") as string;

const radioButton = require("../../assets/Radio.svg") as string;
const radioButtonActive = require("../../assets/RadioSelected.svg") as string;

type CertificatesProps =
    RouteComponentProps<{ type: string }>;

const Certificates = (props: CertificatesProps) => {
    //  const { t, i18n } = useTranslation();
    const [type, setType] = React.useState<number>(0)


    React.useEffect(() => {
        if (Number.isInteger(Number(props.match.params.type)))
            setType(Number(props.match.params.type))
    }, [])

    return (
        <div>

            {type !== 0 && <>
                <div className="title">{"Certificate " + (Number(type) === 1 ? "1" : "2")} </div> <div className="certificate">
                    <React.Fragment>
                        <div>
                            <Row>
                                <Col lg="6" md="12">
                                    <div className="certificate-block">
                                        <Row>
                                            <Col md="12" xs="12" className="certificate-col-title">
                                                <div>
                                                    For builder / homeowner:
                    </div>
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Name:"
                                                    touched={null}
                                                    value={"Kirsten Wellner Jensen"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Address:"
                                                    touched={null}
                                                    value={"Jens Munks Vej 24"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Address:"
                                                    touched={null}
                                                    value={""}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Town:"
                                                    touched={null}
                                                    value={"Århus N"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="ZIP Code:"
                                                    touched={null}
                                                    value={"8200"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Contact Person:"
                                                    touched={null}
                                                    value={"Kirsten Wellner Jensen"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col">

                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Phone:"
                                                    touched={null}
                                                    value={"86107290"}

                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg="6" md="12">
                                    <div className="certificate-block certificate-block-step">
                                        <Row>
                                            <Col md="12" xs="12" className="certificate-col-title">
                                                <div>
                                                    For builder / homeowner:
                    </div>
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Name:"
                                                    touched={null}
                                                    value={""}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Address:"
                                                    touched={null}
                                                    value={"Jens Munks Vej 24"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Address:"
                                                    touched={null}
                                                    value={""}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Town:"
                                                    touched={null}
                                                    value={"Århus N"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">
                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="ZIP Code:"
                                                    touched={null}
                                                    value={"8200"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col col-border-bottom">

                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Contact Person:"
                                                    touched={null}
                                                    value={"Kirsten Wellner Jensen"}

                                                />
                                            </Col>
                                            <Col md="12" xs="12" className="certificate-col">

                                                <CertificateRowContact
                                                    errors={{}}
                                                    handleBlur={() => { }}
                                                    handleChange={() => { }}
                                                    title="Phone:"
                                                    touched={null}
                                                    value={"86107290"}

                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </React.Fragment>
                </div>
                <div className="certificate certificate-step">
                    <div className="certificate-block">
                        <Row>
                            <Col md="12" xs="12" className="certificate-col-title">
                                <div>
                                    Details
                    </div>
                            </Col>
                        </Row>
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Number of buildings:"
                            touched={null}
                            value={"1"}
                        />
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Isolated area:"
                            touched={null}
                            value={"140"}
                        />
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Insulation thickness:"
                            touched={null}
                            value={"80"}
                        />
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Applied consumption:"
                            touched={null}
                            value={"32"}
                        />
                        {type === 1 && <>
                            <Row className="certificate-col col-border-bottom">

                                <Label for="nature" sm={6} className="label-input certificate-input-title">Nature of the property:</Label>
                                <Col sm={6} className="certificate-radio-buttons">
                                    <div className=" col-wrap">
                                        <Row className="row-icons">
                                            <Col sm={6} ><div className="col-flex"> <div><img src={radioButton} alt="" /></div><div className="certificate-icon-step">Villa / rk.hus</div></div></Col>
                                            <Col sm={6} ><div className="col-flex"> <div><img src={radioButtonActive} alt="" /></div><div className="certificate-icon-step">High Rise</div></div></Col>
                                        </Row>
                                        <Row className="row-icons">
                                            <Col sm={6} ><div className="col-flex"> <div><img src={radioButton} alt="" /></div><div className="certificate-icon-step">Farmhouse</div></div></Col>
                                            <Col sm={6} ><div className="col-flex"> <div><img src={radioButton} alt="" /></div><div className="certificate-icon-step">Stable or similiar</div></div></Col>
                                        </Row>

                                    </div>
                                    <div className="certificate-other">
                                        <Input type="text" disabled name="username" className="certificate-input" id="username" autoComplete="off" autoSave="off"
                                            placeholder="Other"
                                        />
                                    </div>

                                </Col>
                            </Row>
                            <Row className="certificate-col col-border-bottom">

                                <Label for="username" sm={6} className="label-input certificate-input-title">Ownership:</Label>
                                <Col sm={6} className="certificate-radio-buttons">
                                    <div className=" col-wrap">
                                        <Row className="row-icons">
                                            <Col sm={6} ><div className="col-flex"> <div><img src={radioButtonActive} alt="" /></div><div className="certificate-icon-step">Private</div></div></Col>
                                            <Col sm={6} ><div className="col-flex"> <div><img src={radioButton} alt="" /></div><div className="certificate-icon-step">Social / general</div></div></Col>
                                        </Row>
                                        <Row className="row-icons">
                                            <Col><div className="col-flex"> <div><img src={radioButton} alt="" /></div><div className="certificate-icon-step">State / municipality</div></div></Col>
                                        </Row>

                                    </div>
                                    <div className="certificate-other">
                                        <Input type="text" disabled name="username" className="certificate-input" id="username" autoComplete="off" autoSave="off"
                                            placeholder="Other"
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </>}
                        {type === 2 && <>


                            <Row className="certificate-col col-border-bottom">

                                <Col sm={12} ><div className="col-flex"> <div><img src={selected} alt="" /></div><div className="certificate-icon-step">Walkway control and ventilation</div></div></Col>

                            </Row>
                            <Row className="certificate-col col-border-bottom">
                                <Col sm={12} ><div className="col-flex"> <div><img src={notSelected} alt="" /></div><div className="certificate-icon-step">Thickness measurement done</div></div></Col>
                            </Row>

                            <CertificateRow
                                errors={{}}
                                handleBlur={() => { }}
                                handleChange={() => { }}
                                title="Number:"
                                touched={null}
                                value={""}
                            />
                            <CertificateRow
                                errors={{}}
                                handleBlur={() => { }}
                                handleChange={() => { }}
                                title="Average Thickness:"
                                touched={null}
                                value={"67"}
                            />
                            <CertificateRow
                                errors={{}}
                                handleBlur={() => { }}
                                handleChange={() => { }}
                                title="Existing Insulation Thickness:"
                                touched={null}
                                value={"100"}
                            />
                        </>}
                        {type === 1 &&
                            <Row className="certificate-col col-border-bottom">

                                <Label for="username" sm={6} className="label-input certificate-input-title">Terms:</Label>
                                <Col sm={6}>
                                    <Input type="select" disabled name="select" className="certificate-input" id="exampleSelect" placeholder="Choose">
                                        <option>Choose</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </Input>
                                </Col>
                            </Row>}
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Insulation and control performed by:"
                            touched={null}
                            value={"kasper ejersbo"}
                        />
                        <Row className="certificate-col col-border-bottom">

                            <Label for="username" sm={6} className="label-input certificate-input-title">Notes:</Label>
                            <Col sm={6}>
                                <Input type="textarea" disabled name="username" value={" Tømt for Leca. Senere tilbygning med batts forsøgt isoleret uden held. Mur ud for krybekælder ikke isoleret. Sammenmuringer."} className="certificate-input certificate-textarea" id="username" autoComplete="off" autoSave="off"
                                />
                            </Col>
                        </Row>
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Work completed on:"
                            touched={null}
                            value={"30/04/2010"}
                        />
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="The certificate was issued on:"
                            touched={null}
                            value={""}
                        />
                        <CertificateRow
                            errors={{}}
                            handleBlur={() => { }}
                            handleChange={() => { }}
                            title="Identity number:"
                            touched={null}
                            value={"5050"}
                        />
                        <Row className="certificate-col col-border-bottom">

                            <Label for="username" sm={6} className="label-input certificate-input-title">Control:</Label>
                            <Col sm={6}>
                                <Input type="textarea" disabled name="username" className="certificate-input certificate-textarea" id="username" autoComplete="off" autoSave="off"
                                />
                            </Col>
                        </Row>
                    </div>
                </div></>}



        </div>
    )
};

export default connect()(Certificates);


