import React from 'react';
import { TransactionTableDTO } from '../../../interfaces/transaction'

interface EditProps {
    value?: boolean,
    handleClick: (type: string, transactionDTO: TransactionTableDTO) => void
    transactionDTO: TransactionTableDTO
}

const Edit = (props: EditProps) => {

    return (
        <div className="edit-icon" onClick={() => props.handleClick("edit", props.transactionDTO)} />
    )
}


export default Edit;