import React from 'react';
import { Col, Input, Row, Label } from 'reactstrap';

interface certificateRowProps {
    value?: any,
    handleChange?: any,
    handleBlur?: any,
    errors?: any,
    touched?: any,
    title: string,
    name?: string,
    disabled?: boolean
    nested?: boolean,
    entity?: string,
    nestedName?: string
    nestedEntityName?: string
}


const CertificateRow = (props: certificateRowProps) => {

    const { handleBlur, handleChange, errors, touched, name, title, value, disabled, nested, nestedEntityName, nestedName } = props

    let existErrors = errors && errors![name!] && (touched![name!] ? "error" : "")
    if (nested)
        existErrors = errors && errors![nestedEntityName!] && errors![nestedEntityName!][nestedName!] && (touched![nestedEntityName!] && touched![nestedEntityName!][nestedName!] ? "error" : "")


    return (
        <Row className="row-full-width">
            <Label for={name} sm={3} md={3} xl={3} className="label-input certificate-input-title">{title}</Label>
            <Col sm={9} md={9} xl={9} className="col-padding-removed">
                <div className="input-flex-end">
                    <Input
                        disabled={name === "id" ? true : disabled ? true : false}
                        name={name}
                        type={name === "password" ? "password" : "text"}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`certificate-input ${existErrors}`}
                    />
                    {!nested && errors && errors![name!] && touched![name!] && (
                        <div className="input-feedback-nested  input-feedback-contact">{errors![name!]}</div>
                    )}
                    {nested && errors && errors![nestedEntityName!] && errors![nestedEntityName!][nestedName!] && (touched![nestedEntityName!] && touched![nestedEntityName!][nestedName!]) && (
                        <div className="input-feedback-nested input-feedback-contact">{errors![nestedEntityName!][nestedName!]}</div>
                    )}
                </div>
            </Col>
        </Row>)
}

export default (CertificateRow)