/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input, Label, Form, Modal, ModalBody } from 'reactstrap';
import CertificateRowContact from '../CertificateRow/CertificateRowContact'
import CertificateRow from '../CertificateRow/CertificateRow'
import DatePicker from "react-datepicker";
import { CertificateTypes, GetCertificateName } from '../../../enums/certificateType';
import { getCertificateById, editCertificateById } from '../../../api/certificate';
import { CertificateDTO, CavityWallCertificateDTO, GranuleProCertificateDTO } from '../../../interfaces/certificate';
import { downloadOrderPdf, downloadOrderRockwoolPdf, send } from '../../../api/pdf';
import { Formik } from 'formik';
import * as Yup from "yup";
import { ContactDTO } from '../../../interfaces/contact';
import { toast } from 'react-toastify';
import { Default } from '../../../ResponsiveSizes';
import MyButton from '../../MyButton/MyButton';
import { TFunction } from 'i18next';
import { User } from '../../../interfaces/user';
import { UserType } from '../../../enums/userType';


const selected = require("../../../assets/Selected.svg") as string;
const notSelected = require("../../../assets/NotSelected.svg") as string;

const radioButton = require("../../../assets/Radio.svg") as string;
const radioButtonActive = require("../../../assets/RadioSelected.svg") as string;

interface EditCertificatesProps {
    id: number,
    history: any,
    t: TFunction,
    user: User
}

declare global {
    interface Navigator {
        msSaveBlob: (blob: Blob | string, filename: string) => void
    }
}

const moment = require('moment');
const backArrow = require("../../../assets/Keyboard_backspace.svg") as string;
const pdf = require("../../../assets/Pdf.svg") as string;
const save = require("../../../assets/Save.svg") as string;
const email = require("../../../assets/Email.svg") as string;




const EditCertificates = (props: EditCertificatesProps) => {

    const [type, setType] = React.useState<CertificateTypes>(-1)
    const [certificate, setCertificate] = React.useState<CertificateDTO | undefined>()
    const [natureOfPropertyState, setNatureOfPropertyState] = React.useState<string>("")
    const [ownershipState, setOwnershipState] = React.useState<string>("")
    const [walkwayControlAndVentilationState, setWalkwayControlAndVentilationState] = React.useState<boolean>()
    const [thicknessMeasurementDone, setThicknessMeasurementDone] = React.useState<boolean>()

    const [certificateIssuedDateState, setCertificateIssuedDate] = React.useState<Date | null | number>()
    const [certificateWorkCompletedDateState, setCertificateWorkCompletedDate] = React.useState<Date | null | number>()

    const [emailModal, setEmailModal] = React.useState<boolean>(false)
    const [submitModal, setSubmitModal] = React.useState<boolean>(false)

    const { id, t, user } = props

    const notifyEdit = () => toast(t("Certificate was edited successfully"));

    const notifySend = () => toast(t("Certificate was sent successfully"));

    useEffect(() => {
        getCertificateById(id).then((response) => {
            if (response.data) {

                setType(response.data.certificateType)


                setCertificateIssuedDate(Date.parse(response.data.certificateIssuedDate!.toString()))
                setCertificateWorkCompletedDate(Date.parse(response.data.workCompletedDate!.toString()))

                const certificateType = Number(response.data.certificateType.toString())
                if (certificateType === CertificateTypes.granuleMulti) {
                    setCertificate(response.data as CavityWallCertificateDTO)
                    setNatureOfPropertyState((response.data as CavityWallCertificateDTO)!.natureOfTheProperty!)
                    setOwnershipState((response.data as CavityWallCertificateDTO)!.ownership!)

                }
                else if (certificateType === CertificateTypes.granulePro) {
                    setCertificate(response.data as GranuleProCertificateDTO)
                    setWalkwayControlAndVentilationState((response.data as GranuleProCertificateDTO).walkwayControlAndVentilation)
                    setThicknessMeasurementDone((response.data as GranuleProCertificateDTO).thicknessMeasurementDone)
                }
            }
        })
    }, [])


    const HandleNatureOfPropertyStateChange = (term: string) => {
        setNatureOfPropertyState(term)
    }

    const HandleOwnerStateChange = (term: string) => {
        setOwnershipState(term)
    }


    const DownloadOrderPdf = (certificateId: number) => {
        downloadOrderPdf(certificateId).then((response) => {
            if (response.data) {

                const myURL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window.URL works in Firefox
                //const csv = csv;  
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const csvUrl = myURL.createObjectURL(blob);
                //setFiledownloadlink(csvUrl);

                const tempLink = document.createElement('a');
                tempLink.href = csvUrl;
                tempLink.setAttribute('download', `Attester${certificate && certificate!.contactBilledTo!.address1! ? `-${certificate.contactBilledTo.address1}` : ""}.pdf`);
                tempLink.setAttribute('target', '_self');
                tempLink.click();
            }
        })
    }

    const DownloadOrderRockwoolPdf = (certificateId: number) => {
        downloadOrderRockwoolPdf(certificateId).then((response) => {
            if (response.data) {
                const myURL = window.URL || window.webkitURL //window.webkitURL works in Chrome and window.URL works in Firefox
                //const csv = csv;  
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const csvUrl = myURL.createObjectURL(blob);
                //setFiledownloadlink(csvUrl);

                if (navigator.msSaveBlob) {
                    return navigator.msSaveBlob(blob, `Attester${certificate && certificate!.contactBilledTo!.address1! ? `-${certificate.contactBilledTo.address1}` : ""}.pdf`);
                }

                const tempLink = document.createElement('a');
                tempLink.href = csvUrl;
                tempLink.setAttribute('download', `Attester${certificate && certificate!.contactBilledTo!.address1! ? `-${certificate.contactBilledTo.address1}` : ""}.pdf`);
                tempLink.setAttribute('target', '_self');
                tempLink.click();
            }
        })
    }

    const SendEmail = (email: string) => {

        send(certificate!.id!, email).then(response => {
            if (response.data) {
                notifySend()
            }
            setEmailModal(false)
        })
    }

    const handleBack = () => {
        props.history.goBack()
    }

    const initialValues: CavityWallCertificateDTO | GranuleProCertificateDTO = {
        appliedConsumption: undefined,
        certificateIssuedDate: undefined,
        certificateType: 0,
        comments: "",
        control: "",
        contactBilledTo: {
            address1: "",
            address2: "",
            att: "",
            city: "",
            contactPerson: "",
            email: "",
            id: -1,
            name: "",
            phone: "",
            zipCode: ""
        },
        contactDeliverdTo: {
            address1: "",
            address2: "",
            att: "",
            city: "",
            contactPerson: "",
            email: "",
            id: -1,
            name: "",
            phone: "",
            zipCode: ""
        },
        createdDate: undefined,
        id: -1,
        identityNumber: undefined,
        insulationAndControlPerformedBy: "",
        insulationThickness: undefined,
        isolatedArea: undefined,
        notes: "",
        numberOfBuildings: undefined,
        workCompletedDate: undefined,
        averageThickness: undefined,
        existingInsulationThickness: undefined,
        natureOfTheProperty: "",
        number: undefined,
        ownership: "",
        terms: "",
        thicknessMeasurementDone: false,
        walkwayControlAndVentilation: false,
        transactionType: 0,
        isEdited: false
    }

    const isDisabled = (certificate && certificate.isEdited) && user.userType !== UserType.Administrator

    return (
        <div><Formik
            initialValues={certificate || initialValues}
            onSubmit={async (values, { setSubmitting }) => {


                values.certificateType = type
                values.insulationThickness = Number(values!.insulationThickness!.toString())
                values.identityNumber = Number(values!.identityNumber!.toString())
                values.isolatedArea = Number(values!.isolatedArea!.toString())
                values.numberOfBuildings = Number(values!.numberOfBuildings!.toString())


                editCertificateById(certificate!.id!, values.certificateType === CertificateTypes.granulePro ?
                    ({
                        ...values, walkwayControlAndVentilation: walkwayControlAndVentilationState, thicknessMeasurementDone: thicknessMeasurementDone,
                        workCompletedDate: moment(certificateWorkCompletedDateState), certificateIssuedDate: moment(certificateIssuedDateState)
                    } as GranuleProCertificateDTO) :
                    ({ ...values, natureOfTheProperty: natureOfPropertyState, ownership: ownershipState }) as CavityWallCertificateDTO).then((response) => {
                        if (response.data) {
                            notifyEdit()

                        }
                    }).then(() => {

                        getCertificateById(id).then((response) => {
                            if (response.data) {
                                setType(response.data.certificateType)
                                if (response.data.certificateType === CertificateTypes.granuleMulti) {
                                    setCertificate(response.data as CavityWallCertificateDTO)
                                    setNatureOfPropertyState((response.data as CavityWallCertificateDTO)!.natureOfTheProperty!)
                                    setOwnershipState((response.data as CavityWallCertificateDTO)!.ownership!)

                                }
                                else if (response.data.certificateType === CertificateTypes.granulePro) {
                                    setCertificate(response.data as GranuleProCertificateDTO)
                                    setWalkwayControlAndVentilationState((response.data as GranuleProCertificateDTO).walkwayControlAndVentilation)
                                    setThicknessMeasurementDone((response.data as GranuleProCertificateDTO).thicknessMeasurementDone)
                                }
                            }
                        })
                    })

                setSubmitModal(false);
                setSubmitting(false);

            }
            }
            enableReinitialize
            validationSchema={
                Yup.object().shape<CavityWallCertificateDTO | GranuleProCertificateDTO | any>({
                    appliedConsumption: Yup.number().notRequired().typeError(t('Must be a number')),
                    certificateIssuedDate: Yup.date().notRequired(),
                    certificateType: Yup.number(),
                    comments: Yup.string().nullable(),
                    contactBilledTo: Yup.object().shape<ContactDTO | any>({
                        address1: Yup.string().nullable(),
                        address2: Yup.string().nullable(),
                        att: Yup.string().nullable(),
                        city: Yup.string().nullable(),
                        contactPerson: Yup.string().nullable(),
                        email: Yup.string().nullable(),
                        id: Yup.number(),
                        name: Yup.string().nullable(),
                        phone: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                        zipCode: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                    }),
                    contactDeliverdTo: Yup.object().shape<ContactDTO | any>({
                        address1: Yup.string().nullable(),
                        address2: Yup.string().nullable(),
                        att: Yup.string().nullable(),
                        city: Yup.string().nullable(),
                        contactPerson: Yup.string().nullable(),
                        email: Yup.string().nullable(),
                        id: Yup.number(),
                        name: Yup.string().nullable(),
                        phone: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                        zipCode: Yup.string().notRequired().matches(/^(?:[+\d].*\d|\d)$/, t('Must be a number')).max(20, t("Value is too large")).nullable(),
                    }),
                    createdDate: Yup.date().notRequired(),
                    id: Yup.number(),
                    identityNumber: Yup.number().notRequired().typeError(t('Must be a number')),
                    insulationAndControlPerformedBy: Yup.string().nullable(),
                    insulationThickness: Yup.number().notRequired().typeError(t('Must be a number')),
                    isolatedArea: Yup.number().typeError(t('Must be a number')),
                    notes: Yup.string().nullable(),
                    numberOfBuildings: Yup.number().notRequired().typeError(t('Must be a number')),
                    workCompletedDate: Yup.date().notRequired(),
                    averageThickness: Yup.number().typeError(t('Must be a number')),
                    existingInsulationThickness: Yup.number().notRequired().typeError(t('Must be a number')),
                    natureOfTheProperty: Yup.string().nullable(),
                    number: Yup.number().notRequired().typeError(t('Must be a number')),
                    ownership: Yup.string().nullable(),
                    terms: Yup.string().nullable(),
                    thicknessMeasurementDone: Yup.boolean().notRequired(),
                    walkwayControlAndVentilation: Yup.boolean().notRequired(),
                    email: Yup.string().email(t("Email must be a valid")).notRequired()
                })}
        // isInitialValid={type === "add" ? false : true}

        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid
                } = props;

                return (
                    <Form onSubmit={handleSubmit} className="transaction-form">
                        <Modal isOpen={submitModal && certificate && !certificate.isEdited} toggle={() => setSubmitModal(!submitModal)} centered className="my-modal-dialog" >
                            <ModalBody className="modal-body">
                                <div>
                                    <Form onSubmit={handleSubmit} className="certificate-full-width">

                                        <div className="title-container"><div className="title-nav">
                                            <div />
                                        </div><Default><div className="cross" onClick={() => setSubmitModal(!submitModal)} /></Default></div>
                                        <div className="title">{"You will not be able to change any values after you save the Certificate. Please make sure all details are correct."}</div>
                                        <div className="certificate-form-step">
                                        </div>
                                        <div className="add-user-button-container">
                                            <MyButton type="reset" typeButton="secondary" onClick={() => setSubmitModal(!submitModal)}>
                                                {t("Cancel")}
                                            </MyButton>
                                            <div className="step" />

                                            <MyButton type="submit" disabled={isSubmitting}>
                                                {t("Submit")}
                                            </MyButton>
                                        </div>

                                    </Form>
                                </div>
                            </ModalBody>


                        </Modal>
                        {<>
                            <div className="title"> <div className="transaction-add-edit-back">
                                <img onClick={() => handleBack()} src={backArrow} alt='' className="transaction-back-arrow" />
                                <div className="transaction-add-edit-back-text">{"Certificate " + (GetCertificateName(Number(type)))}</div>
                            </div> </div>

                            <div className="certificate-col-title transaction-title-container">
                                <div className="icon-margin">
                                    <button className="transaction-icon-container" onClick={() => DownloadOrderRockwoolPdf(certificate!.id!)} type="button">
                                        <img src={pdf} alt="" />
                                        <div className="transaction-icon-text">{t("Rockwool Certificate")}</div>
                                    </button>
                                </div>
                                <div className="icon-margin">
                                    <button className="transaction-icon-container" onClick={() => DownloadOrderPdf(certificate!.id!)} type="button">
                                        <img src={pdf} alt="" />
                                        <div className="transaction-icon-text">{t("Certificate")}</div>
                                    </button>
                                </div>
                                <div className="icon-margin">
                                    <button className="transaction-icon-container" type="button" onClick={() => setEmailModal(!emailModal)}>
                                        <img src={email} alt="" />
                                        <Default>  <div className="transaction-icon-text">{t("Email Pdf")}</div></Default>
                                    </button>
                                </div>
                                <div className="icon-margin">
                                    <button className="transaction-icon-container" type={user.userType == UserType.Administrator ? "submit" : "button"}
                                        onClick={() => user.userType == UserType.Administrator ? {} : setSubmitModal(!submitModal)} disabled={!isValid || isSubmitting}>
                                        <img src={save} alt="" />
                                        <Default>  <div className="transaction-icon-text">{t("Save")}</div></Default>
                                    </button>
                                </div>
                            </div>
                            <div className="certificate">


                                <React.Fragment>
                                    <div>
                                        <div>
                                            <Formik
                                                initialValues={{ email: "" }}
                                                onSubmit={async (values, { setSubmitting }) => {

                                                    SendEmail(values.email)
                                                    setSubmitting(false);
                                                }}

                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string()
                                                        .email(t("Email must be a valid"))
                                                        .required(t("Required")),
                                                })}
                                            >
                                                {props => {
                                                    const {
                                                        values,
                                                        touched,
                                                        errors,
                                                        isSubmitting,
                                                        handleChange,
                                                        handleBlur,
                                                        handleSubmit,
                                                    } = props;

                                                    return (

                                                        <Modal isOpen={emailModal} toggle={() => setEmailModal(!emailModal)} centered className="my-modal-dialog" >
                                                            <ModalBody className="modal-body">
                                                                <div>
                                                                    <Form onSubmit={handleSubmit} className="certificate-full-width">

                                                                        <div className="title-container"><div className="title-nav">
                                                                            <div className="title">{t("Send certificates")}</div>
                                                                        </div><Default><div className="cross" onClick={() => setEmailModal(!emailModal)} /></Default></div>
                                                                        <div className="certificate-form-step">
                                                                            <Label htmlFor="email" className="input-title">{t("Email")}</Label>
                                                                            <Input
                                                                                name="email"
                                                                                type="text"
                                                                                placeholder="Type"
                                                                                value={values.email}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                className={`user-input ${errors.email && (touched.email ? "error" : "")}`}
                                                                            />
                                                                            {errors.email && touched.email && (
                                                                                <div className="input-feedback">{errors.email}</div>
                                                                            )}
                                                                        </div>
                                                                        <div className="add-user-button-container">
                                                                            <MyButton type="reset" typeButton="secondary" onClick={() => setEmailModal(!emailModal)}>
                                                                                {t("Cancel")}
                                                                            </MyButton>
                                                                            <div className="step" />

                                                                            <MyButton type="submit" disabled={isSubmitting}>
                                                                                {t("Submit")}
                                                                            </MyButton>
                                                                        </div>

                                                                    </Form>
                                                                </div>
                                                            </ModalBody>


                                                        </Modal>

                                                    );
                                                }}
                                            </Formik>


                                        </div>
                                        <Row>
                                            <Col lg="6" md="12">
                                                <div className="certificate-block">
                                                    <Row>
                                                        <Col md="12" xs="12" className="certificate-col-title">
                                                            <div>
                                                                {t("For builder / homeowner") + ":"}
                                                            </div>
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Name") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.name}
                                                                name={"contactBilledTo.name"}
                                                                disabled={isDisabled}
                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Address") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.address1}
                                                                name={"contactBilledTo.address1"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Address") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.address2}
                                                                name={"contactBilledTo.address2"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Town") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.city}
                                                                name={"contactBilledTo.city"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Zip Code") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.zipCode}
                                                                name={"contactBilledTo.zipCode"}
                                                                nested={true}
                                                                nestedName="zipCode"
                                                                nestedEntityName="contactBilledTo"
                                                                disabled={isDisabled}
                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Contact Person") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.contactPerson}
                                                                name={"contactBilledTo.contactPerson"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Phone") + ":"}
                                                                touched={touched}
                                                                value={values.contactBilledTo.phone}
                                                                name={"contactBilledTo.phone"}
                                                                nested={true}
                                                                nestedName="phone"
                                                                nestedEntityName="contactBilledTo"
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col lg="6" md="12">
                                                <div className="certificate-block certificate-block-step">
                                                    <Row>
                                                        <Col md="12" xs="12" className="certificate-col-title">
                                                            <div>
                                                                {t("Insulation done in the property") + ":"}
                                                            </div>
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Name") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.name}
                                                                name={"contactDeliverdTo.name"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Address") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.address1}
                                                                name={"contactDeliverdTo.address1"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Address") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.address2}
                                                                name={"contactDeliverdTo.address2"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Town") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.city}
                                                                name={"contactDeliverdTo.city"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Zip Code") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.zipCode}
                                                                name={"contactDeliverdTo.zipCode"}
                                                                nested={true}
                                                                nestedName="zipCode"
                                                                nestedEntityName="contactDeliverdTo"
                                                                disabled={isDisabled}
                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Contact Person") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.contactPerson}
                                                                name={"contactDeliverdTo.contactPerson"}
                                                                disabled={isDisabled}

                                                            />
                                                        </Col>
                                                        <Col md="12" xs="12" className="certificate-col col-border-bottom certificate-col-wrap">
                                                            <CertificateRowContact
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                title={t("Phone") + ":"}
                                                                touched={touched}
                                                                value={values.contactDeliverdTo.phone}
                                                                name={"contactDeliverdTo.phone"}
                                                                nested={true}
                                                                nestedName="phone"
                                                                nestedEntityName="contactDeliverdTo"
                                                                disabled={isDisabled}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                </React.Fragment>
                            </div>
                            <div className="certificate certificate-step">
                                <div className="certificate-block">
                                    <Row>
                                        <Col md="12" xs="12" className="certificate-col-title">
                                            <div>
                                                {t("Details")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <CertificateRow
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        title={t("Number of buildings") + ":"}
                                        touched={touched}
                                        value={values.numberOfBuildings || ""}
                                        name={"numberOfBuildings"}
                                        disabled={isDisabled}
                                    />
                                    <CertificateRow
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        title={t("Isolated area") + ":"}
                                        touched={touched}
                                        value={values.isolatedArea || ""}
                                        name={"isolatedArea"}
                                        disabled={isDisabled}
                                    />
                                    <CertificateRow
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        title={t("Insulation thickness") + ":"}
                                        touched={touched}
                                        value={values.insulationThickness}
                                        name={"insulationThickness"}
                                        disabled={isDisabled}
                                    />
                                    <CertificateRow
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        title={t("Applied consumption") + ":"}
                                        touched={touched}
                                        value={values.appliedConsumption || ""}
                                        name={"appliedConsumption"}
                                        disabled={isDisabled}
                                    />
                                    {type === 0 && <>
                                        <Row className="certificate-col col-border-bottom">

                                            <Label for="natureOfPropertyState" sm={6} className="label-input certificate-input-title">{t("Nature of the property") + ":"}</Label>
                                            <Col sm={6} className="certificate-radio-buttons">
                                                <div className=" col-wrap">
                                                    <Row className="row-icons">
                                                        <Col sm={6} ><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleNatureOfPropertyStateChange("Villa / rk.hus") : null}>
                                                                <img src={natureOfPropertyState === "Villa / rk.hus" ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("Villa / rk.hus")}</div></div></Col>
                                                        <Col sm={6} ><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleNatureOfPropertyStateChange("High Rise") : null}>
                                                                <img src={natureOfPropertyState === "High Rise" ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("High Rise")}</div></div></Col>
                                                    </Row>
                                                    <Row className="row-icons">
                                                        <Col sm={6} ><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleNatureOfPropertyStateChange("Farmhouse") : null}>
                                                                <img src={natureOfPropertyState === "Farmhouse" ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("Farmhouse")}</div></div></Col>
                                                        <Col sm={6} ><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleNatureOfPropertyStateChange("Stable or similiar") : null}>
                                                                <img src={natureOfPropertyState === "Stable or similiar" ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("Stable or similiar")}</div></div></Col>
                                                    </Row>

                                                </div>
                                                <div className="certificate-other">
                                                    <Input type="text"
                                                        name="natureOfPropertyState"
                                                        className="certificate-input"
                                                        id="natureOfPropertyState"
                                                        autoComplete="off"
                                                        autoSave="off"
                                                        placeholder={t("Other")}
                                                        onChange={(event) => HandleNatureOfPropertyStateChange(event.target.value)}
                                                        value={natureOfPropertyState}
                                                        disabled={isDisabled}
                                                    />
                                                </div>

                                            </Col>
                                        </Row>
                                        <Row className="certificate-col col-border-bottom">

                                            <Label for="ownershipState" sm={6} className="label-input certificate-input-title">{t("Ownership" + ":")}</Label>
                                            <Col sm={6} className="certificate-radio-buttons">
                                                <div className=" col-wrap">
                                                    <Row className="row-icons">
                                                        <Col sm={6} ><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleOwnerStateChange(t("Private")) : null}>
                                                                <img src={ownershipState === t("Private") ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("Private")}</div></div></Col>
                                                        <Col sm={6} ><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleOwnerStateChange(t("Social / general")) : null}>
                                                                <img src={ownershipState === t("Social / general") ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("Social / general")}</div></div></Col>
                                                    </Row>
                                                    <Row className="row-icons">
                                                        <Col><div className="col-flex">
                                                            <div onClick={() => !isDisabled ? HandleOwnerStateChange(t("State / municipality")) : null}>
                                                                <img src={ownershipState === t("State / municipality") ? radioButtonActive : radioButton} alt="" /></div>
                                                            <div className="certificate-icon-step">{t("State / municipality")}</div></div></Col>
                                                    </Row>

                                                </div>
                                                <div className="certificate-other">
                                                    <Input type="text"
                                                        name="ownershipState"
                                                        className="certificate-input"
                                                        id="ownershipState"
                                                        autoComplete="off"
                                                        autoSave="off"
                                                        placeholder={t("Other")}
                                                        value={ownershipState}
                                                        onChange={(event) => HandleOwnerStateChange(event.target.value)}
                                                        disabled={isDisabled}
                                                    />
                                                </div>

                                            </Col>
                                        </Row>
                                    </>}
                                    {type === 1 && <>


                                        <Row className="certificate-col col-border-bottom">

                                            <Col sm={12} ><div className="col-flex">
                                                <div onClick={() => !isDisabled ? setWalkwayControlAndVentilationState(!walkwayControlAndVentilationState) : null} >
                                                    <img src={walkwayControlAndVentilationState === true ? selected : notSelected} alt="" /></div>
                                                <div className="certificate-icon-step">{t("Walkway control and ventilation")}</div></div></Col>

                                        </Row>
                                        <Row className="certificate-col col-border-bottom">
                                            <Col sm={12} ><div className="col-flex">
                                                <div onClick={() => !isDisabled ? setThicknessMeasurementDone(!thicknessMeasurementDone) : null}>
                                                    <img src={thicknessMeasurementDone === true ? selected : notSelected} alt="" /></div>
                                                <div className="certificate-icon-step">{t("Thickness measurement done")}</div></div></Col>
                                        </Row>

                                        <CertificateRow
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            title={t("Number") + ":"}
                                            touched={touched}
                                            value={(values as GranuleProCertificateDTO).number || ""}
                                            name={"number"}
                                            disabled={isDisabled}
                                        />
                                        <CertificateRow
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            title={t("Average Thickness") + ":"}
                                            touched={touched}
                                            value={(values as GranuleProCertificateDTO).averageThickness || ""}
                                            name={"averageThickness"}
                                            disabled={isDisabled}
                                        />
                                        <CertificateRow
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            title={t("Existing Insulation Thickness") + ":"}
                                            touched={touched}
                                            value={(values as GranuleProCertificateDTO).existingInsulationThickness || ""}
                                            name={"existingInsulationThickness"}
                                            disabled={isDisabled}
                                        />
                                    </>}
                                    {type === 0 &&
                                        <Row className="certificate-col col-border-bottom">

                                            <Label sm={6} for="terms" className="label-input certificate-input-title">{t("Terms") + ":"}</Label>
                                            <Col sm={6}>
                                                <Input type="textarea"
                                                    name="terms"
                                                    value={(values as CavityWallCertificateDTO).terms || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="certificate-input certificate-textarea"
                                                    disabled={isDisabled}
                                                />
                                                {errors.comments && touched.comments && (
                                                    <div className="input-feedback">{errors.comments}</div>
                                                )}
                                            </Col>
                                        </Row>}
                                    <CertificateRow
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        title={t("Insulation and control performed by") + ":"}
                                        touched={touched}
                                        value={values.insulationAndControlPerformedBy}
                                        name={"insulationAndControlPerformedBy"}
                                        disabled={isDisabled}
                                    />
                                    <Row className="certificate-col col-border-bottom">

                                        <Label sm={6} for="notes" className="label-input certificate-input-title">{t("Notes") + ":"}</Label>
                                        <Col sm={6}>
                                            <Input type="textarea"
                                                name="notes"
                                                value={values.notes || ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="certificate-input certificate-textarea"
                                                disabled={isDisabled}
                                            />
                                            {errors.notes && touched.notes && (
                                                <div className="input-feedback">{errors.notes}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="certificate-col col-border-bottom">

                                        <Label sm={6} for="workCompletedDate" className="label-input certificate-input-title">{t("Certificate completed date") + ":"}</Label>
                                        <Col sm={6}>
                                            <div className="date-picker-wrap">
                                                <DatePicker
                                                    name="workCompletedDate"
                                                    autoComplete="off"
                                                    selected={certificateWorkCompletedDateState as Date}
                                                    onChange={setCertificateWorkCompletedDate}
                                                    locale="da"
                                                    dateFormat="dd-MM-yyyy"
                                                    className="form-control input-search-date input-search user-input-search"
                                                    disabled={isDisabled}

                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="certificate-col col-border-bottom">

                                        <Label sm={6} for="certificateIssuedDate" className="label-input certificate-input-title">{t("Certificate issued date") + ":"}</Label>
                                        <Col sm={6}>
                                            <div className="date-picker-wrap">
                                                <DatePicker
                                                    name="certificateIssuedDate"
                                                    autoComplete="off"
                                                    selected={certificateIssuedDateState as Date}
                                                    onChange={setCertificateIssuedDate}
                                                    locale="da"
                                                    dateFormat="dd-MM-yyyy"
                                                    className="form-control input-search-date input-search user-input-search"
                                                    disabled={isDisabled}

                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CertificateRow
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        title={t("Identity number") + ":"}
                                        touched={touched}
                                        value={values.identityNumber || ""}
                                        disabled={isDisabled}
                                        name={"identityNumber"}
                                    />
                                    <Row className="certificate-col col-border-bottom">

                                        <Label sm={6} for="comments" className="label-input certificate-input-title">{t("Comments on Rockwool A/S") + ":"}</Label>
                                        <Col sm={6}>
                                            <Input type="textarea"
                                                name="comments"
                                                value={values.comments || ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="certificate-input certificate-textarea"
                                                disabled={isDisabled}
                                            />
                                            {errors.comments && touched.comments && (
                                                <div className="input-feedback">{errors.comments}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="certificate-col col-border-bottom">

                                        <Label sm={6} for="control" className="label-input certificate-input-title">{t("Control") + ":"}</Label>
                                        <Col sm={6}>
                                            <Input type="textarea"
                                                name="control"
                                                value={values.control || ""}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className="certificate-input certificate-textarea"
                                                disabled={isDisabled}
                                            />
                                            {errors.control && touched.control && (
                                                <div className="input-feedback">{errors.control}</div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div></>}</Form>)
            }}



        </Formik>  </div >
    )
};

export default connect()(EditCertificates);


