import React from 'react';
import { TransactionTableDTO, TransactionTable } from '../../../interfaces/transaction';
import TransactionCollapse from '../TransactionCollapse/TransactionCollapse';
import { TFunction } from 'i18next';

interface MyTableRowProps {
    user: TransactionTable,
    tab: number,
    handleArchive: (id: number, isActive: boolean) => void,
    setSelectedAddEditTransaction: (type: string, transactionTableDTO?: TransactionTableDTO) => void,
    t:TFunction
}

const UserTableRowTablet = (props: MyTableRowProps) => {

    return (
        <TransactionCollapse transaction={props.user} openModalWithTransaction={props.setSelectedAddEditTransaction} handleArchive={props.handleArchive} tab={props.tab} t ={props.t} />
    )
}

export default UserTableRowTablet;