/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { GetCertificateName } from '../../../enums/certificateType';
import { Mobile, Tablet } from '../../../ResponsiveSizes';
import { CertificateTable, CertificateTableDTO } from '../../../interfaces/certificate';
import { TFunction } from 'i18next';

interface MyTableRowProps {
    certificate: CertificateTable,
    setSelectedAddEditTransaction: (type: string, certificate: CertificateTableDTO) => void
    t: TFunction

}

const chevronUp = require("../../../assets/Chevron-up.svg") as string;
const chevronDown = require("../../../assets/Chevron-down.svg") as string;

const CertificateCollapse = (props: MyTableRowProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {

        setIsOpen(!isOpen)
    };

    const { certificate, t } = props
    const editTransaction = () => {
        props.setSelectedAddEditTransaction("edit", certificate!)
    }


    return (
        <>
            <Mobile>
                <div color="primary" onClick={toggle} className={isOpen ?
                    " collapse-active collapse-header" :
                    "collapse-header collapse-header-border"}>

                    <div>
                        <div className="collapse-header-title">{certificate.blowerName}</div>
                        <div className="collapse-header-type">{GetCertificateName(certificate.certificateType)}</div>
                    </div>
                    <div> <img src={isOpen ? chevronUp : chevronDown} alt="" /></div>
                </div>
            </Mobile>
            <Tablet>
                <tr onClick={toggle}>
                    <td>{certificate.id}</td>
                    <td>{GetCertificateName(certificate.certificateType)}</td>
                    <td>{certificate.createdDate}</td>
                    <td>{certificate.blowerName}</td>
                    <td > <img src={isOpen ? chevronUp : chevronDown} alt="" /></td>
                </tr>
            </Tablet>
            <Mobile>
                <Collapse isOpen={isOpen}>
                    <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                        <div className="collapse-row">
                            <div>{t("Id")}</div>
                            <div>{certificate.id}</div>
                        </div>

                        <div className="collapse-row">
                            <div>{t("Date")}</div>
                            <div>{certificate.createdDate}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Address")}</div>
                            <div>{certificate.contactDeliverdToAddress}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Town")}</div>
                            <div>{certificate.contactDeliverdToCity}</div>
                        </div>
                        <div className="collapse-row">
                            <div>{t("Zip code")}</div>
                            <div>{certificate.contactDeliverdToZipCode}</div>
                        </div>
                        <div className="collapse-row-end">
                            <div className="collapse-buttons">
                                <div />
                                <div onClick={editTransaction} className="collapse-text">{t("EDIT")}</div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </Mobile>
            <Tablet>
                {isOpen && <tr className="collaplse-tr">
                    <td colSpan={4}>
                        <Collapse isOpen={isOpen}>
                            <div className={isOpen ? "collapse-content collapse-active-border" : "collapse-content"}>
                                <div className="collapse-row">
                                    <div>{t("Address")}</div>
                                    <div>{certificate.contactDeliverdToAddress}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Town")}</div>
                                    <div>{certificate.contactDeliverdToCity}</div>
                                </div>
                                <div className="collapse-row">
                                    <div>{t("Zip code")}</div>
                                    <div>{certificate.contactDeliverdToZipCode}</div>
                                </div>
                                <div className="collapse-row-end">
                                    <div className="collapse-buttons">
                                        <div />
                                        <div onClick={editTransaction} className="collapse-text">{t("EDIT")}</div>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </td>
                </tr>
                }
            </Tablet>

        </>
    )
}

export default CertificateCollapse;