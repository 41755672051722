import React from 'react';
import { Col, Input, Row, Label } from 'reactstrap';

interface certificateRowProps {
    value?: any,
    handleChange?: any,
    handleBlur?: any,
    errors?: any,
    touched?: any,
    title: string,
    name?: string
    disabled?: boolean
}


const CertificateRow = (props: certificateRowProps) => {

    const { handleBlur, handleChange, errors, touched, title, value, name, disabled } = props
    return (


        <Row className="certificate-col col-border-bottom">
            <Label for={name} sm={6} className="label-input certificate-input-title">{title}</Label>
            <Col sm={6} className="certificate-row-flex">
                <div className="certificate-row-flex-nested">
                    <Input
                        name={name}
                        type={name === "password" ? "password" : "text"}
                        value={value}
                        onChange={handleChange}
                        disabled={disabled}
                        onBlur={handleBlur}
                        className={`certificate-input ${errors![name!] && (touched![name!] ? "error" : "")}`}
                    />
                    <div className="col-measure">{name === "isolatedArea" ? "m²" : name === "insulationThickness" ? "mm" : null}</div>
                </div>
                {errors![name!] && touched![name!] && (
                    <div className="input-feedback-nested input-feedback-contact">{errors![name!]}</div>
                )}
            </Col>

        </Row>)
}

export default (CertificateRow)

