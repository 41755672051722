import axios from "axios"
import { Credentials, User, UserTableTuple } from '../interfaces/user'
import { AddEditUser } from "../interfaces/user"
import { SortDTO, SearchDTO } from "../interfaces/general"

export const login = (credentials: Credentials) =>
    axios.post<User | null>('/users/authenticate', credentials)

export const getUserFromCookie = () => 
axios.get<User | null>('/users/getuserfromcookie')


export const recovery = (email: string) =>
    axios.post<boolean>(`/users/recovery?email=${email}`)

export const existRecoveryCode = (code: string) =>
    axios.get<boolean>(`/users/existrecoverycode?code=${code}`)

export const changePassword = (code: string, password: string) =>
    axios.put<boolean>(`/users/changepassword?code=${code}`, { password })

export const createUser = (user: AddEditUser) =>
    axios.post<boolean | null>(`/users/createuser`, user)


export const editUser = (id: number, userUser: AddEditUser) =>
    axios.put<boolean | null>(`/users/edituser/${id}`, userUser)


export const getFilteredUsersForTableByIndex =
    (start: number, amount: number, sortDTO: SortDTO | undefined, searchTerm: string, searchDTOs: SearchDTO[], startDate: Date | string, endDate: Date | string) =>
        axios.get<UserTableTuple>(`/users/getfilteredusersfortablebyindex?
        &start=${start}&amount=${amount}&sortDTO=${JSON.stringify(sortDTO)}&searchterm=${searchTerm}&searchDTOs=${JSON.stringify(searchDTOs)}&startDate=${startDate ? JSON.stringify(startDate) : ""}&endDate=${endDate ? JSON.stringify(endDate) : ""}`)
