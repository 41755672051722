import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      de: {
        translations: {
          //General
          "Search": "Søg",
          "Start date": "Start dato",
          "End date": "Slut dato",
          "Id": "Id",
          "Date": "Dato",
          "Items per page": "Rækker per side",
          "EDIT": "REDIGER",
          "Save": "Gem",
          "Email": "E-mail",
          "Cancel": "Annuller",
          "Submit": "Submit",
          "Name": "Navn",
          "UserType": "Bruger Type",
          "Address": "Adresse",
          "Role": "Rolle",
          "Created": "Oprettet",
          "Offer": "Tilbud",
          "Executed": "Eksekveret",
          "Town": "By",
          "Zip Code": "Postnummer",
          "Contact Person": "Kontaktperson",
          "Phone": "Telefon",
          "Details": "Påkrævet",
          "Required": "Påkrævet",
          "Password is too short - should be 8 chars minimum": "Adgangskoden er for kort - den skal være på minimum 8 tegn",
          "Password must contain a number": "Adgangskoden skal indeholde minimum et tal",
          "Password": "Adgangskode",
          "Forgot Password": "Glemt Adgangskode",
          "DELETE": "SLET",
          "Granule Multi": "Granulat Multi",
          "Granule Pro": "Granulat Pro",
          "Preview template": "Forhåndsvisning",
          "Must be a number": "Det skal være et tal",
          "Value is too large": "Værdien er for stor",
          "Identity number": "Id nummer",
          "Type": "Type",
          "Blower": "Indblæser",
          "Admin": "Admin",
          "Go back": "Retur",
          "Pdf": "Pdf",
          "Worksheet": "Regneark",


          //Certificates
          "Certificates": "Attester",
          "Zip code": "Postnummer",
          "Certificate type": "Attest type",
          "Rockwool Certificate": "Rockwool Attest",
          "Certificate": "Attest",
          "Email Pdf": "Email Pdf",
          "Send certificates": "Send Attest",
          "For builder / homeowner": "til Bygherre / boligejer",
          "Insulation done in the property": "Isolering udført i ejendommen",
          "Number of buildings": "Antal bygninger",
          "Isolated area": "Isoleret område",
          "Insulation thickness": "Isoleringstykkelse",
          "Applied consumption": "Anvendt forbrug",
          "Nature of the property": "Ejendomstype",
          "Villa / rk.hus": "Villa / Rækkehus",
          "High Rise": "Høj Stigning",
          "Farmhouse": "Landejendom",
          "Stable or similiar": "Stald eller lignende",
          "Other": "Andet",
          "Ownership": "Ejerskab",
          "State / municipality": "Region / Kommune",
          "Private": "Privat",
          "Social / general": "Social / Generel",
          "Walkway control and ventilation": "Gangbane kontrol og ventilation",
          "Thickness measurement done": "Tykkelsesmåling udført",
          "Number": "Antal",
          "Average Thickness": "Gennemsnitlig tykkelse",
          "Existing Insulation Thickness": "Eksisterende isoleringstykkelse",
          "Terms": "Vilkår",
          "Insulation and control performed by": "Isolering og kontrol udført af",
          "Notes": "Bemærkninger",
          "Certificate completed date": "Dato for afsluttet attest",
          "Certificate issued date": "Dato for udstedelse af attest",
          "Control": "Control",
          "Comments on Rockwool A/S": "Comments on Rockwool A/S",

          //Login
          "Welcome to": "Velkommen til",
          "Blower Certification Tool": "Indblæsernet",
          "Login": "Login",
          "Incorrect email or password": "Forkert email eller adgangskode",
          "User is not active": "Brugeren er ikke aktiv",

          //Products
          "Products": "Produkter",
          "Product was edited successfully": "Produktet er redigeret",
          "Product was added successfully": "Produktet er blevet tilføjet",
          "Product was deleted successfully": "Produktet er blevet slettet",
          "Add Product": "Tilføj Produkt",
          "Edit Product": "Rediger Produkt",
          "Delete Product": "Slet Produkt",
          "Product Name": "Produktnavn",
          "Do you want to delete Product": "Vil du slette produktet",

          //Profile
          "Profile": "Profil",
          "User detail": "Bruger Oplysninger",
          "Username": "Brugernavn",
          "Company detail": "Virksomhedsoplysninger",
          "Blower name": "Indblæsernavn",
          "Company name": "Virksomhedsnavn",
          "Website": "Hjemmeside",
          "Company email": "Virksomheds email",
          "CVR": "CVR",
          "Top text - estimate": "Overskrift - estimering",
          "shown in extension of top text by": "Vist i forlængelse af overskriften",
          "estimate": "Estimering",
          "Letterhead image": "Brevhovedbillede",
          "Image contains company information": "Billedet indeholder virksomhedsoplysninger",
          "Hulmur stem text": "Hulmur stam tekst",
          "Loft stem text": "Loft stam tekst",
          "Floor separation with clay deposit text": "Gulvseparation med leraflejring tekst",
          "End text - order": "Slut tekst - ordrebekræftelse",
          "appears as bottom text at": "vises som bundtekst ved ordrebekræftelse",
          "order confirmation": "Ordrebekræftelse",
          "Business conditions": "Forretningsbetingelser",
          "appears on Page": "Vises på side",
          "Logo graphic for Granule Multi certificate": "Logo til Granulat Multi attest",
          "Logo graphic for Granule PRO certificate": "Logo til Granulat PRO attest",
          "Profile was edited successfully": "Profilen er redigeret",

          //Recovery
          "Recovery": "Genopretning",
          "Passwords don't match": "Adgangskoderne er ikke ens",
          "New Password": "Ny Adgangskode",
          "Confirm New Password": "Bekræft adgangskoden",
          "Recovery successfully sent": "Genopretningen er sendt",
          "Password has been successufully updated": "Adgangskoden er ændret",

          //Users
          "Users": "Bruger",
          "Active": "Aktiv",
          "Add User": "Tilføj ny Bruger",
          "User already exists with this username": "Brugeren findes allerede",
          "User already exists with this email": "Brugeren og mail findes allerede",
          "User was edited successfully": "Bruger er nu blevet redigeret",
          "User was added successfully": "Brugeren er nu oprettet",

          //Transactions
          "Do you want to": "Vil du",
          "recovery": "genoprette",
          "archive": "arkivere",
          "Create transaction": "Oprette en transaktion",
          "Customer": "Kunde",
          "State": "Status",
          "Transactions": "Transaktioner",
          "Transaction details": "Transaktions oplysninger",
          "Transaction name": "Transaktions navn",
          "Change transaction state to": "Ændre transaktions status til",
          "Company Address": "Virksomhedens adresse",
          "Att": "Att",
          "Copy the data from the company address": "Kopier data fra Virksomheds adressen",
          "Delivery address": "Leveringsadresse",
          "Letterhead": "Brevhoved",
          "shown in extension of top text from master data": "Vist i forlængelse af overskriften fra stamdata",
          "select product to add transaction line": "Vælg produkt for at tilføje transaktionslinje",
          "Add Transaction Line": "Tilføj transaktionslinje",
          "End text": "Sluttekst",
          "shown in extension of final text from master data": "Vist i forlængelse af den endelige tekst fra stamdata",
          "Note for worksheet": "Bemærkning til regnearket",
          "Note for Rockwool": "Bemærkning til Rockwool",
          "Job type": "Jobtype",
          "Description": "Beskrivelse",
          "Prerequisites": "Forudsætninger",
          "Perfomance": "Perfomance",
          "Area": "Område",
          "Thickness": "Tykkelse",
          "Packages Consumption": "Forbrug af pakker",
          "Sub total": "Total",
          "VAT": "Moms",
          "Total": "Total",
          "Price per": "Price per",
          "Kg per": "Kg per",
          "Do you want to delete the transaction line with product": "Vil du slette transaktionslinjen med produktet",
          "Edit Transaction Line": "Rediger transaktionslinjen",

          "ZIP Code": "ZIP Code",
          "Add": "Tilføje",
          "Transaction Line": "Transaktionslinje",
          "Archived": "Arkiveret",
          "Zip": "Zip",
          "Transaction lines dont exist": "Transaktionslinjer findes ikke",
          "Email must be a valid": "Email skal være gyldig",
          "You will not be able to change any values after you save the order as fulfilled. Please make sure all details are correct":
            "Du kan ikke ændre nogen værdier, når du har gemt ordren, som den er opfyldt. Sørg for, at alle detaljer er korrekte",
          "Fullfiled": "Udført",
          "Order": "Ordre",
          "Quote": "Tilbud",
          "Inactive": "Inaktiv",
          "Product active": "Produkt aktivt",
          "Edit user": "Rediger bruger",
          "User active": "Bruger aktiv",
          "Certificate was edited successfully": "Certifikatet blev redigeret med succes",
          "Certificate was sent successfully": "Certifikatet blev sendt",
          "Transaction was edited successfully": "Transaktionen blev redigeret med succes",
          "Recovery successfully sent": "Gendannelse sendt",
          "Password has been successufully updated": "Adgangskoden er opdateret",
          "Tansaction was archived successfully": "Transaktion blev opnået med succes",
          "Tansaction was recovered successfully": "Transaktionen blev inddrevet med succes",
          "Transaction was archived successfully": "Transaktion blev opnået med succes",
          "User was edited successfully": "Brugeren blev redigeret med succes",
          "User was added successfully": "Brugeren blev tilføjet med succes",
          "Transaction was added successfully": "Transaktionen blev tilføjet med succes",
          "Profile was edited successfully": "Profilen blev redigeret med succes",
          "Settings were edited successfully": "Indstillinger blev redigeret med succes",
          "Settings": "Indstillinger",
          "Status": "Status"




        }
      }
    },
    lng: 'de',
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
