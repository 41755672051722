import axios from "axios"
import { CertificateDTO, CertificateTuple } from "../interfaces/certificate"
import { SortDTO, SearchDTO } from "../interfaces/general"


export const getCertificateById = (id: number) =>
    axios.get<CertificateDTO>(`/certificates/getcertificatebyid/${id}`)

    export const editCertificateById = (id: number, certificateDTO: CertificateDTO) =>
    axios.put<boolean>(`/certificates/editcertificatebyid/${id}`, certificateDTO)

    export const getFilteredCertificatesForTableByIndex =
    (start: number, amount: number, sortDTO: SortDTO | undefined, searchTerm: string, searchDTOs: SearchDTO[], startDate: Date | string, endDate: Date | string) =>
        axios.get<CertificateTuple>(`/certificates/getfilteredcertificatesbyindex?
        &start=${start}&amount=${amount}&sortDTO=${JSON.stringify(sortDTO)}&searchterm=${searchTerm}&searchDTOs=${JSON.stringify(searchDTOs)}&
startDate=${startDate ? JSON.stringify(startDate) : ""}&endDate=${endDate ? JSON.stringify(endDate) : ""}`)