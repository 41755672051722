/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from 'react';
import { Modal, ModalBody, Container, Row, Col } from 'reactstrap';
import MyButton from '../../MyButton/MyButton'
import { Formik } from "formik";
import * as Yup from "yup";
import { ProductDTO } from "../../../interfaces/product"
import '../../../../node_modules/text-security/dist/text-security.css'
import { Default, Mobile } from '../../../ResponsiveSizes';
import { RouteComponentProps } from 'react-router';
import { GetCertificateName } from '../../../enums/certificateType';
import { TFunction } from 'i18next';



// eslint-disable-next-line @typescript-eslint/no-var-requires
const backArrow = require("../../../assets/Keyboard_backspace.svg") as string;
interface AddEditDeleteProductModalProps {
    modal: any,
    toggle: any,
    type: string,
    selectedProduct: ProductDTO | undefined,
    handleEdit: (values: ProductDTO) => void
    handleFilter: () => void
    handleAdd: (values: ProductDTO) => void
    t: TFunction,
    productActive: boolean,
    setProductActive: (value: boolean) => void

}

const selected = require("../../../assets/Selected.svg") as string;
const notSelected = require("../../../assets/NotSelected.svg") as string;

type unionAddEditDeleteProductModalProps = AddEditDeleteProductModalProps &
    // eslint-disable-next-line @typescript-eslint/ban-types
    RouteComponentProps<{}>;


const AddEditDeleteProductModal = (props: unionAddEditDeleteProductModalProps | any) => {

    const initialValues: ProductDTO = {
        id: undefined,
        kgPerBag: undefined,
        kgPerCube: undefined,
        certificateType: 0,
        isActive: false
    };


    const { modal, toggle, type, selectedProduct, handleEdit, handleAdd, handleDelete, t, productActive, setProductActive } = props

    const [certificateType, setCertificateType] = useState(0)


    const handleChangeUserType = (event: any) => {
        setCertificateType(Number(event.target.value))
    }

    Yup.addMethod(Yup.number, 'delocalize', function () {
        return this.transform(function (currentValue, originalValue) {
            return parseFloat(originalValue.replace(',', '.'))
        })
    })

    const content = (<Formik
        initialValues={(type === "edit" || type === "delete") ? (selectedProduct || initialValues) : initialValues}
        onSubmit={async (values, { setSubmitting }) => {

            values.certificateType = certificateType
            if (type === "add") {
                handleAdd(values)
            } else if (type === "edit") {
                if (values) {
                    handleEdit(values)
                }
            }
            else if (type === "delete") {
                handleDelete(values)
            }
            toggle(type)
            setSubmitting(false);

        }}

        validationSchema={
            Yup.object().shape<ProductDTO | any>({
                kgPerBag: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
                kgPerCube: Yup.string().required(t("Required")).matches(/^\d*\.?\d*$/, t('Must be a number')).max(20, t("Value is too large")),
                certificateType: Yup.number(),
                isActive: Yup.boolean()

            })}
        isInitialValid={type === "add" ? false : true}

    >

        {props => {
            const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid
            } = props;

            return (
                <React.Fragment>
                    <div className="title-container"><div className="title-nav"><Mobile><img onClick={() => toggle(type)} src={backArrow} alt='' /></Mobile>
                        <div className="title">{type === "add" ? t("Add Product") : type === "edit" ?
                            t("Edit Product") : type === "delete" ?
                                `${t("Do you want to delete Product")} "${GetCertificateName(selectedProduct!.certificateType)}"?` : ""}</div>
                    </div><Default><div className="cross" onClick={() => toggle(type)} /></Default></div>
                    <div className="form-step">
                        {type !== "delete" && (<Row className="form-step">
                            <Col md="6" xs="6" className="checkbox-wrapper round">

                                <input
                                    id="checkbox1"
                                    name="type1"
                                    type="checkbox"
                                    checked={certificateType === 0}
                                    onChange={handleChangeUserType}
                                    value={0}
                                />
                                <label htmlFor="checkbox1"></label>
                                <div>{t("Granule Multi")}
                                    {/* <Tooltip placement="right" isOpen={tooltipOpenForCertificate1} target="certificate1" toggle={() => setTooltipOpenForCertificate1(!tooltipOpenForCertificate1)}>
                                        {t("Preview template")}
                                    </Tooltip> */}
                                </div>

                            </Col>
                            <Col md="6" xs="6" className="checkbox-wrapper round">
                                <input
                                    id="checkbox2"
                                    name="type2"
                                    type="checkbox"
                                    checked={certificateType === 1}
                                    value={1}
                                    onChange={handleChangeUserType}
                                />
                                <label htmlFor="checkbox2"></label>
                                <div>{t("Granule Pro")}
                                    {/* <Tooltip placement="right" isOpen={tooltipOpenForCertificate2} target="certificate2" toggle={() => setTooltipOpenForCertificate2(!tooltipOpenForCertificate2)}>
                                        {t("Preview template")}
                                    </Tooltip> */}
                                </div>
                            </Col>
                        </Row>)}
                        <form onSubmit={handleSubmit} className="my-form">
                            {type !== "delete" && (
                                <React.Fragment>
                                    <Row>
                                        <Col md="12" xs="12">
                                            <label htmlFor="kgPerBag" className="input-title">Kg/bag</label>
                                            <input
                                                name="kgPerBag"
                                                type="text"
                                                placeholder="Type"
                                                value={values.kgPerBag}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.kgPerBag && (touched.kgPerBag ? "error" : "")}`}
                                            />
                                            {errors.kgPerBag && touched.kgPerBag && (
                                                <div className="user-input input-feedback">{errors.kgPerBag}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" xs="12">

                                            <label htmlFor="kgPerCube" className="input-title">{"Kg/m"}&#x2083;</label>
                                            <input
                                                name="kgPerCube"
                                                type="text"
                                                placeholder="Type"
                                                value={values.kgPerCube}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={`user-input ${errors.kgPerCube && (touched.kgPerCube ? "error" : "")}`}
                                            />
                                            {errors.kgPerCube && touched.kgPerCube && (
                                                <div className="input-feedback">{errors.kgPerCube}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" xs="12">
                                            {type === "edit" ?
                                                <div className="user-active">
                                                    <div className="user-active-icon" onClick={() => setProductActive(!productActive)}><img src={productActive ? selected : notSelected} alt='' /></div>
                                                    <div className="user-active-text">{t("Product active")}</div>
                                                </div> : null}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}

                            <div className="add-user-button-container">
                                <MyButton type="reset" typeButton="secondary" onClick={toggle}>
                                    {t("Cancel")}
                                </MyButton>

                                <div className="step" />

                                <MyButton type="submit" disabled={!isValid || isSubmitting}>
                                    {type === "add" ? t("Add Product") : type === "edit" ? t("Edit Product") : type === "delete" ? t("Delete Product") : ""}
                                </MyButton>

                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }}
    </Formik>)

    return (
        <div>
            <Container>
                <Default>
                    <Modal isOpen={modal} toggle={() => toggle(type)} centered className="my-modal-dialog" >
                        <ModalBody className="modal-body">
                            {content}
                        </ModalBody>
                    </Modal>
                </Default>
                <Mobile>
                    {modal && content}
                </Mobile>
            </Container>
        </div>

    );
}

export default AddEditDeleteProductModal;