import React from 'react';
import { TransactionLineDTO } from '../../../../interfaces/transactionLine'

interface DeleteProps {
    value?: boolean,
    handleClick: (type: string, transactionLineDTO: TransactionLineDTO) => void
    transactionLineDTO: TransactionLineDTO
}

const Delete = (props: DeleteProps) => {

    return (
        <div className="delete-icon" onClick={() => props.handleClick("delete", props.transactionLineDTO)} />
    )
}


export default Delete;