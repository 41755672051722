import React from 'react';
import { ProductDTO } from '../../../interfaces/product'
import { TransactionLineDTO } from '../../../interfaces/transactionLine';
import NumberFormat from 'react-number-format';
import { GetCertificateName } from '../../../enums/certificateType';
import CertificateIcon from './TransactionLineCertificateIcon';
import Edit from './Edit/Edit';
import Delete from './Delete/Delete';
import { TransactionTypes } from '../../../enums/transactionState';
import { TFunction } from 'i18next';

interface TransactionLinesBlockProps {
    transactionLines: TransactionLineDTO[],
    products?: ProductDTO[] | undefined,
    history: any,
    handleSelectTransactionLine: (term: string, transactionLineDTO: TransactionLineDTO) => void,
    transactionStatus: TransactionTypes,
    t: TFunction
}

const TransactionLinesBlock = (props: TransactionLinesBlockProps) => {

    const { transactionLines, products, history, handleSelectTransactionLine, transactionStatus, t } = props



    const total = transactionLines!.map(t => t!.total).reduce((a, b) => a! + b!)
    const vat = total! * 0.25
    const totalWithVat = total! + vat!

    const existProducts = products || []
    return (
        <div>
            <div className="certificate-col-title transaction-lines-title">
                <div className="transaction-line-col1">{t("Job type")}</div>
                <div className="transaction-line-col2">{t("Description")}</div>
                <div className="transaction-line-col3">{t("Prerequisites")}</div>
                <div className="transaction-line-col4">{t("Perfomance")}</div>
                <div className="transaction-line-col5" />
                <div className="transaction-line-col6" />
            </div>
            <div>
                {transactionLines!.map(tl =>
                    <div key={tl.id} className="transaction-line">
                        <div className="transaction-line-col1">  {GetCertificateName(existProducts.filter(m => m.id === tl.productId)[0] && existProducts.filter(m => m.id === tl.productId)[0].certificateType) || "Andet"}</div>
                        <div className="transaction-line-col2" dangerouslySetInnerHTML={{ __html: tl.description }} />
                        <div className="transaction-line-col3 transaction-line-prerequisites">
                            {tl.productId !== null && <><div className="transaction-line-col-content"><div>{t("Job type")}</div><div>{tl.insulationArea}m&#178;</div></div>
                                <div className="transaction-line-col-content"><div>{t("Thickness")}</div><div>{tl.insulationThickness}mm</div></div>
                                <div className="transaction-line-col-content"><div>{t("Packages Consumption")}</div><div>{tl.quantity}</div></div></>}
                        </div>
                        <div className={transactionStatus !== 2 ? "transaction-line-col4" : "transaction-line-col4-full"}><div className="transaction-line-flex">
                            <NumberFormat value={tl.total} displayType={'text'} thousandSeparator={true} /><span>&nbsp;kr.</span></div>
                        </div>
                        <div className="transaction-line-col5">
                            <div>{tl!.certificateId && <CertificateIcon certificateId={tl!.certificateId!} history={history} />}</div> </div>
                        {transactionStatus !== 2 && <div className="transaction-line-col6"><div><Edit handleClick={handleSelectTransactionLine} transactionLineDTO={tl} />
                        </div> <div><Delete handleClick={handleSelectTransactionLine} transactionLineDTO={tl} /></div> </div>}
                    </div>
                )}
                <div className="certificate-col-title transaction-lines-statitistic">
                    <div className="transaction-line-col1" />
                    <div className="transaction-line-col2" />
                    <div className="transaction-line-col3">{t("Sub total")}</div>
                    <div className="transaction-line-col4"><NumberFormat value={total} displayType={'text'} thousandSeparator={true} /> kr.</div>
                    <div className="transaction-line-col5"> </div>
                    <div className="transaction-line-col6" />
                </div>
                <div className="certificate-col-title transaction-lines-statitistic">
                    <div className="transaction-line-col1" />
                    <div className="transaction-line-col2" />
                    <div className="transaction-line-col3">{t("VAT")}</div>
                    <div className="transaction-line-col4"><NumberFormat value={vat} displayType={'text'} thousandSeparator={true} /> kr.</div>
                    <div className="transaction-line-col5"> </div>
                    <div className="transaction-line-col6" />
                </div>
                <div className="certificate-col-title transaction-lines-statitistic-end">
                    <div className="transaction-line-col1" />
                    <div className="transaction-line-col2" />
                    <div className="transaction-line-col3">{t("Total")}</div>
                    <div className="transaction-line-col4"><NumberFormat value={totalWithVat} displayType={'text'} thousandSeparator={true} /> kr.</div>
                    <div className="transaction-line-col5"> </div>
                    <div className="transaction-line-col6" />

                </div>
            </div>
        </div>
    )
}


export default TransactionLinesBlock;