import React, { useState } from 'react';
import { TransactionTable } from '../../../interfaces/transaction';
import { GetTransactionType } from '../../../enums/transactionState';

interface MyTableRowProps {
    transaction: TransactionTable,
}

const TransactionTableRow = (props: MyTableRowProps) => {
    const [hovered, setHovered] = useState(false)

    
    return (<tr onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <td>{props.transaction.name}</td>
        <td>{props.transaction.contactBilledPhone}</td>
        <td>{props.transaction.contactBilledAddress1}</td>
        <td>{props.transaction.contactBilledZipCode}</td>
        <td>{props.transaction.contactBilledCity}</td>
        <td>{props.transaction.contactDeliveredName}</td>
        <td>{props.transaction.contactDeliveredPhone}</td>
        <td>{GetTransactionType(props.transaction.state)}</td>
        <td>{props.transaction.dateCreated}</td>
        <td>
            <div className={hovered ? "edit-delete" : 'edit-delete-hide'}>
                <div className="icon-step">{props.transaction.edit}</div>
                <div className="icon-step">{props.transaction.archive}</div>
            </div>
        </td>
    </tr>
    )
}

export default TransactionTableRow;