import React from 'react';

interface PaginationItemProps {
    page: number,
    handlePaginationClick: (position: number, step: number) => void,
    selectedItem: number,
    step: number,
    itemsAmountPerPage: number,
    itemsLength: number
}

const PaginationItem = (props: PaginationItemProps) => {

    const { page, handlePaginationClick, selectedItem, step, itemsAmountPerPage, itemsLength } = props

    const lastIndex = (page - 1) * itemsAmountPerPage + 1
    const disabled = lastIndex > itemsLength

    return (
        <div className={selectedItem === page ? "pagination-cell  selected-cell" : disabled ? "pagination-cell disabled-cell " : "pagination-cell"} onClick={() => handlePaginationClick(page, step - 1)}>{page}</div>
    )
}


export default PaginationItem;