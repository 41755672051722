/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'reactstrap';
import { Default, Mobile } from '../ResponsiveSizes';


const MyNavLink = ({ ...props }) => {

    
    
    return (
        <React.Fragment>
            <Default>
                <NavLink {...props} rel="preconnect" onClick={() => props.setActiveTab(props.to)}
                    className={props.position.some((p: string) => props.activeTab.indexOf(p) !== -1 && p !== "/" || p.includes(props.activeTab)) ? "nav-text nav-bold" : "nav-text"} />
            </Default>
            <Mobile>
                <NavLink {...props} rel="preconnect" onClick={() => props.setActiveTab(props.to)}
                    className={props.position.some((p: string) => props.activeTab.indexOf(p) !== -1 && p !== "/" || p.includes(props.activeTab)) ? "mobile-navbar-active mobile-navbar-item" : "mobile-navbar-item"} />
            </Mobile>
        </React.Fragment>
    )
}


export default MyNavLink;