export enum CertificateTypes {
    granuleMulti,
    granulePro

}


export const GetCertificateType = (pos: number) => {
    switch (pos) {
        case 0:
            return "granuleMulti"
        case 1:
            return "granulePro"
        default:
            return ''
    }
}

export const GetCertificateName = (pos: number | undefined) => {
    switch (pos) {
        case 0:
            return "Granule Multi"
        case 1:
            return "Granule Pro"
        default:
            return ''
    }
}