import React, { useState } from 'react';
import { UserTable } from '../../../interfaces/user';
import { UserType, GetUserType } from '../../../enums/userType';

interface MyTableRowProps {
    user: UserTable,
}

const UserTableRow = (props: MyTableRowProps) => {
    const [hovered, setHovered] = useState(false);


    return (<tr onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}>
        <th scope="row">{props.user.id}</th>
        <td>{props.user.name}</td>
        <td>{props.user.userType}</td>
        <td>{props.user.username}</td>
        <td>{props.user.email}</td>
        <td>{props.user.created}</td>
        <td>{props.user.userType === GetUserType(UserType.Blower) ? props.user.offerAmount : '-'}</td>
        <td>{props.user.userType === GetUserType(UserType.Blower) ? props.user.exectutedOfferAmount : '-'}</td>
        <td>{props.user.userType === GetUserType(UserType.Blower) ? props.user.exectutedOfferPercentage : '-'}</td>
        <td>
            <div className={hovered ? "edit-delete" : 'edit-delete-hide'}>
                <div className="icon-step">{props.user.edit}</div>
            </div>
        </td>
    </tr>
    )
}

export default UserTableRow;