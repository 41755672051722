import React from 'react';

interface CertificateIconProps {
    history: any,
    certificateId: number,
}

const CertificateIcon = (props: CertificateIconProps) => {

    const { history, certificateId } = props

    const pdf = require("../../../assets/Pdf.svg") as string;

    return (
        <div className="transaction-line-col5-icon"><img src={pdf} alt={""} onClick={() => history.push(`/certificates/${certificateId}`)} /></div>
    )
}


export default CertificateIcon;