import React from 'react';
import { Redirect, RouteProps, Route } from "react-router-dom"
import { User } from '../interfaces/user'
import Cookie from "js-cookie"

interface PrivateRouteProps {
    component: any,
    roles?: Array<number>,
    currentUser: User,   
}


type PrivateRoutePropsWithRoute = PrivateRouteProps & RouteProps;

 const PrivateRoute = ({ component: Component, roles, currentUser, ...rest }: PrivateRoutePropsWithRoute) => (
     
    <Route {...rest} render={props => {

        if (currentUser === null || currentUser.id === -1 || !Cookie.get("token")) {
            // not logged in so redirect to login page with the return url
            return <Redirect to='/login' />
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser!.userType) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/' }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)

export default PrivateRoute;