

export enum TransactionTypes {
    quote,
    order,
    fulfilled
}


export const GetTransactionType = (pos: number) => {
    switch (pos) {
        case 0:
            return "Tilbud"
        case 1:
            return "Ordre"
        case 2:
            return "Udført"
        default:
            return ''
    }
}