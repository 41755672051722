import { Component } from "react"
import Cookie from "js-cookie"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { AxiosStatic } from "axios"


type PropsType = RouteComponentProps & {
  axios: AxiosStatic,
}

class RequestHandler extends Component<PropsType> {

  reqInterceptor: any
  tokenInterceptor: any
  resInterceptor: any


  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {

    const { history } = this.props


    this.tokenInterceptor = this.props.axios.interceptors.request.use(config => {
      const token = Cookie.get("token")
      if (token) {
        config.headers.Authorization = `Bearer ${token}` //eslint-disable-line
      }
      return config
    })
    this.resInterceptor = this.props.axios.interceptors.response.use(
      res => res,
      error => {
        
          if (window.navigator.onLine && !error.response) {
            history.push("/login")
          } else {
            const { status } = error.response


            if (window.navigator.onLine)
              switch (status) {
                case 401:
                case 403:
                  history.push("/login")
                  break
                default:
                  break
              }
          }
        return Promise.reject(error.response)
      }
    )
  }

  componentWillUnmount() {
    this.props.axios.interceptors.request.eject(this.reqInterceptor)
    this.props.axios.interceptors.request.eject(this.tokenInterceptor)
    this.props.axios.interceptors.response.eject(this.resInterceptor)
  }

  render() {
    return this.props.children
  }
}

export default withRouter(RequestHandler)
