import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../store';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NavbarState {
    show: boolean
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface Show {
    type: 'NAVBAR_SHOW';
    show: boolean;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = Show

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    setNavbarShow: (show: boolean): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'NAVBAR_SHOW', show });
    },
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: NavbarState = { show: true };




export const reducer: Reducer<NavbarState> = (state: NavbarState | undefined, incomingAction: Action): NavbarState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'NAVBAR_SHOW':
            return {
                show: action.show
            };
        default:
            break;
    }
    return state;
};
